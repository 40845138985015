import React, { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { type Stripe, loadStripe } from '@stripe/stripe-js';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import {
  addOfferOrderIntent,
  createOfferOrderCardPaymentIntent,
  clearCreateOfferOrderCardPaymentIntent,
} from 'src/actions';
import { OfferOrderConstant } from 'src/constants';
import { getCurrencyFormatter } from 'src/utils';

import { EnvConstant } from '../../../../../../configs/env.configs';
import { ErrorCode } from '../../../../../../constants/common.constants';
import { CreateOfferOrderCardPaymentIntentResponseDto } from '../../../../../../dtos/offerOrders.dtos';
import { MAlert } from '../../../../../../lib/Miscellaneous/MAlert/MAlert';
import Spinner from '../../../../../../lib/Miscellaneous/Spinner';
import { UnknownErrorMessage } from '../../../../../../lib/UnknownErrorMessage/UnknownErrorMessage';
import { OfferOrderIntent } from '../../../../../../models/offer-orders.model';
import { assertPropertyIsFound } from '../../../../../../utils/assertPropertyIsFound';
import { Logger } from '../../../../../../utils/Logger';

import * as Styles from './CardPayment.styles';
import { CardPaymentForm } from './CardPaymentForm';

export interface CardPaymentProps {
  isValid: (value: boolean) => void;
}

export const CardPayment = ({ isValid }: CardPaymentProps) => {
  const dispatch = useDispatch();

  const cardPaymentIntent: CreateOfferOrderCardPaymentIntentResponseDto | undefined = useSelector(
    (state: any) => state.offerOrders.createCardPaymentIntent.data,
  );
  const isCreateCardPaymentIntentLoading = useSelector((state: any) =>
    Boolean(state.offerOrders.createCardPaymentIntent?.__requested),
  );
  const createCardPaymentIntentSucceeded = useSelector((state: any) =>
    Boolean(state.offerOrders.createCardPaymentIntent?.__succeeded),
  );
  const createCardPaymentIntentFailed = useSelector((state: any) =>
    Boolean(state.offerOrders.createCardPaymentIntent?.__failed),
  );

  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);

  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isStripeLoading, setIsStripeLoading] = useState<boolean>(false);
  const [hasMaximumAmountError, setHasMaximumAmountError] = useState<boolean>(false);
  const [hasBeforeStripeLoadError, setHasBeforeStripeLoadError] = useState<boolean>(false);

  const shouldCreateCardPaymentIntent = () => Boolean(stripe && !offerOrderIntent.cardPaymentIntentId);

  const initStripe = async () => {
    try {
      setIsStripeLoading(true);
      assertPropertyIsFound<OfferOrderIntent, 'cardPaymentAccountExternalId'>(
        offerOrderIntent,
        'cardPaymentAccountExternalId',
      );
      const stripe = await loadStripe(EnvConstant.STRIPE_PUBLIC_API_KEY, {
        stripeAccount: offerOrderIntent.cardPaymentAccountExternalId,
      });
      setStripe(stripe);
      setHasBeforeStripeLoadError(false);
    } catch (error) {
      Logger.error(`Before stripe load error. Received ${error}`);
      setHasBeforeStripeLoadError(true);
    } finally {
      setIsStripeLoading(false);
    }
  };

  useEffect(() => {
    if (offerOrderIntent.cardPaymentClientSecret) {
      setClientSecret(offerOrderIntent.cardPaymentClientSecret);
    }
  }, []);

  useEffect(() => {
    if (createCardPaymentIntentSucceeded && cardPaymentIntent) {
      setClientSecret(cardPaymentIntent.clientSecret);

      dispatch(
        addOfferOrderIntent({
          ...offerOrderIntent,
          cardPaymentIntentId: cardPaymentIntent.cardPaymentIntentId,
          cardPaymentClientSecret: cardPaymentIntent.clientSecret,
          cardPaymentIntentTotalCost: cardPaymentIntent.totalCost,
        }),
      );
    }
  }, [createCardPaymentIntentSucceeded]);

  useEffect(() => {
    if (shouldCreateCardPaymentIntent()) {
      dispatch(
        createOfferOrderCardPaymentIntent({
          body: {
            offerId: offerOrderIntent.offerId,
            quantity: offerOrderIntent.quantity,
            accountId: offerOrderIntent.account.accountId,
          },
        }),
      );

      return () => {
        dispatch(clearCreateOfferOrderCardPaymentIntent());
      };
    }
  }, [stripe]);

  useEffect(() => {
    if (offerOrderIntent.totalInvestment > OfferOrderConstant.CARD_PAYMENT_MAX_AMOUNT) {
      setHasMaximumAmountError(true);

      return;
    }
    initStripe();
  }, []);

  useEffect(() => {
    if (hasBeforeStripeLoadError || createCardPaymentIntentFailed || hasMaximumAmountError) {
      isValid(false);
    }
  }, [hasBeforeStripeLoadError, createCardPaymentIntentFailed, hasMaximumAmountError]);

  if (isStripeLoading || isCreateCardPaymentIntentLoading) {
    return <Spinner />;
  }

  if (hasMaximumAmountError) {
    return (
      <MAlert
        type='error'
        description={
          <span>
            The maximum payment amount for Card is{' '}
            {getCurrencyFormatter().format(OfferOrderConstant.CARD_PAYMENT_MAX_AMOUNT)} per Order. To invest over{' '}
            {getCurrencyFormatter().format(OfferOrderConstant.CARD_PAYMENT_MAX_AMOUNT)}, on an Order, please select Wire
            or Check transfer.
          </span>
        }
        closable={false}
      />
    );
  }

  if (hasBeforeStripeLoadError) {
    return (
      <MAlert
        type='error'
        description={<UnknownErrorMessage errorCode={ErrorCode.BEFORE_LOAD_STRIPE} />}
        closable={false}
      />
    );
  }

  if (createCardPaymentIntentFailed) {
    return (
      <MAlert
        type='error'
        description={<UnknownErrorMessage errorCode={ErrorCode.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT} />}
        closable={false}
      />
    );
  }

  return (
    <div className={Styles.container}>
      <ErrorBoundary
        onError={error => {
          isValid(false);
          Logger.error(error);
        }}
        fallback={
          <MAlert
            type='error'
            description={<UnknownErrorMessage errorCode={ErrorCode.LOAD_STRIPE} />}
            closable={false}
          />
        }>
        {stripe && clientSecret && (
          <Elements
            options={{
              appearance: {
                theme: 'stripe',
                labels: 'above',
                variables: {
                  fontFamily: 'Source Sans Pro, sans-serif',
                },
              },
              clientSecret,
            }}
            stripe={stripe}>
            <CardPaymentForm isValid={isValid} clientSecret={clientSecret} />
          </Elements>
        )}
      </ErrorBoundary>
    </div>
  );
};
