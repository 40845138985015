import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededDefaults } from './utils';

export const createAccount = (state: any = initialState.accounts.create, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ACCOUNT):
      return clearedDefaults(state);
    case State.actionRequested(Type.CREATE_ACCOUNT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_ACCOUNT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
      return initialState.accounts.create;
    default:
      return state;
  }
};

export const patchAccount = (state: any = initialState.accounts.patch, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_ACCOUNT):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_ACCOUNT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_ACCOUNT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_ACCOUNT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
      return initialState.accounts.patch;
    default:
      return state;
  }
};

export const patchJointAccountInterest = (state: any = initialState.accounts.patchJointInterest, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_JOINT_ACCOUNT_INTEREST):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_JOINT_ACCOUNT_INTEREST):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_JOINT_ACCOUNT_INTEREST):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_JOINT_ACCOUNT_INTEREST):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accounts.patchJointInterest;
    default:
      return state;
  }
};

export const getAccount = (state: any = initialState.accounts.item, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_ACCOUNT):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_ACCOUNT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_ACCOUNT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_ACCOUNT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.accounts.item;
    default:
      return state;
  }
};

export const submit = (state: any = initialState.accounts.submit, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.SUBMIT_ACCOUNT):
      return clearedDefaults(state);
    case State.actionRequested(Type.SUBMIT_ACCOUNT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.SUBMIT_ACCOUNT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.SUBMIT_ACCOUNT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.accounts.submit;
    default:
      return state;
  }
};

export const downloadSignature = (state: any = initialState.accounts.downloadSignature, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.DOWNLOAD_ACCOUNT_SIGNATURE):
      return clearedDefaults(state);
    case State.actionRequested(Type.DOWNLOAD_ACCOUNT_SIGNATURE):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.DOWNLOAD_ACCOUNT_SIGNATURE):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.DOWNLOAD_ACCOUNT_SIGNATURE):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
      return initialState.accounts.downloadSignature;
    default:
      return state;
  }
};

export const switchAccount = (state: any = initialState.accounts.switch, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.SWITCH_ACCOUNT):
      return clearedDefaults(state);
    case State.actionRequested(Type.SWITCH_ACCOUNT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.SWITCH_ACCOUNT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.accounts.submit;
    default:
      return state;
  }
};

export const accountsReducers = combineReducers({
  item: getAccount,
  submit,
  create: createAccount,
  patch: patchAccount,
  patchJointInterest: patchJointAccountInterest,
  downloadSignature,
  switch: switchAccount,
});
