import { PropsWithChildren, useState } from 'react';

import { cx } from '@emotion/css';
import { Tooltip } from 'antd';
import type { TooltipPlacement } from 'antd/es/tooltip';
import { ColorConstant } from 'src/styles';

import { MModal } from '../MModal/MModal';

import * as Styles from './MTooltip.styles';

export interface MTooltipProps {
  type?: 'modal' | 'popover';
  title?: string;
  label?: string;
  placement?: TooltipPlacement;
  showIcon?: boolean;
  icon?: 'question' | 'info';
  text?: 'underline' | 'none';
  className?: string;
}

export const MTooltip = ({
  title = '',
  label,
  type = 'popover',
  placement = 'top',
  showIcon = true,
  icon = 'question',
  text = 'none',
  className = '',
  children,
}: PropsWithChildren<MTooltipProps>) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onClick = () => {
    setIsModalOpen(true);
  };

  if (type === 'popover') {
    return (
      <Tooltip
        placement={placement}
        title={children}
        color={ColorConstant.GRAY1}
        className={cx(Styles.tooltip(text), className)}
        overlayClassName={Styles.overlay}>
        <span className={Styles.popoverTitle}>
          {showIcon && <i className={Styles.icon(icon)}></i>}
          {title}
        </span>
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip>
        <span className={Styles.popoverTitle} onClick={onClick}>
          {showIcon && <i className={Styles.icon(icon)}></i>}
          {label}
        </span>
      </Tooltip>
      <MModal
        title={title}
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        tertiaryButtonText='Close'
        onTertiaryButtonClick={() => setIsModalOpen(false)}>
        {children}
      </MModal>
    </>
  );
};
