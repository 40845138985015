import { cx } from '@emotion/css';
import { useAuthenticatedUserSelector } from 'src/hooks';
import { Account } from 'src/models';
import { AlignItems, Display, FlexDirection, JustifyContent } from 'src/styles';

import * as Styles from './Header.styles';

interface AccountHolderFullNameProps {
  alignment: 'start' | 'center';
  account?: Account;
}

export const AccountHolderFullName = ({ account, alignment }: AccountHolderFullNameProps) => {
  const { user } = useAuthenticatedUserSelector();

  const getFullName = () => {
    if (!account || !account.primaryAccountHolder) {
      return `${user?.firstName} ${user?.middleName ?? ''} ${user?.lastName} ${user?.suffix ?? ''}`;
    }

    return `${account.primaryAccountHolder.firstName} ${account.primaryAccountHolder.middleName ?? ''} ${
      account.primaryAccountHolder.lastName
    } ${account.primaryAccountHolder.suffix ?? ''}`;
  };

  return (
    <div
      className={cx(
        Display.flex,
        JustifyContent.center,
        alignment === 'start' ? AlignItems.flexStart : AlignItems.center,
        FlexDirection.column,
        JustifyContent.flexStart,
      )}>
      <span className={Styles.fullName}>{getFullName()}</span>
      {account && (
        <span className={Styles.accountType}>
          {account.type.label} {account.accountNumber ? `• ${account.accountNumber}` : ''}
        </span>
      )}
    </div>
  );
};
