import produce from 'immer';
import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';
import { Order } from '../models/orders.models';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededDefaults } from './utils';

export const updateOrderFromEvent = produce((draftState: any, action: any) => {
  if (draftState.data?.data) {
    draftState.data.data = draftState.data.data.map((anOrder: Order) => {
      if (anOrder.id === action.payload.id) {
        return {
          ...anOrder,
          ...action.payload,
        };
      }

      return anOrder;
    });
  }
});

export const getOrders = (state: any = initialState.orders.list, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_ORDERS):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_ORDERS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_ORDERS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_ORDERS):
      return failedDefaults(state, action);
    case Type.UPDATE_ORDER_FROM_EVENT:
      return updateOrderFromEvent(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.orders.list;
    default:
      return state;
  }
};

export const getOrdersById = (state: any = initialState.orders.item, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_ORDERS_ID):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_ORDERS_ID):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_ORDERS_ID):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_ORDERS_ID):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.orders.item;
    default:
      return state;
  }
};

export const getOrdersPositions = (state: any = initialState.orders.positions, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_ORDERS_POSITIONS):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_ORDERS_POSITIONS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_ORDERS_POSITIONS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_ORDERS_POSITIONS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.orders.positions;
    default:
      return state;
  }
};

export const createOrdersReviewPositions = (state: any = initialState.orders.review, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ORDERS_REVIEW):
      return clearedDefaults(state);
    case State.actionSucceeded(Type.CREATE_ORDERS_REVIEW):
      return succeededDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.orders.review;
    default:
      return state;
  }
};

export const createOrdersPositions = (state: any = initialState.orders.created, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ORDERS):
      return clearedDefaults(state);
    case State.actionRequested(Type.CREATE_ORDERS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CREATE_ORDERS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_ORDERS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.orders.created;
    default:
      return state;
  }
};

export const cancelOrdersPositions = (state: any = initialState.orders.updated, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.UPDATE_ORDERS):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPDATE_ORDERS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_ORDERS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.UPDATE_ORDERS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.orders.updated;
    default:
      return state;
  }
};

export default combineReducers({
  getOrders,
  getOrdersById,
  getOrdersPositions,
  createOrdersReviewPositions,
  createOrdersPositions,
  cancelOrdersPositions,
});
