/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-alert */
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
// import { Banner, LegacyAccountDisclaimer } from 'src/lib';

import accountImage from '../../assets/images/account.png';
import imgDownloadIOS from '../../assets/images/downloadIOS.png';
import imgDownloadPlayStore from '../../assets/images/downloadPlayStore.png';
import imgHandheldVideo from '../../assets/images/handheldVideo.png';
import investImage from '../../assets/images/invest.png';
import offersImage from '../../assets/images/offers.png';

import * as Styles from './styles';

const MobileHowItWorks = () => (
  <div className={Styles.howItWorksSectionMobile}>
    <div className={Styles.centeredSectionContainer}>
      <div className={Styles.howItWorksTitle}>How it works</div>
      <div className={Styles.howItWorksMobile}>
        <div className={Styles.blueLine}>
          <div className={Styles.blueLineTop} />
        </div>
        <div className={Styles.mobileRow}>
          <div className={Styles.setupDescriptionSection}>
            <div className={Styles.stepNumberMobile}>1</div>
            <div className={Styles.setupText}>Set up a My IPO investment account</div>
          </div>
          <div className={Styles.middleSplit} />
          <div className={Styles.setupImageBoxMobile}>
            <img src={accountImage} alt='Open Account' className={Styles.howImage} />
          </div>
        </div>
        <div className={Styles.mobileRow}>
          <div className={Styles.browseImageBoxMobile}>
            <img src={offersImage} alt='View Offers' className={Styles.howImage} />
          </div>
          <div className={Styles.middleSplit} />
          <div className={Styles.browseDescriptionSection}>
            <div className={Styles.stepNumberMobile}>2</div>
            <div className={Styles.browseText}>Browse My IPO offers</div>
          </div>
        </div>
        <div className={Styles.mobileRow}>
          <div className={Styles.investDescriptionSection}>
            <div className={Styles.stepNumberMobile}>3</div>
            <div className={Styles.investText}>
              Invest in one of the available <br />
              IPOs or other Offerings<sup>*</sup>
            </div>
          </div>
          <div className={Styles.middleSplit} />
          <div className={Styles.investImageBoxMobile}>
            <img src={investImage} alt='invest' className={Styles.howImage} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const DefaultHowItWorks = () => (
  <div className={Styles.howItWorksSection}>
    <div className={Styles.centeredSectionContainer}>
      <div className={Styles.howItWorksTitle}>How it works</div>
      <div className={Styles.howItWorks}>
        <div className={Styles.howItWorksBlock}>
          <div className={Styles.descBlock}>
            <div className={Styles.descNum}>1</div>
            <div className={Styles.descText}>Set up a My IPO investment account</div>
          </div>
          <img src={accountImage} alt='Open Account' className={Styles.howItWorksImage} />
        </div>
        <div className={Styles.howItWorksBlock}>
          <div className={Styles.descBlock}>
            <div className={Styles.descNum}>2</div>
            <div className={Styles.descText}>Browse My IPO offers</div>
          </div>
          <img src={offersImage} alt='View Offers' className={Styles.howItWorksImage} />
        </div>
        <div className={Styles.howItWorksBlock}>
          <div className={Styles.descBlock}>
            <div className={Styles.descNum}>3</div>
            <div className={Styles.descText}>
              Invest in one of the available <br />
              IPOs or other Offerings<sup>*</sup>
            </div>
          </div>
          <img src={investImage} alt='Invest' className={Styles.howItWorksImage} />
        </div>
      </div>
    </div>
  </div>
);

const MobileStoreBanner = ({ btn }: any) => {
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  return (
    <div className={Styles.getStartedHome}>
      <div className={Styles.getStartedCol}>
        <div className={Styles.getStartedTitleHome}>Get started today!</div>
        <Link className={Styles.signupBtn} to={btn.location}>
          {btn.text}
        </Link>
      </div>
      <div className={Styles.getStartedCol}>
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%', position: 'relative', marginTop: '10px' }}>
            <img src={imgHandheldVideo} alt='Handheld MyIPO Video' style={{ width: '100%' }} />
            <iframe
              title='welcomeVideo'
              src='https://player.vimeo.com/video/402228666'
              frameBorder='0'
              allow='autoplay; fullscreen'
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                marginLeft: '22%',
                marginTop: '11%',
                width: '37%',
                height: '62%',
              }}
            />
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            {isIOS ? (
              <a href='https://apps.apple.com/app/my-ipo/id1437751317' target='_blank'>
                <img src={imgDownloadIOS} alt='Download on iOS Store' style={{ marginTop: '10px' }} />
              </a>
            ) : (
              <a href='https://play.google.com/store/apps/details?id=com.myipo.myipo' target='_blank'>
                <img src={imgDownloadPlayStore} alt='Download on PlayStore' style={{ marginTop: '10px' }} />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DefaultStoreBanner = ({ btn }: any) => (
  <div className={Styles.getStartedHome}>
    <div className={Styles.getStartedCol}>
      <div className={Styles.getStartedTitleHome}>Get started today!</div>
      <Link className={Styles.signupBtn} to={btn.location}>
        {btn.text}
      </Link>
    </div>
    <div className={`${Styles.getStartedCol} ${Styles.getStartedBorderLeft}`}>
      <div style={{ width: '100%' }}>
        <div style={{ float: 'left', position: 'relative' }}>
          <img src={imgHandheldVideo} alt='Handheld MyIPO Video' className={Styles.handheldImage} />
          <iframe
            title='welcomeVideo'
            src='https://player.vimeo.com/video/402228666'
            width='145'
            height='280'
            frameBorder='0'
            allow='autoplay; fullscreen'
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              marginLeft: '90px',
              marginTop: '50px',
            }}
          />
        </div>
        <div style={{ float: 'right' }}>
          <a href='https://apps.apple.com/app/my-ipo/id1437751317' target='_blank'>
            <img src={imgDownloadIOS} alt='Download on iOS Store' style={{ display: 'block', marginTop: '172px' }} />
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.myipo.myipo' target='_blank'>
            <img src={imgDownloadPlayStore} alt='Download on PlayStore' style={{ display: 'block' }} />
          </a>
        </div>
      </div>
    </div>
  </div>
);

const Home = ({ authToken }: any) => {
  const btn = {
    text: 'Sign up',
    location: '/register',
  };

  if (authToken) {
    btn.text = 'View Offers';
    btn.location = '/offers';
  }

  return (
    <div className={Styles.Home}>
      {/* <Banner type='info' message={<LegacyAccountDisclaimer />} className={Styles.banner} size='small' /> */}
      <div className={Styles.levelingSection}>
        <div className={Styles.sectionContainer}>
          <div className={Styles.levelingCTABox}>
            <div className={Styles.levelingTitle}>Leveling the playing field</div>
            <div className={Styles.levelingSubtitle}>
              Giving investors access to some of the most exciting <br />
              IPOs and other Offerings<sup>*</sup>!
            </div>
            <Link className={Styles.signupBtn} to={btn.location}>
              {btn.text}
            </Link>
          </div>
        </div>
      </div>

      <MediaQuery maxWidth='624px'>
        <MobileHowItWorks />
        <MobileStoreBanner btn={btn} />
      </MediaQuery>
      <MediaQuery minWidth='625px'>
        <DefaultHowItWorks />
        <DefaultStoreBanner btn={btn} />
      </MediaQuery>
    </div>
  );
};

export default Home;
