export enum SortDirectionType {
  Asc = 'asc',
  Desc = 'desc',
}

export interface SortDirectionQueryParams {
  sortDirection: SortDirectionType;
}

export interface PaginationQueryParams {
  take?: number;
  skip?: number;
}

export interface BaseListResponseDto<T> {
  data: T;
  total: number;
}

export interface DateRangeQueryParamsDto {
  startDate?: string;
  endDate?: string;
}

export interface ErrorResponseDto {
  error: string;
  statusCode: number;
  message: string | string[];
}

export enum PhoneNumberTypeDto {
  Home = 'Home',
  Work = 'Work',
  Mobile = 'Mobile',
}

export interface PhoneNumberDto {
  phoneNumber: string;
  type: PhoneNumberTypeDto;
}
