import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  setAuthentication,
  doSucceededEmailConfirm,
  emailUpdateSucceeded,
  updateOnboardStep,
  login as loginAction,
  doSucceededUpdatePhoneNumber,
} from 'src/actions';
import { mapUserDtoToModel } from 'src/mappers';
import { User } from 'src/models';
import { isTradingBlockPasswordExpirationError, isTradingBlockSamePasswordError } from 'src/utils';

import { State, Type } from '../actions/utils';
import * as URLs from '../constants/url';
import { SignUpDto, SignUpStatusDto, UserDto } from '../dtos/users.dtos';
import { OnboardStep } from '../models/app.models';
import { AuthReducedState } from '../typings/auth.types';
import { ResponseGenerator, TReduxAction } from '../typings/commonTypes';

import { HttpClient, safeSaga } from './utils';

export function* signUp(action: TReduxAction) {
  const { email, phoneNumber, firstName, middleName, lastName, suffix, password } = action.payload;

  const payload: SignUpDto = {
    email,
    password,
    firstName,
    middleName,
    lastName,
    suffix,
    phoneNumber,
  };
  yield call(HttpClient, 'POST', URLs.SIGN_UP, payload);

  yield put({
    type: State.actionSucceeded(Type.SIGN_UP),
    payload: {
      email,
      firstName,
      lastName,
      phoneNumber,
      password,
    },
  });

  // authenticate user
  yield put(loginAction({ email, password }));
}

export function* patchAddress(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const user: UserDto = yield select(state => state.user.authenticated.data);
  const isEdit = Boolean(user.address);

  let response: { data: UserDto };

  if (isEdit) {
    response = yield call(HttpClient, 'PUT', URLs.SAVE_ADDRESS, action.payload, authToken);
  } else {
    response = yield call(HttpClient, 'POST', URLs.SAVE_ADDRESS, action.payload, authToken);
  }

  yield put({
    type: State.actionSucceeded(Type.UPDATE_AUTHENTICATED_USER),
    payload: response.data,
  });

  yield put(updateOnboardStep(OnboardStep.MfaConfiguration));

  yield put({
    type: State.actionSucceeded(Type.PATCH_USER_ADDRESS),
    payload: action.payload,
  });
}

export function* toggleMfaConfig(action: TReduxAction) {
  const { isEnabled } = action.payload;
  const { authToken, tbToken, email, password }: AuthReducedState = yield select(state => state.auth.data);

  if (isEnabled) {
    yield call(HttpClient, 'PUT', URLs.ENABLE_MFA, undefined, authToken);

    const loginResponse: ResponseGenerator<any> = yield call(HttpClient, 'POST', URLs.LOGIN, {
      email,
      password,
    });

    // TODO: move authentication side-effecs inside the page where the action is dispatched
    // toggleMfaConfig will be used in the future from user/settings and it should not involve
    // authentication side-effects
    yield put(setAuthentication({ ...loginResponse.data, authToken, email, password, tbToken }));

    yield put({
      type: State.actionSucceeded(Type.UPDATE_AUTHENTICATED_USER),
      payload: { signUpStatus: SignUpStatusDto.Done, isMfaEnabled: true },
    });

    yield put(updateOnboardStep(OnboardStep.MfaConfirm));

    yield put({
      type: State.actionSucceeded(Type.TOGGLE_MFA_CONFIG),
      payload: action.payload,
    });

    return;
  }
  yield call(HttpClient, 'PUT', URLs.DISABLE_MFA, undefined, authToken);

  yield put(setAuthentication({ authToken, tbToken }));

  yield put(updateOnboardStep(OnboardStep.Done));

  yield put({
    type: State.actionSucceeded(Type.UPDATE_AUTHENTICATED_USER),
    payload: { signUpStatus: SignUpStatusDto.Done, isMfaEnabled: false },
  });

  yield put({
    type: State.actionSucceeded(Type.TOGGLE_MFA_CONFIG),
    payload: action.payload,
  });
}

export function* login(action: TReduxAction) {
  try {
    const { email, password } = action.payload;
    const response: ResponseGenerator<any> = yield call(HttpClient, 'POST', URLs.LOGIN, { email, password });
    yield put({
      type: State.actionSucceeded(Type.LOGIN),
      payload: response.data,
    });
  } catch (error: any) {
    if (isTradingBlockSamePasswordError(error)) {
      yield put({ type: State.actionFailed(Type.LOGIN), message: { error: error?.response?.data?.error } });

      return;
    }

    if (isTradingBlockPasswordExpirationError(error)) {
      yield put({ type: State.actionFailed(Type.LOGIN), message: { error: error?.response?.data?.error } });

      return;
    }
    throw error;
  }
}

export function* logout(action: TReduxAction) {
  yield put({
    type: State.actionSucceeded(Type.LOGOUT),
    payload: action.payload,
  });
}

export function* getAuthenticatedUser() {
  const { authToken }: { authToken: string | undefined } = yield select(state => state.auth.data);

  try {
    const response: ResponseGenerator<any> = yield call(
      HttpClient,
      'GET',
      URLs.GET_AUTHENTICATED_USER,
      undefined,
      authToken,
    );

    const model = mapUserDtoToModel(response.data);

    // NOTE: it's difficult to handle side-effects with react routing, therefore we are adding
    // the last used account to the store right after the user has been authenticated. At this point
    // there are no components rendered that listen to account state changes, which means no re-rendering.
    const lastUsedAccount =
      model.accounts.find(acc => acc.id === response.data?.lastUsedAccountId) ?? model?.accounts[0];

    if (lastUsedAccount) {
      yield put({
        type: State.actionSucceeded(Type.GET_ACCOUNT),
        payload: lastUsedAccount,
      });
    }
    yield put({
      type: State.actionSucceeded(Type.GET_AUTHENTICATED_USER),
      payload: mapUserDtoToModel(response.data),
    });
  } catch (error) {
    yield put(setAuthentication({ authToken: null, tbToken: null }));

    throw error;
  }
}

export function* confirmMfaCode(action: TReduxAction) {
  try {
    const { code } = action.payload;

    const { password, session, email }: AuthReducedState = yield select(state => state.auth.data) as AuthReducedState;
    const onboardStep: OnboardStep | undefined = yield select(state => state.app.onboardStep);

    const response: ResponseGenerator<any> = yield call(HttpClient, 'POST', URLs.MFA_CHALLENGE, {
      email,
      session,
      code,
      password,
    });

    if (onboardStep === OnboardStep.MfaConfirm) {
      yield put(updateOnboardStep(OnboardStep.Done));
    }

    yield put({
      type: State.actionSucceeded(Type.CONFIRM_MFA),
      payload: response.data,
    });
  } catch (error: any) {
    if (isTradingBlockSamePasswordError(error)) {
      yield put({ type: State.actionFailed(Type.CONFIRM_MFA), message: { error: error?.response?.data?.error } });

      return;
    }

    if (isTradingBlockPasswordExpirationError(error)) {
      yield put({ type: State.actionFailed(Type.CONFIRM_MFA), message: { error: error?.response?.data?.error } });

      return;
    }
    throw error;
  }
}

export function* resendMfaCode() {
  const { email, password, authToken, tbToken }: AuthReducedState = yield select(state => state.auth.data);
  const response: ResponseGenerator<any> = yield call(HttpClient, 'POST', URLs.LOGIN, { email, password });

  yield put(setAuthentication({ ...response.data, authToken, email, password, tbToken }));

  yield put({
    type: State.actionSucceeded(Type.RESEND_MFA_CODE),
  });
}

export function* forgotPassword(action: TReduxAction) {
  const { email } = action.payload;

  const response: ResponseGenerator<any> = yield call(HttpClient, 'POST', URLs.FORGOT_PASSWORD, {
    email,
  });

  yield put({
    type: State.actionSucceeded(Type.FORGOT_PASSWORD),
    payload: response.data,
  });
}

export function* resetPassword(action: TReduxAction) {
  const { email, code, password } = action.payload;

  const response: ResponseGenerator<any> = yield call(HttpClient, 'POST', URLs.RESET_PASSWORD, {
    code,
    email,
    password,
  });

  yield put({
    type: State.actionSucceeded(Type.RESET_PASSWORD),
    payload: response.data,
  });
}

export function* updateEmail(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const { email } = action.payload;

  yield call(HttpClient, 'PUT', URLs.EMAIL_CHANGE, { email }, authToken);

  yield put(emailUpdateSucceeded());
}

export function* emailConfirm(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const { code, updateId } = action.payload;

  yield call(HttpClient, 'PUT', URLs.EMAIL_CONFIRM, { code, updateId }, authToken);

  yield put(doSucceededEmailConfirm());
}

export function* updatePhoneNumber(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const user: User = yield select(state => state.user.authenticated.data);
  yield call(HttpClient, 'PUT', URLs.UPDATE_PHONE_NUMBER, action.payload, authToken);
  yield put(doSucceededUpdatePhoneNumber());
  yield put({
    type: State.actionSucceeded(Type.GET_AUTHENTICATED_USER),
    payload: { ...user, phoneNumber: action.payload.phoneNumber },
  });
}

/**
 * Forms sagas
 */
export default function* registrationSaga() {
  yield takeEvery(State.actionRequested(Type.SIGN_UP), safeSaga(signUp, Type.SIGN_UP));
  yield takeEvery(State.actionRequested(Type.PATCH_USER_ADDRESS), safeSaga(patchAddress, Type.PATCH_USER_ADDRESS));
  yield takeEvery(State.actionRequested(Type.TOGGLE_MFA_CONFIG), safeSaga(toggleMfaConfig, Type.TOGGLE_MFA_CONFIG));
  yield takeEvery(State.actionRequested(Type.LOGIN), safeSaga(login, Type.LOGIN));
  yield takeEvery(State.actionRequested(Type.LOGOUT), safeSaga(logout, Type.LOGOUT));
  yield takeEvery(
    State.actionRequested(Type.GET_AUTHENTICATED_USER),
    safeSaga(getAuthenticatedUser, Type.GET_AUTHENTICATED_USER, false),
  );
  yield takeEvery(State.actionRequested(Type.CONFIRM_MFA), safeSaga(confirmMfaCode, Type.CONFIRM_MFA));
  yield takeEvery(State.actionRequested(Type.RESEND_MFA_CODE), safeSaga(resendMfaCode, Type.RESEND_MFA_CODE));
  yield takeEvery(State.actionRequested(Type.FORGOT_PASSWORD), safeSaga(forgotPassword, Type.FORGOT_PASSWORD));
  yield takeEvery(State.actionRequested(Type.RESET_PASSWORD), safeSaga(resetPassword, Type.RESET_PASSWORD));
  yield takeEvery(State.actionRequested(Type.EMAIL_CHANGE), safeSaga(updateEmail, Type.EMAIL_CHANGE, false));
  yield takeEvery(State.actionRequested(Type.EMAIL_CONFIRM), safeSaga(emailConfirm, Type.EMAIL_CONFIRM, false));
  yield takeEvery(
    State.actionRequested(Type.UPDATE_PHONE_NUMBER),
    safeSaga(updatePhoneNumber, Type.UPDATE_PHONE_NUMBER, false),
  );
}
