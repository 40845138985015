import { AccountTypeDto, JointAccountTypeDto } from 'src/dtos';
import { Maybe } from 'src/utils';

import { AccountHolder } from './accountHolders.models';

export enum AccountTypeLabel {
  Individual = 'Individual',
  Joint = 'Joint',
}

export class AccountType {
  private _value: AccountTypeDto;
  private _label: AccountTypeLabel;

  constructor(type: AccountTypeDto) {
    this._value = type;
    this._label = this.findLabel();
  }

  private findLabel(): AccountTypeLabel {
    switch (this._value) {
      case AccountTypeDto.Individual:
        return AccountTypeLabel.Individual;
      default:
        return AccountTypeLabel.Joint;
    }
  }

  get value(): AccountTypeDto {
    return this._value;
  }

  get label(): AccountTypeLabel {
    return this._label;
  }

  get isIndividual(): boolean {
    return this._value === AccountTypeDto.Individual;
  }

  get isJoint(): boolean {
    return this._value === AccountTypeDto.Joint;
  }
}

export enum JointAccountTypeLabel {
  Community = 'Community Property',
  Survivor = 'Rights of Survivorship',
  TenantsInCommon = 'Tenants in Common',
  TenantsInEntirety = 'Tenants in Entirety',
}

export class JointAccountType {
  private _value?: JointAccountTypeDto;
  private _label?: JointAccountTypeLabel;

  constructor(type?: JointAccountTypeDto) {
    this._value = type;
    this._label = this.findLabel();
  }

  private findLabel(): Maybe<JointAccountTypeLabel> {
    switch (this._value) {
      case JointAccountTypeDto.Community:
        return JointAccountTypeLabel.Community;
      case JointAccountTypeDto.Survivor:
        return JointAccountTypeLabel.Survivor;
      case JointAccountTypeDto.TenantsInCommon:
        return JointAccountTypeLabel.TenantsInCommon;
      case JointAccountTypeDto.TenantsInEntirety:
        return JointAccountTypeLabel.TenantsInEntirety;
    }
  }

  get value(): Maybe<JointAccountTypeDto> {
    return this._value;
  }

  get label(): Maybe<JointAccountTypeLabel> {
    return this._label;
  }

  get isCommunity(): boolean {
    return this._value === JointAccountTypeDto.Community;
  }

  get isSurvivor(): boolean {
    return this._value === JointAccountTypeDto.Survivor;
  }

  get isTenantsInCommon(): boolean {
    return this._value === JointAccountTypeDto.TenantsInCommon;
  }

  get isTenantsInEntirety(): boolean {
    return this._value === JointAccountTypeDto.TenantsInEntirety;
  }
}

export interface Account {
  id: string;
  type: AccountType;
  accountId?: number;
  accountNumber?: string;
  primaryAccountHolder?: AccountHolder;
  secondaryAccountHolder?: AccountHolder;
  jointAccountType?: JointAccountType;
  jointCommunityPropertyState?: { label?: string; value: string };
  tbToken?: string;
}
