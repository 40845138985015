import { call, put, SagaReturnType, select, takeEvery } from 'redux-saga/effects';
import { getClientApi } from 'src/data-communication';
import { InvestigationsApi } from 'src/data-communication/InvestigationsApi';
import { mapGetInvestigationListResponseDtoToModel } from 'src/mappers/investigations.mappers';

import { State, Type } from '../actions/utils';
import { TReduxAction } from '../typings/commonTypes';

import { safeSaga } from './utils';

const clientApi = getClientApi();

export function* getInvestigations(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { accountId } = action?.payload;

  const response: SagaReturnType<InvestigationsApi['list']> = yield call(clientApi.investigations.list, {
    params: {
      accountId,
    },
    authToken,
  });

  yield put({
    type: State.actionSucceeded(Type.GET_INVESTIGATIONS),
    payload: mapGetInvestigationListResponseDtoToModel(response),
  });
}

/**
 * Investigations sagas
 */
export default function* investigationsSaga() {
  yield takeEvery(State.actionRequested(Type.GET_INVESTIGATIONS), safeSaga(getInvestigations, Type.GET_INVESTIGATIONS));
}
