import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { clearToastMessages } from 'src/actions';
import { useAccountSelector } from 'src/hooks';
import { ScreenBreakpoint } from 'src/styles';
import { isAccountPersonalInformationComplete } from 'src/utils';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { AccountHolderMailingAddressSection } from './AccountHolderMailingAddressSection';
import { AccountHolderPhysicalAddressSection } from './AccountHolderPhysicalAddressSection';
import { AccountHolderSection } from './AccountHolderSection';
import { AccountNumberSection } from './AccountNumberSection';
import { AccountTypeSection } from './AccountTypeSection';
import { JointAccountHolderMailingAddressSection } from './JointAccountHolderMailingAddressSection';
import { JointAccountHolderPhysicalAddressSection } from './JointAccountHolderPhysicalAddressSection';
import { JointAccountHolderSection } from './JointAccountHolderSection';

export const PersonalInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPatchPersonalInformationSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.patch.__succeeded),
  );
  const isCreatePhysicalAddressSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.createPhysicalAddress.__succeeded),
  );
  const isPatchPhysicalAddressSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.patchPhysicalAddress.__succeeded),
  );
  const isCreateMailingAddressSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.createMailingAddress.__succeeded),
  );
  const isPatchMailingAddressSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.patchMailingAddress.__succeeded),
  );

  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const hasAnyCreateRequest = () => isCreatePhysicalAddressSuccess || isCreateMailingAddressSuccess;

  const hasAnyPatchRequest = () =>
    isPatchPersonalInformationSuccess || isPatchPhysicalAddressSuccess || isPatchMailingAddressSuccess;

  const hasAnyUpsertRequest = () => hasAnyCreateRequest() || hasAnyPatchRequest();

  const shouldSendAnalyticsEvent = () =>
    !account?.accountId && hasAnyCreateRequest() && isAccountPersonalInformationComplete(account);

  const shouldRedirectToTrustedContact = () =>
    !account?.accountId && hasAnyUpsertRequest() && isAccountPersonalInformationComplete(account);

  useEffect(() => {
    if (shouldSendAnalyticsEvent()) {
      window.gtag('event', 'account_personal_information_complete');
    }
  }, [isCreatePhysicalAddressSuccess, isCreateMailingAddressSuccess]);

  useEffect(() => {
    if (shouldRedirectToTrustedContact()) {
      navigate(`/accounts/trusted-contact`);
    }
  }, [
    isPatchPersonalInformationSuccess,
    isCreatePhysicalAddressSuccess,
    isPatchPhysicalAddressSuccess,
    isCreateMailingAddressSuccess,
    isPatchMailingAddressSuccess,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearToastMessages());
    };
  }, []);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.PersonalInformation}
      title={isMobile ? undefined : account ? 'Personal' : 'Account Type'}
      testId={account ? 'personal' : 'account-type'}>
      <AccountTypeSection />
      <AccountNumberSection />
      <AccountHolderSection />
      <AccountHolderPhysicalAddressSection />
      <AccountHolderMailingAddressSection />
      <JointAccountHolderSection />
      <JointAccountHolderPhysicalAddressSection />
      <JointAccountHolderMailingAddressSection />
    </MyAccountLayout>
  );
};
