import { AnyAction } from 'redux';
import {
  CreateAccountholderDisclosureDto,
  CreateAccountHolderFinancialAssetDto,
  CreateAccountHolderFinancialEmploymentDto,
  CreateAccountHolderMailingAddressDto,
  CreateAccountHolderPhysicalAddressDto,
  CreateAccountHolderSuitabilityInformationDto,
  CreateAccountHolderTrustedContactDto,
  DeleteAccountHolderTrustedContactDto,
  PatchAccountHolderDisclosureDto,
  PatchAccountHolderDto,
  PatchAccountHolderFinancialAssetDto,
  PatchAccountHolderFinancialEmploymentDto,
  PatchAccountHolderMailingAddressDto,
  PatchAccountHolderPhysicalAddressDto,
  PatchAccountHolderSuitabilityInformationDto,
  PatchAccountHolderTrustedContactDto,
} from 'src/dtos';

import { createAction, State, Type } from './utils';

export const doPatchAccountHolder = (payload: PatchAccountHolderDto): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION), payload);
};

export const doClearPatchAccountHolder = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION));

export const doCreateAccountHolderPhysicalAddress = (payload: CreateAccountHolderPhysicalAddressDto): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS), payload);
};

export const doClearCreateAccountHolderPhysicalAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS));

export const doPatchAccountHolderPhysicalAddress = (payload: PatchAccountHolderPhysicalAddressDto): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS), payload);
};

export const doClearPatchAccountHolderPhysicalAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS));

export const doCreateAccountHolderMailingAddress = (payload: CreateAccountHolderMailingAddressDto): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS), payload);
};

export const doClearCreateAccountHolderMailingAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS));

export const doPatchAccountHolderMailingAddress = (payload: PatchAccountHolderMailingAddressDto): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS), payload);
};

export const doClearPatchAccountHolderMailingAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS));

// Trusted Contacts
export const doCreateAccountHolderTrustedContact = (payload: CreateAccountHolderTrustedContactDto): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT), payload);
};

export const doClearCreateAccountHolderTrustedContact = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT));

export const doPatchAccountHolderTrustedContact = (payload: PatchAccountHolderTrustedContactDto): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT), payload);
};

export const doClearPatchAccountHolderTrustedContact = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT));

export const doDeleteAccountHolderTrustedContact = (payload: DeleteAccountHolderTrustedContactDto): AnyAction => {
  return createAction(State.actionRequested(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT), payload);
};

export const doClearDeleteAccountHolderTrustedContact = (): AnyAction =>
  createAction(State.actionCleared(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT));

// Suitability Information
export const doCreateAccountHolderSuitabilityInformation = (
  payload: CreateAccountHolderSuitabilityInformationDto,
): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION), payload);
};

export const doClearCreateAccountHolderSuitabilityInformation = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION));

export const doPatchAccountHolderSuitabilityInformation = (
  payload: PatchAccountHolderSuitabilityInformationDto,
): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION), payload);
};

export const doClearPatchAccountHolderSuitabilityInformation = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION));

// Financial Information
export const doCreateAccountHolderFinancialInformationAssets = (
  payload: CreateAccountHolderFinancialAssetDto,
): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS), payload);
};

export const doClearCreateAccountHolderFinancialInformationAssets = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS));

export const doPatchAccountHolderFinancialInformationAssets = (
  payload: PatchAccountHolderFinancialAssetDto,
): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS), payload);
};

export const doClearPatchAccountHolderFinancialInformationAssets = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS));

export const doCreateAccountHolderFinancialInformationEmployment = (
  payload: CreateAccountHolderFinancialEmploymentDto,
): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT), payload);
};

export const doClearCreateAccountHolderFinancialInformationEmployment = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT));

export const doPatchAccountHolderFinancialInformationEmployment = (
  payload: PatchAccountHolderFinancialEmploymentDto,
): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT), payload);
};

export const doClearPatchAccountHolderFinancialInformationEmployment = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT));

// Disclosures
export const doCreateAccountHolderDisclosures = (payload: CreateAccountholderDisclosureDto): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES), payload);
};

export const doClearCreateAccountHolderDisclosures = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES));

export const doPatchAccountHolderDisclosures = (payload: PatchAccountHolderDisclosureDto): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES), payload);
};

export const doClearPatchAccountHolderDisclosures = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES));
