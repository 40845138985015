import { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearLogin, login, setAuthentication, toastMessagesAdd, toastMessagesRemove } from 'src/actions';
import { AuthenticatedUserLoginResponseDto, LoginResponseDto, MfaRequiredLoginResponseDto } from 'src/dtos';
import { MInput, MInputPassword, MButton, CardLayout } from 'src/lib';
import { Spacing } from 'src/styles';
import {
  renderTradingBlockPasswordExpirationErrorMessage,
  renderTradingBlockSamePasswordErrorMessage,
} from 'src/utils';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import { SeverityEnum } from '../../../typings/commonTypes';
import * as Styles from '../BaseLayout.styles';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password should be 8 characters at minimum')
    .max(24, 'Password should be 24 characters at most'),
});

interface LoginFormValues {
  email: string;
  password: string;
}

export const Login = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const [loginErrorToastId, setLoginErrorToastId] = useState<string | null>(null);
  const failedLoginMessage = useSelector((state: any) => state.user.login?.message);

  const isLoginLoading: boolean = useSelector((state: any) => state.user.login.isLoading);
  const loginSucceded = useSelector((state: any) => Boolean(state.user.login.__succeeded));
  const loginData: LoginResponseDto | undefined = useSelector((state: any) => state.user.login.data);
  const [credentials, setCredentials] = useState<{ email: string; password: string } | null>(null);

  const isMfaRequired = (loginData?: LoginResponseDto): loginData is MfaRequiredLoginResponseDto => {
    if (!loginData) return false;

    return (loginData as MfaRequiredLoginResponseDto).session !== undefined;
  };

  const isLoggedIn = (loginData?: LoginResponseDto): loginData is AuthenticatedUserLoginResponseDto => {
    if (!loginData) return false;

    return (loginData as AuthenticatedUserLoginResponseDto).user !== undefined;
  };

  const removeLoginToastMessageIfFound = () => {
    if (loginErrorToastId) {
      dispatch(toastMessagesRemove({ key: setLoginErrorToastId }));
    }
  };

  const onResetPasswordRequest = () => {
    navigateTo('/forgot-password');
  };

  useEffect(() => {
    if (failedLoginMessage?.error === 'TradingBlockSamePasswordError') {
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderTradingBlockSamePasswordErrorMessage({ onClick: onResetPasswordRequest }),
        }),
      );
      setLoginErrorToastId(toastId);
    }

    if (failedLoginMessage?.error === 'TradingBlockPasswordExpirationError') {
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderTradingBlockPasswordExpirationErrorMessage({ onClick: onResetPasswordRequest }),
        }),
      );
      setLoginErrorToastId(toastId);
    }
  }, [failedLoginMessage]);

  useEffect(() => {
    if (loginSucceded && isMfaRequired(loginData)) {
      dispatch(setAuthentication({ ...loginData, ...credentials }));

      return navigateTo('/login-mfa');
    }

    if (loginSucceded && isLoggedIn(loginData)) {
      window.gtag('event', 'login', {
        login_method: 'sfa',
        user_properties: {
          user_id: loginData.user.id,
          user_email: credentials?.email,
          user_first_name: loginData.user.firstName,
          user_last_name: loginData.user.lastName,
        },
      });
      const { accessToken, idToken, tbToken } = loginData;
      const authToken = `Bearer ${accessToken}; idToken ${idToken}; tbToken ${tbToken}`;
      dispatch(setAuthentication({ authToken, tbToken }));

      return navigateTo('/');
    }
  }, [loginSucceded, loginData]);

  useEffect(() => {
    return () => {
      dispatch(clearLogin());
      removeLoginToastMessageIfFound();
    };
  }, []);

  return (
    <CardLayout
      // banner={{ message: <LegacyAccountDisclaimer />, type: 'info', size: 'small' }}
      title='Welcome!'
      subtitle='In order to Invest in an Offer, you will first need to “Sign up” or “Log in” to My IPO'>
      <Formik<LoginFormValues>
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={loginValidationSchema}
        onSubmit={values => {
          removeLoginToastMessageIfFound();
          setCredentials(values);
          dispatch(login(values));
        }}>
        {form => {
          return (
            <Form>
              <Row className={Spacing.mb16}>
                <Col span={24} className={Spacing.my16}>
                  <MInput
                    placeholder='Email'
                    value={form.values.email}
                    onChange={value => form.setFieldValue('email', value)}
                    error={form.errors.email}
                    name='email_123'
                    autoComplete='off'
                    testId={'email-input'}
                  />
                </Col>
                <Col span={24} className={Spacing.my16}>
                  <MInputPassword
                    placeholder='Password'
                    value={form.values.password}
                    onChange={value => form.setFieldValue('password', value)}
                    error={form.errors.password}
                    name='password_123'
                    autoComplete='new-password'
                    testId={'password-input'}
                  />
                </Col>
              </Row>
              <Link className={Styles.link} to='/forgot-password' data-testid='forgot-password-link'>
                Forgot your password?
              </Link>
              <Row className={Styles.buttonsContainer}>
                <MButton
                  type='secondary'
                  disabled={isLoginLoading || !form.isValid}
                  onClick={() => form.submitForm()}
                  loading={isLoginLoading}
                  testId={'login-button'}>
                  Log in
                </MButton>
              </Row>
              <Row className={Styles.alreadyHaveAccountLabel}>
                <span className={Styles.space} data-testid='do-not-have-acc-testId'>
                  Don’t have an account?
                </span>
                <Link to='/register' className={Styles.linkButton} data-testid='signup-link'>
                  Sign up now.
                </Link>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </CardLayout>
  );
};
