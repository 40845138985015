import React from 'react';
import type { PropsWithChildren } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Row } from 'antd';

import { SpaceSize } from '../../styles';

import * as Styles from './MDrawer.styles';

export interface MDrawerProps {
  isOpen: boolean;
  title?: string;
  onClose?: () => void;
  header?: JSX.Element;
  closeIcon?: JSX.Element;
}

export const MDrawer = ({
  isOpen,
  onClose,
  closeIcon,
  title,
  header,
  children,
}: PropsWithChildren<MDrawerProps>): JSX.Element => {
  const _onClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Drawer
      className={Styles.drawerContainer}
      placement='right'
      onClose={_onClose}
      open={isOpen}
      headerStyle={{ paddingTop: SpaceSize.is16, paddingBottom: SpaceSize.is16 }}
      title={title}
      closeIcon={<div className={Styles.drawerCloseIconContainer}>{closeIcon ?? <CloseOutlined />}</div>}
      extra={
        <Row justify='center' align='middle'>
          {header}
        </Row>
      }
      width={285}>
      {children}
    </Drawer>
  );
};
