import { useEffect, useState } from 'react';

import { isUndefined } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { JointAccountInterestForm } from './JointAccountInterestForm';
import { JointAccountInterestView } from './JointAccountInterestView';

export const JointAccountInterestSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isPercentInterestAlreadySaved = () =>
    !isUndefined(account?.primaryAccountHolder?.jointTenantsInCommonInterestPercent) &&
    !isUndefined(account?.secondaryAccountHolder?.jointTenantsInCommonInterestPercent);

  useEffect(() => {
    if (isPercentInterestAlreadySaved() && !Boolean(account?.accountId)) {
      setIsEditable(true);
    }
  }, [account]);

  if (!account?.type.isJoint || !account?.jointAccountType?.isTenantsInCommon) {
    return null;
  }

  return (
    <>
      <MFormSection
        title='Tenants in Common Interest Percent'
        onEdit={onEdit}
        isEditable={isEditable}
        isEditMode={isEditMode}
        tooltip={{
          type: 'modal',
          icon: 'info',
          title: 'Tenants in Common Interest Percent',
          label: 'Please specify each account holder’s share of interest. Total must equal 100%.',
          content: <>Please specify each account holder’s share of interest. Total must equal 100%. </>,
        }}
        testId={'tenants-in-common'}>
        {isPercentInterestAlreadySaved() && !isEditMode ? (
          <JointAccountInterestView account={account} />
        ) : (
          <JointAccountInterestForm account={account} onCancel={onCancel} />
        )}
      </MFormSection>
    </>
  );
};
