import { MModal } from 'src/lib';

export interface TermsAndConditionsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TermsAndConditionsModal = ({ isOpen, onClose }: TermsAndConditionsModalProps) => {
  return (
    <MModal
      customWidth={725}
      visible={isOpen}
      title='Terms and Conditions'
      tertiaryButtonText='Close'
      onTertiaryButtonClick={onClose}
      onClose={onClose}>
      <div>
        <ul>
          <li>
            <p>The decision to purchase this security rests solely with me</p>
          </li>
          <li>
            <p>I have the appropriate investor status and/or authority to make this investment</p>
          </li>

          <li>
            <p>
              I agree to permit My IPO and Apex Clearing Corporation to provide to the issuer my name, address, and
              other personal information on an ongoing basis to the extent I continue to hold this security
            </p>
          </li>

          <li>
            <p>
              My IPO, Apex Clearing Corporation and the issuer are relying on the truth and accuracy of the information
              provided and representations made with respect to submission of this investment request
            </p>
          </li>

          <li>
            <p>
              No person representing the issuer or My IPO, Apex Clearing Corporation or purporting to represent the
              issuer or My IPO and Apex Clearing Corporation io has made any oral representation that is inconsistent
              with the information contained in the offering documents
            </p>
          </li>

          <li>
            <p>
              My IPO and Apex Clearing Corporation has made no representations to me as to the suitability of this
              investment, unless otherwise noted on this site
            </p>
          </li>

          <li>
            <p>
              If I am using this site as an authorized representative of an investor, I represent that I am capable of
              evaluating the investment risks associated with investing in unregistered and/or unlisted securities
              independently and I am exercising my independent judgment in concluding that this investment is
              appropriate for my client
            </p>
          </li>

          <li>
            <p>
              If I am not an accredited investor and if the security I&#39;m subscribing to here is an unlisted security
              that is issued under Regulation A Tier 2, then I certify that my subscription represents less than 10% of
              the greater of my annual income or net worth (less than 10% of the greater of annual revenue or net assets
              at fiscal year end if the investor is a corporate entity)
            </p>
          </li>

          <li>
            <p>Take the time to read and carefully understand the terms of the proposed investment.</p>
          </li>

          <li>
            <p>
              Investments in unregistered and/or unlisted securities generally have a higher level of risk than national
              exchange traded securities. Only limited information may be available about these securities and their
              issuers.
            </p>
          </li>

          <li>
            <p>
              Unregistered and/or unlisted securities generally may be illiquid. Investors may have no ability to sell
              or transfer the securities, and even where a sale or transfer is permitted, there may be no buyers.
            </p>
          </li>

          <li>
            <p>
              You must not have a contract, agreement or arrangement, formal or informal, with any person to sell,
              transfer or pledge any interest or rights in the securities, and have no present plans to enter into any
              such obligation.
            </p>
          </li>

          <li>
            <p>
              The issuer is ultimately responsible for deciding who is permitted to purchase securities in the offering
              and the amount of the investment. Even if an investor meets all qualification requirements, the issuer may
              reduce requested investment amounts or reject investment requests completely.
            </p>
          </li>

          <li>
            <p>
              The securities purchased may not be transferable to another brokerage firm. If the securities remain in an
              account with us, you may be unable to close the account and may be obligated to pay our fees.
            </p>
          </li>

          <li>
            <p>Securities purchased by credit card are funded and held outside of your My IPO account.</p>
          </li>

          <li>
            <p>
              Payment made by credit card will be sent to the Issuers escrow account and sent to the Issuer if accepted
              once the closing occurs. If your Order is completed, your Shares will be held in electronic form at the
              Issuer’s Transfer Agent. Some brokerage firms may not hold these securities or may charge fees to deposit
              these securities.
            </p>
          </li>

          <li>
            <p>
              You are responsible for choosing this investment and you should not assume that anyone (including My IPO
              and Apex Clearing Corporation, who is our clearing firm) has investigated, analyzed or otherwise evaluated
              the investment or determined that it is appropriate for you. The fact that an investment opportunity is
              made available through our web site is not an endorsement of the investment by anyone.
            </p>
          </li>
        </ul>
        <p>
          MY IPO is the online offering platform division of AOS, Inc. which does business as TradingBlock and My IPO.
          AOS, Inc. is a registered broker-dealer and member of FINRA and SIPC. All securities are only offered by AOS
          Inc. dba TradingBlock and My IPO. Apex Clearing Corporation is the clearing firm for AOS, Inc. dba My IPO and
          a registered broker-dealer and a member of FINRA and SIPC. ALL SECURITIES ARE ONLY OFFERED BY AOS, INC. DOING
          BUSINESS AS MY IPO. This is neither an offer to sell nor a solicitation of an offer to buy securities.
        </p>
      </div>
    </MModal>
  );
};
