import { cx } from '@emotion/css';
import { Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useAccountSelector } from 'src/hooks';
import { BaseStyle, ScreenBreakpoint, Spacing } from 'src/styles';

import { AccountHolderFullName } from './AccountHolderFullName';
import * as Styles from './Header.styles';

export interface UserInfoProps {
  onClick?: () => void;
}

export const UserInfo = ({ onClick }: UserInfoProps) => {
  const { account } = useAccountSelector();
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  return (
    <Row className={BaseStyle.level}>
      {isMobile ? null : (
        <span className={cx(Styles.iconContainer, Spacing.mr8)}>
          <i className='ri-user-line' style={{ fontSize: 24 }} />
        </span>
      )}
      <AccountHolderFullName account={account} alignment={isMobile ? 'center' : 'start'} />
      {isMobile ? null : (
        <span onClick={onClick} className={cx(Styles.iconContainer, Spacing.ml12)}>
          <i className='ri-arrow-down-s-line' style={{ fontSize: 24 }} data-testid='summary-dropdown-menu' />
        </span>
      )}
    </Row>
  );
};
