import produce from 'immer';
import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import { failedDefaults, requestedDefaults, succeededDefaults, clearedDefaults } from './utils';

export const list = (state: any = initialState.offerOrders.list, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.GET_OFFER_ORDERS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_OFFER_ORDERS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_OFFER_ORDERS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.offerOrders.list;
    default:
      return state;
  }
};

export const details = (state: any = initialState.offerOrders.details, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.GET_OFFER_ORDER_DETAILS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_OFFER_ORDER_DETAILS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_OFFER_ORDER_DETAILS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionCleared(Type.GET_OFFER_ORDER_DETAILS):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.offerOrders.details;
    default:
      return state;
  }
};

export const create = (state: any = initialState.offerOrders.create, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.CREATE_OFFER_ORDERS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CREATE_OFFER_ORDERS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_OFFER_ORDERS):
      return failedDefaults(state, action);
    case State.actionCleared(Type.CREATE_OFFER_ORDERS):
      return clearedDefaults(state);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.offerOrders.create;
    default:
      return state;
  }
};

export const update = (state: any = initialState.offerOrders.update, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.UPDATE_OFFER_ORDER):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_OFFER_ORDER):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.UPDATE_OFFER_ORDER):
      return failedDefaults(state, action);
    case State.actionCleared(Type.UPDATE_OFFER_ORDER):
      return clearedDefaults(state);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.offerOrders.update;
    default:
      return state;
  }
};

export const deleteOfferOrder = (state: any = initialState.offerOrders.delete, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.DELETE_OFFER_ORDER):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.DELETE_OFFER_ORDER):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.DELETE_OFFER_ORDER):
      return failedDefaults(state, action);
    case State.actionCleared(Type.DELETE_OFFER_ORDER):
      return clearedDefaults(state);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.offerOrders.delete;
    default:
      return state;
  }
};

export const offerOrderIntent = (state: any = initialState.offerOrders.intent, action: any) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case Type.ADD_OFFER_ORDER_INTENT:
        return action.payload;
      case Type.REMOVE_OFFER_ORDER_INTENT:
        return null;
      case State.actionSucceeded(Type.LOGOUT):
      case State.actionSucceeded(Type.SWITCH_ACCOUNT):
      case State.actionSucceeded(Type.CREATE_ACCOUNT):
        return null;
      default:
        return draft;
    }
  });

export const createCardPaymentIntent = (state: any = initialState.offerOrders.createCardPaymentIntent, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT):
      return failedDefaults(state, action);
    case State.actionCleared(Type.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT):
      return clearedDefaults(state);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.offerOrders.createCardPaymentIntent;
    default:
      return state;
  }
};

export const updateCardPaymentIntent = (state: any = initialState.offerOrders.updateCardPaymentIntent, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT):
      return failedDefaults(state, action);
    case State.actionCleared(Type.UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT):
      return clearedDefaults(state);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.offerOrders.updateCardPaymentIntent;
    default:
      return state;
  }
};

export const submitCardPayment = (state: any = initialState.offerOrders.submitCardPayment, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.SUBMIT_CARD_PAYMENT):
      return true;
    case State.actionCleared(Type.SUBMIT_CARD_PAYMENT):
      return false;
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.offerOrders.submitCardPayment;
    default:
      return state;
  }
};

export const confirmCardPayment = (state: any = initialState.offerOrders.confirmCardPayment, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.CONFIRM_CARD_PAYMENT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CONFIRM_CARD_PAYMENT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CONFIRM_CARD_PAYMENT):
      return failedDefaults(state, action);
    case State.actionCleared(Type.CONFIRM_CARD_PAYMENT):
      return clearedDefaults(state);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.offerOrders.confirmCardPayment;
    default:
      return state;
  }
};

export default combineReducers({
  details,
  list,
  create,
  update,
  delete: deleteOfferOrder,
  intent: offerOrderIntent,
  createCardPaymentIntent,
  updateCardPaymentIntent,
  submitCardPayment,
  confirmCardPayment,
});
