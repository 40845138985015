import { countryCodes } from 'src/constants/countryCodes';
import { mask } from 'src/utils';
import * as Yup from 'yup';

const validationMessages = {
  firstNameRequired: 'First Name is required.',
  firstNameMinCharacters: 'First Name is too short',
  firstNameMaxCharacters: 'First Name can have a maximum length of 40 characters',
  lastNameRequired: 'Last Name is required.',
  lastNameMinCharacters: 'Last Name is too short',
  lastNameMaxCharacters: 'Last Name can have a maximum length of 40 characters',
  phoneNumbersRequired: 'At least a valid phone number is required',
  emailNameRequired: 'Email is required.',
  emailNameInvalid: 'Email is invalid',
  address1Required: 'Address Line 1 is required',
  address1Invalid: 'Adress Line 1 can have a maximum length of 30 characters',
  address2Required: 'Address Line 2 is required',
  address2Invalid: 'Adress Line 2 can have a maximum length of 30 characters',
  cityRequired: 'City is required',
  cityInvalid: 'City can have a maximum length of 30 characters',
  stateRequired: 'State is required',
  postalCodeRequired: 'Zip Code is required',
  postalCodeInvalid: 'Zip Code value should be between 0 and 100000',
  countryRequired: 'Country is required',
  countryInvalid: 'Country is required',
  fieldShouldContainOnlyLetters: 'Field should contain only letters',
};

export const upsertAccountHolderTrustedContactValidation = Yup.object().shape({
  firstName: Yup.string()
    .required(validationMessages.firstNameRequired)
    .trim()
    .min(2, validationMessages.firstNameMinCharacters)
    .max(40, validationMessages.firstNameMaxCharacters)
    .matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, validationMessages.fieldShouldContainOnlyLetters),
  lastName: Yup.string()
    .required(validationMessages.lastNameRequired)
    .trim()
    .min(2, validationMessages.lastNameMinCharacters)
    .max(40, validationMessages.lastNameMaxCharacters)
    .matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, validationMessages.fieldShouldContainOnlyLetters),
  phones: Yup.array()
    .of(
      Yup.object({
        prefix: Yup.string().required('Phone Number country code is required'),
        value: Yup.string()
          .required('Phone Number is required')
          .test('compare', 'Phone Number is invalid', (value: any, schema: any) => {
            if (isNaN(Number(value))) {
              return false;
            }
            const country = countryCodes.find(anItem => anItem.dialCode === schema?.parent?.prefix);
            const masked = mask({ value, mask: country?.phoneMask ?? '', substitute: '9' });

            return masked?.length == country?.phoneMask?.length;
          }),
        type: Yup.string().required('Phone Number type is required'),
      }),
    )
    .required('At least a valid Phone Number is required'),
  email: Yup.string().required(validationMessages.emailNameRequired).trim().email(validationMessages.emailNameInvalid),
  address1: Yup.string()
    .trim()
    .required(validationMessages.address1Required)
    .max(30, validationMessages.address1Invalid),
  address2: Yup.string().trim().max(30, validationMessages.address2Invalid),
  country: Yup.string().required(validationMessages.countryRequired).max(100, validationMessages.countryInvalid),
  city: Yup.string().required(validationMessages.cityRequired).max(30, validationMessages.cityInvalid),
  state: Yup.string().required(validationMessages.stateRequired),
  postalCode: Yup.string()
    .trim()
    .required(validationMessages.postalCodeRequired)
    .max(6)
    .test('Digits only', 'Postal Code should have digits only', (value: any) => /^\d+$/.test(value))
    .test(
      'Range size',
      'Postal Code value should be between 0 and 100000',
      (value: any) => 0 < Number(value) && Number(value) < 100000,
    ),
});
