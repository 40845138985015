import { MFormRadioGroupField } from 'src/lib';
import { Account } from 'src/models';

import { AccountTypeDescription } from './AccountTypeDescription';
import { JointAccountTypeDescription } from './JointAccountTypeDescription';

export interface AccountTypeViewProps {
  account?: Account;
}

export const AccountTypeView = ({ account }: AccountTypeViewProps) => {
  return (
    <>
      <MFormRadioGroupField
        label='What type of account do you wish to open?'
        value={account?.type.label}
        description={<AccountTypeDescription value={account?.type.value} />}
        alignment='vertical'
        testId={'account-type'}
      />
      {account?.type.isJoint && (
        <MFormRadioGroupField
          label='Type of Joint Account'
          value={account?.jointAccountType?.label}
          description={<JointAccountTypeDescription value={account?.jointAccountType?.value} />}
          alignment='vertical'
        />
      )}
      {account?.type.isJoint && account?.jointAccountType?.isCommunity && account?.jointCommunityPropertyState && (
        <MFormRadioGroupField
          label='Property State'
          value={account?.jointCommunityPropertyState?.label}
          alignment='vertical'
        />
      )}
    </>
  );
};
