import { useEffect } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { doClearPatchJointAccountInterest, doPatchJointAccountInterest } from 'src/actions';
import { MFormInput, MFormSaveButton } from 'src/lib';
import { Account } from 'src/models';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  primaryAccountHolder: Yup.number()
    .typeError('Primary account holder percent must be a number')
    .required('Primary account holder is required'),
  secondaryAccountHolder: Yup.number()
    .typeError('Joint account holder percent must be a number')
    .required('Joint account holder is required')
    .test(
      'is-percent-total-100',
      'Total share of interest for Primary and Joint account holder must equal 100',
      function (value) {
        const primaryAccountHolder = this.parent.primaryAccountHolder ?? 0;
        const secondaryAccountHolder = value ?? 0;

        return primaryAccountHolder + secondaryAccountHolder === 100;
      },
    ),
});

interface JointAccountInterestFormValues {
  primaryAccountHolder?: number;
  secondaryAccountHolder?: number;
}

interface JointAccountInterestFormProps {
  account: Account;
  onCancel?: () => void;
  onSave?: () => void;
}

export const JointAccountInterestForm = ({ account, onCancel, onSave }: JointAccountInterestFormProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => state.accounts.patchJointInterest.isLoading);
  const isSuccess = useSelector((state: any) => Boolean(state.accounts.patchJointInterest.__succeeded));

  const isPercentInterestAlreadySaved = () =>
    !isUndefined(account?.primaryAccountHolder?.jointTenantsInCommonInterestPercent) &&
    !isUndefined(account?.secondaryAccountHolder?.jointTenantsInCommonInterestPercent);

  const _onCancel = (form: FormikProps<JointAccountInterestFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<JointAccountInterestFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    return () => {
      dispatch(doClearPatchJointAccountInterest());
    };
  }, []);

  return (
    <Formik<JointAccountInterestFormValues>
      enableReinitialize
      initialValues={{
        primaryAccountHolder: account.primaryAccountHolder?.jointTenantsInCommonInterestPercent,
        secondaryAccountHolder: account.secondaryAccountHolder?.jointTenantsInCommonInterestPercent,
      }}
      validationSchema={validationSchema}
      onSubmit={values => {
        dispatch(
          doPatchJointAccountInterest({
            params: { id: account.id },
            body: {
              primaryAccountHolder: { percent: values.primaryAccountHolder ?? 0 },
              secondaryAccountHolder: { percent: values.secondaryAccountHolder ?? 0 },
            },
          }),
        );
      }}>
      {form => {
        return (
          <>
            <Col span={24}>
              <MFormInput
                testId={'primary-account-holder-joint-interest'}
                label={
                  account.primaryAccountHolder?.firstName && account.primaryAccountHolder.lastName
                    ? `${account.primaryAccountHolder.firstName} ${account.primaryAccountHolder.middleName ?? ''} ${
                        account.primaryAccountHolder.lastName
                      } ${account.primaryAccountHolder.suffix ?? ''}`
                    : 'Primary Account Holder'
                }
                placeholder='Enter'
                value={form.values.primaryAccountHolder}
                defaultValue={account.primaryAccountHolder?.jointTenantsInCommonInterestPercent?.toString()}
                error={form.errors.primaryAccountHolder}
                onChange={value => {
                  form.setFieldValue('primaryAccountHolder', value);
                }}
              />
            </Col>

            <Col span={24}>
              <MFormInput
                testId={'secondary-account-holder-joint-interest'}
                label={
                  account?.secondaryAccountHolder?.firstName && account?.secondaryAccountHolder.lastName
                    ? `${account.secondaryAccountHolder.firstName} ${
                        account.secondaryAccountHolder?.middleName ?? ''
                      } ${account.secondaryAccountHolder.lastName}
                    ${account.secondaryAccountHolder.suffix ?? ''}`
                    : 'Joint Account Holder'
                }
                placeholder='Enter'
                value={form.values.secondaryAccountHolder}
                defaultValue={account.secondaryAccountHolder?.jointTenantsInCommonInterestPercent?.toString()}
                error={form.errors.secondaryAccountHolder}
                onChange={value => {
                  form.setFieldValue('secondaryAccountHolder', value);
                }}
              />
            </Col>

            <Col span={24}>
              <MFormSaveButton<JointAccountInterestFormValues>
                loading={isLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode={isPercentInterestAlreadySaved()}
                form={form}
                testId={'joint-account-interest'}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
