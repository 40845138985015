export const customerAgreementLinks: Array<{ label: string; url: string }> = [
  {
    label: 'Customer Agreement',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Customer_Agreement_v5.6_20200424.pdf',
  },
  {
    label: 'FINRA Rule 4311 Disclosure',
    url: '/static/docs/FINRA_Rule_4311_Disclosure.pdf',
  },
];
