import { css, cx } from '@emotion/css';
import { AlignItems, ColorConstant, Display, Font, FontWeight, ScreenBreakpoint, Spacing } from 'src/styles';

export const mobileUserInfoWrapper = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 24px;
`;

// Desktop menu item text
export const menuItemTextContainer = css`
  width: 100%;
`;

export const inactiveAccountMenuItemTextIconContainer = css`
  height: 32px;
  width: 32px;
  ${Spacing.mr24}
`;

export const inactiveAccountMenuItemTextIcon = (isPending: boolean) =>
  cx(
    'ri-error-warning-fill',
    css`
      color: ${isPending ? ColorConstant.ORANGE6 : ColorConstant.RED6};
      font-size: 32px;
    `,
  );

// AccountValue component
export const accountValueContainer = css`
  ${Font.h1}
`;

export const accountValue = css`
  ${FontWeight.semibold}
  color: ${ColorConstant.GREEN7};
`;

export const accountLabel = css`
  color: ${ColorConstant.GRAY7};
`;

// AccountHolderFullName component
export const fullName = css`
  ${Font.h1}
  ${FontWeight.semibold}
`;

export const accountType = css`
  ${Font.md}
  color: ${ColorConstant.GRAY8};
`;

// DesktopMenuLinkItem
export const desktopMenuLink = css`
  ${Font.lg}
  color: ${ColorConstant.GRAY8};
  ${Spacing.pl24}
  ${Spacing.py8}
  ${Spacing.mb8}
  ${Display.flex}
  ${AlignItems.center}
  :hover {
    background: ${ColorConstant.GRAY2};
  }
`;

export const desktopActiveMenuLink = css`
  ${FontWeight.bold}
  color: ${ColorConstant.BRAND6};
  background: #e6eef6;
`;

// UserInfo
export const iconContainer = css`
  width: 32px;
  height: 32px;
  ${FontWeight.normal}
`;

export const menuSubtitle = css`
  ${Spacing.pl24}
  ${Spacing.py8}
  ${Spacing.mb8}
  color: ${ColorConstant.GRAY7};
  ${Font.sm}

  @media (max-width: ${ScreenBreakpoint.laptop.max}) {
    ${Spacing.pl0}
  }
`;

// MobileHeader
export const mobileMenuItem = css`
  ${Font.lg}
  ${Spacing.my16}

  a {
    color: ${ColorConstant.GRAY10};
  }
`;

export const logoutMenuItemColor = css`
  color: ${ColorConstant.RED6};
`;

export const menuItemWithoutLink = cx(
  desktopMenuLink,
  css`
    width: 100%;
    cursor: pointer;
  `,
);
