import { useEffect, useState } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { doClearSwitchAccount, doSwitchAccount, getAuthenticatedUser, getInvestigations } from 'src/actions';
import { useAccountSelector, useAuthenticatedUserSelector, useFindOtherAccountList } from 'src/hooks';
import { Account, InvestigationStatus } from 'src/models';
import { MAIN_PATH } from 'src/pages/Main';
import { HISTORY_PATH } from 'src/pages/Main/pages/History/History';
import { SUMMARY_PATH } from 'src/pages/Main/pages/Summary/Summary';
import { ColorConstant, Spacing } from 'src/styles';

import { logo } from '../../styles/images';
import { MDivider } from '../MDivider/MDivider';
import { MDrawer } from '../MDrawer/MDrawer';

import { AccountStatusBanner } from './AccountStatusBanner';
import { AccountValue } from './AccountValue';
import { getAccountSettingMenuItemList, getUserSettingMenuItemList } from './constants';
import * as Styles from './Header.styles';
import './index.css';
import { LogoutMenuItem } from './LogoutMenuItem';
import { SwitchAccountMenuItem } from './SwitchAccountMenuItem';
import { UserInfo } from './UserInfo';

export type MobileHeaderProps = {
  authToken?: string;
  pathName: string;
  status?: InvestigationStatus;
};

export const MobileHeader = ({ authToken }: MobileHeaderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSwitchLoading: boolean = useSelector((state: any) => state.accounts.switch.isLoading);
  const isSwitchSuccess: boolean = useSelector((state: any) => Boolean(state.accounts.switch.__succeeded));
  const isInvestigationLoading: boolean = useSelector((state: any) => state.investigations.list.isLoading);
  const [switchToAccountId, setSwitchToAccountId] = useState<string | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [step, setStep] = useState<'account' | 'switch'>('account');

  const { isUserLoading } = useAuthenticatedUserSelector();
  const { account, hasAccountSucceeded } = useAccountSelector();
  // const canCreateAccount = useCanCreateAccount();
  // const canSwitchAccount = useCanSwitchAccount();
  const otherAccountList = useFindOtherAccountList();

  // const onCreateAccount = () => {
  //   dispatch(doClearGetAccount());
  //   dispatch(doClearGetInvestigations());
  //   navigate('/accounts/personal-information');
  // };

  // const onGoToSwitchStep = () => {
  //   setStep('switch');
  // };

  const onGoBackToAccountStep = () => {
    setStep('account');
  };

  const switchTo = (account: Account) => {
    setSwitchToAccountId(account.id);
    dispatch(doSwitchAccount({ params: { id: account.id } }));
  };

  const renderAccountSettingMenuItemList = () => {
    return (
      <Row>
        {getAccountSettingMenuItemList(account).map(anItem => (
          <Col span={24} key={anItem.key} className={Styles.mobileMenuItem}>
            <Link to={anItem.path(account?.id)}>{anItem.name}</Link>
          </Col>
        ))}
      </Row>
    );
  };

  const renderUserSettingMenuItemList = () => {
    return (
      <Row>
        {getUserSettingMenuItemList(account).map(anItem => (
          <Col span={24} key={anItem.key} className={Styles.mobileMenuItem}>
            <Link to={anItem.path()}>{anItem.name}</Link>
          </Col>
        ))}
      </Row>
    );
  };

  const renderResourceLinkList = () => {
    if (step === 'switch') return null;

    return (
      <Row>
        <Col span={24}>
          <MDivider />
        </Col>
        <Col span={24} className={Styles.mobileMenuItem}>
          <Link to='/about'>About</Link>
        </Col>
        {authToken ? null : (
          <Col span={24} className={Styles.mobileMenuItem}>
            <Link to='/offers'>Offers</Link>
          </Col>
        )}
        <Col span={24} className={Styles.mobileMenuItem}>
          <Link to='/faq'>FAQ</Link>
        </Col>
      </Row>
    );
  };

  const renderLogout = () => {
    return authToken ? (
      <Row>
        <Col span={24}>
          <MDivider />
        </Col>
        <LogoutMenuItem onClick={() => setIsDrawerOpen(false)} className={Spacing.pl0} />
      </Row>
    ) : null;
  };

  const renderOtherAccountList = () => {
    if (isEmpty(otherAccountList)) return null;

    return (
      <>
        <Row>
          <MDivider />
        </Row>
        <Row>
          <Col span={24}>
            <span className={Styles.menuSubtitle}>OTHER ACCOUNTS</span>
          </Col>
          {otherAccountList.map(acc => (
            <Col span={24} key={acc.id}>
              <SwitchAccountMenuItem
                account={acc}
                onClick={() => switchTo(acc)}
                isLoading={switchToAccountId === acc.id && (isSwitchLoading || isUserLoading)}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  };

  const renderAccountStep = () => {
    return (
      <Row>
        <Col span={24}>
          <UserInfo />
        </Col>
        {account?.accountId && (
          <Col span={24} className={Spacing.mb24}>
            <AccountValue />
          </Col>
        )}
        <Col span={24} className={Styles.mobileMenuItem}>
          <Link to={`/accounts/personal-information`}>My account</Link>
        </Col>
        {/* {canSwitchAccount && (
          <Col span={24} className={Styles.mobileMenuItem}>
            <div
              className={cx(Display.flex, AlignItems.center, JustifyContent.spaceBetween)}
              onClick={onGoToSwitchStep}>
              <span>Switch Account </span>
              <span>
                <i className='ri-arrow-right-s-line' style={{ fontSize: 24 }} />
              </span>
            </div>
          </Col>
        )} */}
        {/* {canCreateAccount && (
          <Col span={24} className={Styles.mobileMenuItem}>
            <Link
              to='/accounts/personal-information'
              onClick={onCreateAccount}
              className={cx(Display.flex, AlignItems.center)}>
              <span className={Spacing.mr8}>
                <i className='ri-user-add-line' style={{ fontSize: 16 }}></i>
              </span>
              <span>Add New Account Type </span>
            </Link>
          </Col>
        )} */}
        {account?.accountId && (
          <>
            <Col span={24}>
              <MDivider />
            </Col>
            <Col span={24}>
              <span className={Styles.menuSubtitle}> ACCOUNT SETTINGS</span>
              {renderAccountSettingMenuItemList()}
            </Col>
          </>
        )}
        <Col span={24}>
          <MDivider />
        </Col>
        <Col span={24}>
          <span className={Styles.menuSubtitle}> USER SETTINGS</span>
          {renderUserSettingMenuItemList()}
        </Col>
        <Col span={24}>
          <MDivider />
        </Col>
        <Col span={24} className={Styles.mobileMenuItem}>
          <Link to={`/${MAIN_PATH}/${SUMMARY_PATH}`}>Summary</Link>
        </Col>
        <Col span={24} className={Styles.mobileMenuItem}>
          <Link to='/offers'>Offers</Link>
        </Col>
        <Col span={24} className={Styles.mobileMenuItem}>
          <Link to={`${MAIN_PATH}/${HISTORY_PATH}`}>History</Link>
        </Col>
      </Row>
    );
  };

  const renderSwitchStep = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <span className={Styles.menuSubtitle}>CURRENT ACCOUNT</span>
          </Col>
          <Col span={24}>
            <SwitchAccountMenuItem account={account} onClick={onGoBackToAccountStep} />
          </Col>
        </Row>
        {renderOtherAccountList()}
      </>
    );
  };

  useEffect(() => {
    if (account?.accountId && !isInvestigationLoading) dispatch(getInvestigations(account.accountId));
  }, [account?.accountId]);

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  useEffect(() => {
    if (isSwitchSuccess) {
      dispatch(getAuthenticatedUser());
    }

    if (isSwitchSuccess && hasAccountSucceeded) {
      setIsDrawerOpen(false);
      navigate('/');
      setStep('account');
    }

    return () => {
      dispatch(doClearSwitchAccount());
    };
  }, [isSwitchSuccess, hasAccountSucceeded]);

  return (
    <div>
      <Row justify='center'>
        <Col span={24}>
          <div className='mobileHeader'>
            <Link to='/'>
              <img className='logo' src={logo} alt='My IPO' />
            </Link>
            <Button
              icon={<MenuOutlined style={{ fontSize: '20px', color: ColorConstant.BRAND6 }} />}
              onClick={() => setIsDrawerOpen(true)}
              className='mobileNavBtn'
            />
            <MDrawer
              closeIcon={
                step === 'account' ? undefined : (
                  <span>
                    <i className='ri-arrow-left-line' />
                  </span>
                )
              }
              isOpen={isDrawerOpen}
              onClose={() => {
                if (step === 'switch') {
                  setStep('account');

                  return;
                }
                setIsDrawerOpen(false);
              }}
              title={step === 'account' ? undefined : 'Accounts'}>
              {!authToken ? (
                <Row>
                  <Col span={24} className={Styles.mobileMenuItem}>
                    <Link to={`/login`}>Login</Link>
                  </Col>
                  <Col span={24} className={Styles.mobileMenuItem}>
                    <Link to={`/register`}>Register</Link>
                  </Col>
                </Row>
              ) : (
                <>{step === 'account' ? renderAccountStep() : renderSwitchStep()}</>
              )}
              {renderResourceLinkList()}
              {renderLogout()}
            </MDrawer>
          </div>
        </Col>
        <AccountStatusBanner />
      </Row>
    </div>
  );
};
