import { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Col } from 'antd';
import { Formik } from 'formik';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addOfferOrderIntent, removeOfferOrderIntent } from 'src/actions';
import { DefinitionConstant } from 'src/constants';
import { useAccountSelector, useAccountBalance } from 'src/hooks';
import {
  MCheckbox,
  MFormCurrencyField,
  MFormCurrencyInput,
  MFormCurrencyRangeField,
  MFormInput,
  MTooltip,
  MButton,
} from 'src/lib';
import { OfferOrderIntent, OfferDetails } from 'src/models';
import { BaseStyle, Images, Spacing } from 'src/styles';
import { isRegAPlusOffer, isRegDTypeCOffer, assertNonNullable, assertPropertyIsFound } from 'src/utils';

import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import CurrencyField from '../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { ThisOfferOrderWillDisclaimer } from '../../../../../lib/ThisOfferOrderWillDisclaimer/ThisOfferOrderWillDisclaimer';
import { DOCUMENTS_PATH } from '../NewOfferOrderDocumentsStep/NewOfferOrderDocumentsStep';
import * as Styles from '../NewOfferOrders.styles';

import { Accreditation } from './Accreditation';
import {
  getConditionalOfferOrderValidationSchema,
  getStandardOfferOrderValidationSchema,
} from './NewOfferOrder.validations';
import { WhatsRestrictedPersonModalContent } from './WhatsRestrictedPersonaModalContent';

export const ORDER_PATH = 'new';

export enum InvestorType {
  Accredited = 'accredited',
  NonAccredited = 'nonAccredited',
}

export interface NewOfferOrderForm {
  quantity?: string;
  isNotRestrictedPerson?: boolean;
  investorType?: InvestorType;
  totalInvestment?: string;
}

export const NewOfferOrder = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const { account } = useAccountSelector();

  const offerOrderIntent: OfferOrderIntent | null = useSelector((state: any) => state.offerOrders.intent);

  const { cashAvailable } = useAccountBalance();

  const [isAccreditationValid, setIsAccreditationValid] = useState<boolean>(true); // NOTE-LA: Unable to synchronize accreditation form validation with offer order. Quantity field overwrites form errors.

  const findTotalCost = (quantity?: string): number =>
    Number((Number(quantity) * offerDetails.pricePerShare).toFixed(2));

  const shouldReuseOfferOrderIntent = () =>
    params.offerId && offerOrderIntent && params.offerId === offerOrderIntent.offerId;

  const shouldDisplayAccreditation = () => isRegAPlusOffer(offerDetails) || isRegDTypeCOffer(offerDetails);

  const hasManySecurityDeliveryOptions = () =>
    Boolean(
      offerDetails.isExternalOffer &&
        offerDetails.externalDetails &&
        offerDetails.externalDetails.securityDeliveryOptions.length > 1,
    );

  const getConditionalOfferInitialFormValues = () => {
    let result: NewOfferOrderForm = {
      totalInvestment: shouldReuseOfferOrderIntent() ? offerOrderIntent?.totalInvestment?.toString() ?? '' : '',
    };

    if (!offerDetails.allowRestrictedPersons) {
      result.isNotRestrictedPerson = shouldReuseOfferOrderIntent()
        ? offerOrderIntent?.isNotRestrictedPerson ?? false
        : false;
    }

    return result;
  };

  const getStandardOfferInitialFormValues = () => {
    let result: NewOfferOrderForm = {
      quantity: shouldReuseOfferOrderIntent() ? offerOrderIntent?.quantity?.toString() ?? '' : '',
    };

    if (!offerDetails.allowRestrictedPersons) {
      result.isNotRestrictedPerson = shouldReuseOfferOrderIntent()
        ? offerOrderIntent?.isNotRestrictedPerson ?? false
        : false;
    }

    return result;
  };

  const getInitialFormValues = () => {
    if (offerDetails.isConditional) {
      return getConditionalOfferInitialFormValues();
    }

    return getStandardOfferInitialFormValues();
  };

  const onSubmit = (values: NewOfferOrderForm) => {
    assertNonNullable(account, 'account');
    assertPropertyIsFound(account, 'accountId');
    const totalInvestment = offerDetails.isConditional
      ? Number(values.totalInvestment)
      : findTotalCost(values.quantity);
    dispatch(
      addOfferOrderIntent({
        ...offerOrderIntent,
        account: {
          id: account.id,
          accountId: account.accountId,
          type: account.type,
        },
        isConditional: offerDetails.isConditional,
        isExternal: offerDetails.isExternalOffer,
        hasManySecurityDeliveryOptions: hasManySecurityDeliveryOptions(),
        minSharePrice: offerDetails.minSharePrice,
        maxSharePrice: offerDetails.maxSharePrice,
        offerId: offerDetails.id,
        quantity: Number(values.quantity),
        price: offerDetails.pricePerShare,
        totalInvestment,
        cashAvailable,
        isNotRestrictedPerson: Boolean(values.isNotRestrictedPerson),
        isAccreditedInvestor: values.investorType ? values.investorType === InvestorType.Accredited : undefined,
        areDocumentsReviewed: offerOrderIntent?.areDocumentsReviewed ?? false,
        allowAchTransfers: offerDetails.allowAchTransfers,
        allowWireTransfers: offerDetails.allowWireTransfers,
        allowCheckTransfers: offerDetails.allowCheckTransfers,
        allowCardPayments: offerDetails.allowCardPayments,
        cardPaymentAccountExternalId: offerDetails.cardPaymentAccount?.externalId,
        cardPaymentAccountStatus: offerDetails.cardPaymentAccount?.status,
        wireInstructions: offerDetails.externalDetails?.wireInstructions,
        checkInstructions: offerDetails.externalDetails?.checkInstructions,
      }),
    );

    window.gtag('event', 'offer_order_invest_amount_complete', {
      offer_id: offerDetails.id,
      offer_name: offerDetails.name,
      account_id: account.accountId,
      offer_order_quantity: Number(values.quantity),
      offer_order_value: totalInvestment,
    });

    navigate(`../${DOCUMENTS_PATH}`);
  };

  useEffect(() => {
    if (!shouldReuseOfferOrderIntent()) {
      dispatch(removeOfferOrderIntent());
    }
  }, []);

  return (
    <NewOfferOrderLayout
      step='new'
      offerId={offerDetails.id}
      hasManySecurityDeliveryOptions={hasManySecurityDeliveryOptions()}>
      <Formik<NewOfferOrderForm>
        initialValues={getInitialFormValues()}
        onSubmit={values => {
          onSubmit(values);
        }}
        validationSchema={
          offerDetails.isConditional
            ? getConditionalOfferOrderValidationSchema(offerDetails)
            : getStandardOfferOrderValidationSchema(offerDetails)
        }>
        {form => {
          return (
            <>
              <Col span={24} className={cx(BaseStyle.level, Spacing.mb40)}>
                <img
                  className={Styles.offerLogo}
                  src={offerDetails.logoUrl || Images.DefaultOffering}
                  alt={`${offerDetails.name}_logo`}
                />
                <span className={Styles.offerName}>{offerDetails.name}</span>
              </Col>

              {!offerDetails.isExternalOffer && (
                <Col span={24}>
                  <MFormCurrencyField
                    label='Cash Available'
                    value={cashAvailable}
                    tooltip={{
                      type: 'popover',
                      content: DefinitionConstant.CASH_AVAILABLE,
                      icon: 'question',
                    }}
                    testId={'offer-order-cash-available'}
                  />
                </Col>
              )}
              <Col span={24}>
                <MFormCurrencyField
                  label='Minimum Investment Amount'
                  value={offerDetails.minimumInvestCurrencyAmt}
                  testId={'minimum-investment-amount'}
                />
              </Col>
              <Col span={24}>
                {offerDetails.isConditional ? (
                  <MFormCurrencyRangeField
                    label='Share Range'
                    min={offerDetails.minSharePrice}
                    max={offerDetails.maxSharePrice}
                    testId={'share-range'}
                  />
                ) : (
                  <MFormCurrencyField label='Share Price' value={offerDetails.pricePerShare} testId={'share-price'} />
                )}
              </Col>
              <Col span={24} className={Spacing.mt12}>
                {offerDetails?.isConditional ? (
                  <MFormCurrencyInput
                    label='Total Investment'
                    placeholder='Enter Total Investment'
                    value={form.values.totalInvestment}
                    error={form.errors.totalInvestment}
                    onChange={value => form.setFieldValue('totalInvestment', value)}
                    testId={'total-investment'}
                  />
                ) : (
                  <MFormInput
                    label='Share Quantity'
                    placeholder='Enter Share Quantity'
                    value={form.values.quantity}
                    error={form.errors.quantity}
                    onChange={value => form.setFieldValue('quantity', value)}
                    testId={'share-quantity'}
                  />
                )}
              </Col>

              <Col span={24} className={Styles.totalInvestment} data-testid={'total-investment'}>
                <span>Total Investment</span>
                {offerDetails.isConditional ? (
                  <CurrencyField
                    value={Number(form.values.totalInvestment)}
                    className={Styles.totalInvestmentValue}
                    testId={'total-investment-value'}
                  />
                ) : (
                  <CurrencyField
                    value={offerDetails.pricePerShare * Number(form.values.quantity)}
                    className={Styles.totalInvestmentValue}
                    testId={'total-investment-value'}
                  />
                )}
              </Col>

              {offerDetails.isConditional && (
                <ThisOfferOrderWillDisclaimer
                  min={offerDetails.minSharePrice}
                  max={offerDetails.maxSharePrice}
                  testId={'offer-order-conditional-range-disclaimer'}
                />
              )}

              {shouldDisplayAccreditation() && (
                <Accreditation
                  form={form}
                  isAccreditationValid={(isValid: boolean) => {
                    setIsAccreditationValid(isValid);
                  }}
                />
              )}
              {!offerDetails.allowRestrictedPersons && (
                <Col span={24}>
                  <MCheckbox
                    checked={form.values.isNotRestrictedPerson}
                    onChange={value => form.setFieldValue('isNotRestrictedPerson', value.checked)}
                    error={form.errors.isNotRestrictedPerson}
                    testId={'offer-order-check-restricted-person'}>
                    By checking I am not considered a “restricted person” and am eligible to participate in this
                    offering
                  </MCheckbox>
                  <MTooltip
                    title='What is a restricted person?'
                    label='What is a restricted person?'
                    type='modal'
                    icon='info'>
                    <WhatsRestrictedPersonModalContent />
                  </MTooltip>
                </Col>
              )}
              <Col span={24} className={cx(BaseStyle.level, Spacing.mt24)}>
                <MButton
                  type='tertiary'
                  onClick={() => navigate(-1)}
                  className={Spacing.mx4}
                  testId={'offer-order-btn-back'}>
                  Back
                </MButton>
                <MButton
                  disabled={!_.isEmpty(form.errors) || !isAccreditationValid}
                  onClick={() => form.submitForm()}
                  className={Spacing.mx4}
                  testId={'offer-order-btn-review'}>
                  Review Disclosures
                </MButton>
              </Col>
            </>
          );
        }}
      </Formik>
    </NewOfferOrderLayout>
  );
};
