import { InvestigationCategoryName } from 'src/models';

import * as Styles from './AccountStatus.styles';

interface CipFailureDescriptionProps {
  category: InvestigationCategoryName;
}

export const InvestigationCipFailureDescription = ({ category }: CipFailureDescriptionProps) => {
  if (category === InvestigationCategoryName.NameOrDateOfBirth) {
    return (
      <div>
        <div className={Styles.investigationTitle}>Proof of Name and/or Date of Birth is required</div>
        <div className={Styles.investigationDescription}>
          Additional document(s) to validate Name and/or Date of Birth are required to process your application.
        </div>
      </div>
    );
  }

  if (category === InvestigationCategoryName.TaxId) {
    return (
      <div>
        <div className={Styles.investigationTitle}>Proof of Tax Identification is required</div>
        <div className={Styles.investigationDescription}>
          Additional document(s) to validate your Tax Identification Number are required to process your application.
        </div>
      </div>
    );
  }

  if (category === InvestigationCategoryName.Address) {
    return (
      <div>
        <div className={Styles.investigationTitle}>Proof of Address is required</div>
        <div className={Styles.investigationDescription}>
          Additional document(s) to validate your Address are required to process your application.
        </div>
      </div>
    );
  }

  return null;
};
