import { cx } from '@emotion/css';
import { Account } from 'src/models';
import { Display, JustifyContent, SpaceSize, Spacing } from 'src/styles';

import { MSpinner } from '../MSpinner/MSpinner';

import { AccountHolderFullName } from './AccountHolderFullName';
import * as Styles from './Header.styles';

interface SwitchAccountMenuItemProps {
  onClick: () => void;
  account?: Account;
  isLoading?: boolean;
}

export const SwitchAccountMenuItem = ({ account, isLoading, onClick }: SwitchAccountMenuItemProps) => {
  if (isLoading) {
    return (
      <div style={{ minHeight: SpaceSize.is80 }} className={cx(Display.flex, JustifyContent.center)}>
        <MSpinner />
      </div>
    );
  }

  return (
    <div className={Styles.menuItemWithoutLink} onClick={isLoading ? undefined : onClick}>
      <div className={cx(Display.flex, Spacing.mr16)}>
        <i className='ri-user-line' style={{ fontSize: 24 }} />
      </div>
      <AccountHolderFullName account={account} alignment={'start'} />
    </div>
  );
};
