import { useEffect, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderFinancialInformationEmployment,
  doClearPatchAccountHolderFinancialInformationEmployment,
  doCreateAccountHolderFinancialInformationEmployment,
  doPatchAccountHolderFinancialInformationEmployment,
  toastMessagesAdd,
  toastMessagesRemove,
} from 'src/actions';
import { US_COUNTRY_LABEL, US_COUNTRY_STATE_LIST, US_COUNTRY_VALUE } from 'src/constants';
import { AccountHolderEmploymentStatusDto, AccountHolderYearsEmployedDto } from 'src/dtos';
import { MFormInput, MFormSaveButton, MFormSelect } from 'src/lib';
import { AccountHolder, AccountHolderEmploymentStatusLabel, AccountHolderYearsEmployedLabel } from 'src/models';
import { SeverityEnum } from 'src/typings/commonTypes';
import { assertNonNullable, renderAddressMismatchErrorMessage } from 'src/utils';
import { v4 as uuid } from 'uuid';

import * as Styles from './FinancialInformation.styles';
import { upsertAccountHolderFinancialInformationEmploymentValidation } from './validations';

export const countryOptions = [{ label: US_COUNTRY_LABEL, value: US_COUNTRY_VALUE }];

export const EMPLOYMENT_STATUS_OPTION_LIST = [
  {
    value: AccountHolderEmploymentStatusDto.Employed,
    label: AccountHolderEmploymentStatusLabel.Employed,
  },
  {
    value: AccountHolderEmploymentStatusDto.Retired,
    label: AccountHolderEmploymentStatusLabel.Retired,
  },
  {
    value: AccountHolderEmploymentStatusDto.Unemployed,
    label: AccountHolderEmploymentStatusLabel.Unemployed,
  },
  {
    value: AccountHolderEmploymentStatusDto.Student,
    label: AccountHolderEmploymentStatusLabel.Student,
  },
];

export const YEARS_EMPLOYED_OPTION_LIST = [
  {
    value: AccountHolderYearsEmployedDto.BetweenZeroAndOne,
    label: AccountHolderYearsEmployedLabel.BetweenZeroAndOne,
  },
  {
    value: AccountHolderYearsEmployedDto.BetweenTwoAndFive,
    label: AccountHolderYearsEmployedLabel.BetweenTwoAndFive,
  },
  {
    value: AccountHolderYearsEmployedDto.BetweenFiveAndTen,
    label: AccountHolderYearsEmployedLabel.BetweenFiveAndTen,
  },
  {
    value: AccountHolderYearsEmployedDto.BetweenTenAndTwenty,
    label: AccountHolderYearsEmployedLabel.BetweenTenAndTwenty,
  },
  {
    value: AccountHolderYearsEmployedDto.MoreThanTwenty,
    label: AccountHolderYearsEmployedLabel.MoreThanTwenty,
  },
];

export interface FinancialInformationEmploymentFormValues {
  employmentStatus?: AccountHolderEmploymentStatusDto;
  employerName?: string;
  jobTitle?: string;
  yearsEmployed?: AccountHolderYearsEmployedDto;
  address1?: string;
  address2?: string;
  country?: string;
  state?: string;
  city?: string;
  postalCode?: string;
}

export interface FinancialInformationEmploymentFormProps {
  accountUuid?: string;
  accountHolder?: AccountHolder;
  onCancel?: () => void;
  onSave?: () => void;
}

export const FinancialInformationEmploymentForm = ({
  accountHolder,
  accountUuid,
  onCancel,
  onSave,
}: FinancialInformationEmploymentFormProps) => {
  const dispatch = useDispatch();

  const isCreateFinancialInformationEmploymentLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createFinancialInformationEmployment.__requested),
  );
  const succeededCreateFinancialInformationEmployment = useSelector((state: any) =>
    Boolean(state.accountHolders.createFinancialInformationEmployment.__succeeded),
  );
  const failedCreateFinancialInformationEmployment = useSelector(
    (state: any) => state.accountHolders.createFinancialInformationEmployment.__failed,
  );
  const failedCreateFinancialInformationEmploymentMessage = useSelector(
    (state: any) => state.accountHolders.createFinancialInformationEmployment.message,
  );

  const isPatchFinancialInformationEmploymentLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchFinancialInformationEmployment.__requested),
  );
  const succeededPatchFinancialInformationEmployment = useSelector((state: any) =>
    Boolean(state.accountHolders.patchFinancialInformationEmployment.__succeeded),
  );
  const failedPatchFinancialInformationEmployment = useSelector(
    (state: any) => state.accountHolders.patchFinancialInformationEmployment.__failed,
  );
  const failedPatchFinancialInformationEmploymentMessage = useSelector(
    (state: any) => state.accountHolders.patchFinancialInformationEmployment.message,
  );

  const createRequestData = useSelector(
    (state: any) => state.accountHolders.createFinancialInformationEmployment?.data,
  );
  const patchRequestData = useSelector((state: any) => state.accountHolders.patchFinancialInformationEmployment?.data);

  const [initialValues, setInitialValues] = useState<FinancialInformationEmploymentFormValues>({});
  const [shouldPatch, setShouldPatch] = useState<boolean>(false);
  const [failedUpsertFinancialInformationEmploymentToastId, setFailedUpsertFinancialInformationEmploymentToastId] =
    useState<string | null>(null);

  const isFinancialInformationEmploymentAlreadySaved = () =>
    !isUndefined(accountHolder?.financialInformation?.employmentStatus);

  const isRequestMatched = () =>
    accountHolder?.id === createRequestData?.params?.accountHolderId ||
    accountHolder?.id === patchRequestData?.params?.accountHolderId;

  const isUpsertLoading =
    (isCreateFinancialInformationEmploymentLoading || isPatchFinancialInformationEmploymentLoading) &&
    isRequestMatched();

  const removeUpsertFinancialInformationEmploymentToastMessageIfFound = () => {
    if (failedUpsertFinancialInformationEmploymentToastId) {
      dispatch(toastMessagesRemove({ key: failedUpsertFinancialInformationEmploymentToastId }));
    }
  };

  const isEmployedStatusSelected = (value?: string) => value === AccountHolderEmploymentStatusDto.Employed;

  const _onCancel = (form: FormikProps<FinancialInformationEmploymentFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<FinancialInformationEmploymentFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    setInitialValues({
      employmentStatus: accountHolder?.financialInformation?.employmentStatus?.value,
      jobTitle: accountHolder?.financialInformation?.jobTitle,
      yearsEmployed: accountHolder?.financialInformation?.yearsEmployed?.value,
      employerName: accountHolder?.financialInformation?.employerName,
      address1: accountHolder?.financialInformation?.employerAddress?.address1,
      address2: accountHolder?.financialInformation?.employerAddress?.address2,
      country: accountHolder?.financialInformation?.employerAddress?.country?.value ?? US_COUNTRY_VALUE,
      state: accountHolder?.financialInformation?.employerAddress?.state?.value,
      city: accountHolder?.financialInformation?.employerAddress?.city,
      postalCode: accountHolder?.financialInformation?.employerAddress?.postalCode,
    });
  }, [accountHolder]);

  useEffect(() => {
    if (isFinancialInformationEmploymentAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [accountHolder]);

  useEffect(() => {
    if (
      (succeededCreateFinancialInformationEmployment || succeededPatchFinancialInformationEmployment) &&
      isRequestMatched()
    ) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreateFinancialInformationEmployment, succeededPatchFinancialInformationEmployment]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderFinancialInformationEmployment());
      dispatch(doClearPatchAccountHolderFinancialInformationEmployment());
    };
  }, []);

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnBlur
      onSubmit={values => {
        assertNonNullable(accountHolder, 'AccountHolder');
        assertNonNullable(accountUuid, 'accountUuid');

        const dto =
          values.employmentStatus === AccountHolderEmploymentStatusDto.Employed
            ? {
                employmentStatus: values.employmentStatus,
                employerName: values.employerName,
                jobTitle: values.jobTitle,
                yearsEmployed: values.yearsEmployed,
                employerAddress: {
                  address1: values.address1,
                  address2: values.address2,
                  city: values.city,
                  country: values.country,
                  state: values.state,
                  postalCode: values.postalCode,
                },
              }
            : {
                employmentStatus: values.employmentStatus,
              };

        if (shouldPatch) {
          dispatch(
            doPatchAccountHolderFinancialInformationEmployment({
              params: {
                id: accountUuid,
                accountHolderId: accountHolder.id,
              },
              body: dto as any, // TODO: fix the type casting
            }),
          );

          return;
        }

        dispatch(
          doCreateAccountHolderFinancialInformationEmployment({
            params: {
              id: accountUuid,
              accountHolderId: accountHolder.id,
            },
            body: dto as any, // TODO: fix the type casting
          }),
        );
      }}
      initialValues={initialValues}
      validationSchema={upsertAccountHolderFinancialInformationEmploymentValidation}>
      {form => {
        const onPopulateFinancialInformationEmployerAddress = () => {
          if (failedCreateFinancialInformationEmploymentMessage?.error === 'UspsAddressMismatchError') {
            form.setFieldValue('address1', failedCreateFinancialInformationEmploymentMessage.address1);
            form.setFieldValue('address2', failedCreateFinancialInformationEmploymentMessage.address2);
            form.setFieldValue('city', failedCreateFinancialInformationEmploymentMessage.city);
            form.setFieldValue('state', failedCreateFinancialInformationEmploymentMessage.state);
            form.setFieldValue('postalCode', failedCreateFinancialInformationEmploymentMessage.postalCode);
          }

          if (form && failedPatchFinancialInformationEmploymentMessage?.error === 'UspsAddressMismatchError') {
            form.setFieldValue('address1', failedPatchFinancialInformationEmploymentMessage.address1);
            form.setFieldValue('address2', failedPatchFinancialInformationEmploymentMessage.address2);
            form.setFieldValue('city', failedPatchFinancialInformationEmploymentMessage.city);
            form.setFieldValue('state', failedPatchFinancialInformationEmploymentMessage.state);
            form.setFieldValue('postalCode', failedPatchFinancialInformationEmploymentMessage.postalCode);
          }
          removeUpsertFinancialInformationEmploymentToastMessageIfFound();
        };

        useEffect(() => {
          if (
            failedCreateFinancialInformationEmploymentMessage?.error === 'UspsAddressMismatchError' &&
            isRequestMatched()
          ) {
            removeUpsertFinancialInformationEmploymentToastMessageIfFound();
            const toastId = uuid();
            dispatch(
              toastMessagesAdd({
                key: toastId,
                severity: SeverityEnum.Error,
                isClearable: true,
                autoClose: false,
                message: renderAddressMismatchErrorMessage({
                  dto: failedCreateFinancialInformationEmploymentMessage,
                  onClick: onPopulateFinancialInformationEmployerAddress,
                  section: 'employment',
                }),
              }),
            );
            setFailedUpsertFinancialInformationEmploymentToastId(toastId);
          }
        }, [failedCreateFinancialInformationEmployment]);

        useEffect(() => {
          if (
            failedPatchFinancialInformationEmploymentMessage?.error === 'UspsAddressMismatchError' &&
            isRequestMatched()
          ) {
            removeUpsertFinancialInformationEmploymentToastMessageIfFound();
            const toastId = uuid();
            dispatch(
              toastMessagesAdd({
                key: toastId,
                severity: SeverityEnum.Error,
                isClearable: true,
                autoClose: false,
                message: renderAddressMismatchErrorMessage({
                  dto: failedPatchFinancialInformationEmploymentMessage,
                  onClick: onPopulateFinancialInformationEmployerAddress,
                  section: 'employment',
                }),
              }),
            );
            setFailedUpsertFinancialInformationEmploymentToastId(toastId);
          }
        }, [failedPatchFinancialInformationEmployment]);

        return (
          <>
            <Col span={24}>
              <MFormSelect
                testId={'account-employment-status'}
                label='Employment Status'
                placeholder='Select'
                defaultValue={accountHolder?.financialInformation?.employmentStatus?.value}
                options={EMPLOYMENT_STATUS_OPTION_LIST}
                error={form.errors.employmentStatus}
                onChange={value => {
                  form.setFieldValue('employmentStatus', value);
                }}
              />
            </Col>

            {isEmployedStatusSelected(form.values.employmentStatus) && (
              <>
                <Col span={24}>
                  <MFormInput
                    testId={'account-employer-name'}
                    label='Employer Name'
                    placeholder='Enter'
                    defaultValue={accountHolder?.financialInformation?.employerName}
                    error={form.errors.employerName}
                    onChange={value => {
                      form.setFieldValue('employerName', value);
                    }}
                  />
                </Col>

                <Col span={24}>
                  <MFormInput
                    testId={'account-job-title'}
                    label='Job Title'
                    placeholder='Enter'
                    defaultValue={accountHolder?.financialInformation?.jobTitle}
                    error={form.errors.jobTitle}
                    onChange={value => {
                      form.setFieldValue('jobTitle', value);
                    }}
                  />
                </Col>

                <Col span={24}>
                  <MFormSelect
                    testId={'account-years-employed'}
                    label='Years Employed'
                    placeholder='Select'
                    defaultValue={accountHolder?.financialInformation?.yearsEmployed?.value}
                    options={YEARS_EMPLOYED_OPTION_LIST}
                    error={form.errors.yearsEmployed}
                    onChange={value => {
                      form.setFieldValue('yearsEmployed', value);
                    }}
                  />
                </Col>

                <Col span={24} className={Styles.title}>
                  Employment Address
                </Col>

                <Col span={24}>
                  <MFormInput
                    testId={'account-mailing-address1'}
                    label='Address Line 1'
                    placeholder='Enter'
                    value={form.values.address1}
                    defaultValue={accountHolder?.financialInformation?.employerAddress?.address1}
                    error={form.errors?.address1}
                    onChange={value => {
                      form.setFieldValue('address1', value);
                    }}
                  />
                </Col>

                <Col span={24}>
                  <MFormInput
                    testId={'account-mailing-address2'}
                    label='Address Line 2 (Opt.)'
                    placeholder='Enter'
                    value={form.values.address2}
                    defaultValue={accountHolder?.financialInformation?.employerAddress?.address2}
                    error={form.errors.address2}
                    onChange={value => {
                      form.setFieldValue('address2', value);
                    }}
                  />
                </Col>

                <Col span={24}>
                  <MFormSelect
                    testId={'account-mailing-country'}
                    label='Country'
                    placeholder='Select'
                    defaultValue={
                      accountHolder?.financialInformation?.employerAddress?.country?.value ?? US_COUNTRY_VALUE
                    }
                    options={countryOptions}
                    error={form.errors?.country}
                    onChange={value => {
                      form.setFieldValue('country', value);
                    }}
                  />
                </Col>

                <Col span={24}>
                  <MFormInput
                    testId={'account-mailing-city'}
                    label='City'
                    placeholder='Enter'
                    value={form.values.city}
                    defaultValue={accountHolder?.financialInformation?.employerAddress?.city}
                    error={form.errors?.city}
                    onChange={value => {
                      form.setFieldValue('city', value);
                    }}
                  />
                </Col>

                <Col span={24}>
                  <MFormSelect
                    testId={'account-mailing-state'}
                    label='State'
                    placeholder='Select'
                    defaultValue={accountHolder?.financialInformation?.employerAddress?.state?.value}
                    value={form.values.state}
                    options={US_COUNTRY_STATE_LIST}
                    error={form.errors?.state}
                    onChange={value => {
                      form.setFieldValue('state', value);
                    }}
                  />
                </Col>

                <Col span={24}>
                  <MFormInput
                    testId={'account-mailing-postal-code'}
                    label='Postal Code'
                    placeholder='Enter'
                    value={form.values.postalCode}
                    defaultValue={accountHolder?.financialInformation?.employerAddress?.postalCode}
                    error={form.errors?.postalCode}
                    onChange={value => {
                      form.setFieldValue('postalCode', value);
                    }}
                  />
                </Col>
              </>
            )}

            <Col span={24}>
              <MFormSaveButton<FinancialInformationEmploymentFormValues>
                loading={isUpsertLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode={shouldPatch}
                form={form}
                testId={'employment-information'}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
