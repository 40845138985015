import { Col } from 'antd';
import { AccountHolderEmploymentStatusDto } from 'src/dtos';
import { MFormTextField } from 'src/lib';
import { AccountHolder } from 'src/models';

import * as Styles from './FinancialInformation.styles';

export interface FinancialInformationEmploymentViewProps {
  accountHolder?: AccountHolder;
}

export const FinancialInformationEmploymentView = ({ accountHolder }: FinancialInformationEmploymentViewProps) => {
  const isEmployed = () =>
    accountHolder?.financialInformation?.employmentStatus?.value === AccountHolderEmploymentStatusDto.Employed;

  return (
    <>
      <MFormTextField label='Employment Status' value={accountHolder?.financialInformation?.employmentStatus?.label} />
      {isEmployed() && (
        <>
          <MFormTextField label='Employer Name' value={accountHolder?.financialInformation?.employerName} />
          <MFormTextField label='Job Title' value={accountHolder?.financialInformation?.jobTitle} />
          <MFormTextField label='Years Employed' value={accountHolder?.financialInformation?.yearsEmployed?.label} />
          <Col span={24} className={Styles.title}>
            Employment Address
          </Col>
          <MFormTextField
            label='Address Line 1'
            value={accountHolder?.financialInformation?.employerAddress?.address1}
          />
          <MFormTextField
            label='Address Line 2 (Opt.)'
            value={accountHolder?.financialInformation?.employerAddress?.address2}
          />
          <MFormTextField
            label='Country'
            value={accountHolder?.financialInformation?.employerAddress?.country?.label}
          />
          <MFormTextField label='City' value={accountHolder?.financialInformation?.employerAddress?.city} />
          <MFormTextField label='State' value={accountHolder?.financialInformation?.employerAddress?.state?.label} />
          <MFormTextField
            label='Postal Code'
            value={accountHolder?.financialInformation?.employerAddress?.postalCode}
          />
        </>
      )}
    </>
  );
};
