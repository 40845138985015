import 'antd/dist/antd.min.css';
import { useEffect } from 'react';

import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { getAuthenticatedUser } from 'src/actions';
import { useAuthenticatedUserSelector, useRefreshQuoteMedia, useTradingBlockWebSocket } from 'src/hooks';
import { ScrollToTop } from 'src/lib';

import {
  CanActivateSummaryGuard,
  CompletedAccountGuard,
  OfferOrderIntentGuard,
  OnboardedUserGuard,
  NewOfferOrderGuard,
} from './lib/Guards';
import { OFFER_ORDERS_PATH } from './lib/Guards/NewOfferOrderGuard';
import { GlobalLayout } from './lib/Layout/GlobalLayout/GlobalLayout';
import { HeaderLayout } from './lib/Layout/HeaderLayout/HeaderLayout';
import InformationPanel from './lib/Layout/InformationPanel/InformationPanel';
import Spinner from './lib/Miscellaneous/Spinner';
import About from './pages/About/About';
import { AccountStatements } from './pages/AccountStatements/AccountStatements';
import { BankAccountsAndFunding } from './pages/Cashiering/BankAccountsAndFunding';
import { CloseAccount } from './pages/CloseAcount/CloseAccount';
import { UnauthenticatedEmailConfirm, AuthenticatedEmailConfirm } from './pages/EmailConfirm';
import FAQ from './pages/FAQ/FAQ';
import Home from './pages/Home';
import { IndicationsOfInterest } from './pages/IndicationsOfInterest/IndicationsOfInterest';
import { AccountStatus } from './pages/Investigations/AccountStatus/AccountStatus';
import { MAIN_PATH } from './pages/Main';
import { CompleteAccount } from './pages/Main/pages/CompleteAccount/CompleteAccount';
import History, { HISTORY_PATH } from './pages/Main/pages/History/History';
import {
  NewOfferOrder,
  NewOfferOrderDocumentsStep,
  NewOfferOrderSecurityDeliveryInformationStep,
  NewOfferOrderConfirmStep,
  NewOfferOrderFailed,
  NewOfferOrderSucceeded,
  ORDER_PATH,
  DETAILS_PATH,
  DOCUMENTS_PATH,
  SECURITY_DELIVERY_INFORMATION,
} from './pages/Main/pages/OfferOrders';
import Summary, { SUMMARY_PATH } from './pages/Main/pages/Summary/Summary';
import { ArbitrationClauses } from './pages/Miscellaneous/ArbitrationClouses/ArbitrationClauses';
import { CustomerAgreementReview } from './pages/Miscellaneous/CustomerAgreementReview/CustomerAgreementReview';
import OtherFactors from './pages/Miscellaneous/OtherFactors/OtherFactors';
import TermsOfUse from './pages/Miscellaneous/TermsOfUse/TermsOfUse';
import { OfferCardPaymentAccountLinkExpire } from './pages/OfferCardPaymentAccountLinkExpire/OfferCardPaymentAccountLinkExpire';
import { OfferDetailsPage } from './pages/Offers/OfferDetailsPage/OfferDetailsPage';
import { OfferListPage } from './pages/Offers/OfferListPage/OfferListPage';
import { Address } from './pages/Onboarding/Address/Address';
import { ForgotPassword } from './pages/Onboarding/ForgotPassword/ForgotPassword';
import { Login } from './pages/Onboarding/Login/Login';
import { LoginMfa } from './pages/Onboarding/LoginMFA/LoginMfa';
import { Register } from './pages/Onboarding/Register/Register';
import { ResetPassword } from './pages/Onboarding/ResetPassword/ResetPassword';
import { Security } from './pages/Onboarding/Security/Security';
import { SecurityConfirmation } from './pages/Onboarding/SecurityConfirmation/SecurityConfirmation';
import { AccountAgreements } from './pages/PortfolioCompletion/AccountAgreements/AccountAgreements';
import { Disclosures } from './pages/PortfolioCompletion/Disclosures/Disclosures';
import { FinancialInformation } from './pages/PortfolioCompletion/FinancialInformation/FinancialInformation';
import { PersonalInformation } from './pages/PortfolioCompletion/PersonalInformation/PersonalInformation';
import { SignApplication } from './pages/PortfolioCompletion/SignApplication/SignApplication';
import { SuitabilityInformation } from './pages/PortfolioCompletion/SuitabilityInformation/SuitabilityInformation';
import { TrustedContact } from './pages/PortfolioCompletion/TrustedContact/TrustedContact';
import { UserSettings } from './pages/UserSettings/UserSettings';

const AuthenticatedApp = () => {
  const { user } = useAuthenticatedUserSelector();

  useRefreshQuoteMedia();
  useTradingBlockWebSocket();

  useEffect(() => {
    if (!isEmpty(user)) {
      window.gtag('set', {
        user_properties: {
          user_id: user.id,
          user_email: user.email,
          user_first_name: user.firstName,
          user_last_name: user.lastName,
        },
      });
    }
  }, [user]);

  // TODO: create 404 page if account is not found or there is an error while getting it.

  return (
    <Routes>
      <Route path='/address' element={<Address />} />
      <Route path='/security' element={<Security />} />
      <Route path='/security-confirmation' element={<SecurityConfirmation />} />
      <Route element={<OnboardedUserGuard />}>
        <Route element={<HeaderLayout allowWideScreen />}>
          <Route path='/about' element={<About />} />
          <Route element={<InformationPanel />}>
            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='/other-factors' element={<OtherFactors />} />
          </Route>
        </Route>
        <Route element={<HeaderLayout />}>
          <Route path='/faq' element={<FAQ />} />
          <Route path='/complete-account' element={<CompleteAccount />} />
          <Route path='/arbitration-clauses' element={<ArbitrationClauses />} />
          <Route path='/customer-agreement-review' element={<CustomerAgreementReview />} />
          <Route path='/offers' element={<OfferListPage />} />
          <Route path='/offers/:offerId' element={<OfferDetailsPage />} />
          <Route path='/:offerId/invest' element={<OfferDetailsPage />} />
          <Route path='/email-confirm' element={<AuthenticatedEmailConfirm />} />
          <Route path='/accounts/personal-information' element={<PersonalInformation />} />
          <Route path='/accounts/trusted-contact' element={<TrustedContact />} />
          <Route path='/accounts/suitability-information' element={<SuitabilityInformation />} />
          <Route path='/accounts/financial-information' element={<FinancialInformation />} />
          <Route path='/accounts/disclosures' element={<Disclosures />} />
          <Route path='/accounts/account-agreements' element={<AccountAgreements />} />
          <Route path='/accounts/sign-application' element={<SignApplication />} />
          <Route path='/user/indications-of-interest' element={<IndicationsOfInterest />} />
          <Route element={<CompletedAccountGuard />}>
            <Route path='/accounts/:id/money-transfers' element={<BankAccountsAndFunding />} />
            <Route path='/accounts/:id/statements' element={<AccountStatements />} />
            <Route path='/user/settings' element={<UserSettings />} />
            <Route path='/accounts/:id/account-status' element={<AccountStatus />} />
            <Route path={`${MAIN_PATH}`}>
              <Route path={`${OFFER_ORDERS_PATH}/:offerId/orders`} element={<NewOfferOrderGuard />}>
                <Route path={`${ORDER_PATH}`} element={<NewOfferOrder />} />
                <Route element={<OfferOrderIntentGuard />}>
                  <Route path={`${DOCUMENTS_PATH}`} element={<NewOfferOrderDocumentsStep />} />
                  <Route
                    path={`${SECURITY_DELIVERY_INFORMATION}`}
                    element={<NewOfferOrderSecurityDeliveryInformationStep />}
                  />
                  <Route path={`${DETAILS_PATH}`} element={<NewOfferOrderConfirmStep />} />
                  <Route path={`failed`} element={<NewOfferOrderFailed />} />
                </Route>
                <Route path={`:offerOrderId`} element={<NewOfferOrderSucceeded />} />
                <Route path='' element={<Navigate to={`./${ORDER_PATH}`} replace />} />
              </Route>
              <Route path={`${HISTORY_PATH}`} element={<History />} />
              <Route element={<CanActivateSummaryGuard />}>
                <Route path={`${SUMMARY_PATH}`} element={<Summary />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path='' element={<Navigate to={`/${MAIN_PATH}/${SUMMARY_PATH}`} replace />} />
        <Route path='*' element={<Navigate to={`/${MAIN_PATH}/${SUMMARY_PATH}`} replace />} />
      </Route>
    </Routes>
  );
};

const UnauthenticatedApp = () => {
  useEffect(() => {
    window.gtag('set', {
      user_properties: {
        user_id: null,
        user_email: null,
        user_first_name: null,
        user_last_name: null,
      },
    });
  }, []);

  return (
    <Routes>
      <Route element={<HeaderLayout allowWideScreen />}>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route element={<InformationPanel />}>
          <Route path='/terms-of-use' element={<TermsOfUse />} />
          <Route path='/other-factors' element={<OtherFactors />} />
        </Route>
      </Route>
      <Route element={<HeaderLayout />}>
        <Route path='/faq' element={<FAQ />} />
        <Route path='/offers' element={<OfferListPage />} />
        <Route path='/offers/:offerId' element={<OfferDetailsPage />} />
      </Route>
      <Route path='/close-account' element={<CloseAccount />} />
      <Route path='/offer-card-payment-account-link-expire' element={<OfferCardPaymentAccountLinkExpire />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/email-confirm' element={<UnauthenticatedEmailConfirm />} />
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/:offerId/invest' element={<Register />} />
      <Route path='/login-mfa' element={<LoginMfa />} />
      <Route path='*' element={<Navigate to={`/login`} replace />} />
      <Route path='' element={<Navigate to={`/login`} replace />} />
    </Routes>
  );
};

const AppLayout = () => {
  const dispatch = useDispatch();
  const authToken = useSelector((state: any) => Boolean(state.auth?.data?.authToken));
  const { user, isUserLoading, isUserFetched, hasUserError } = useAuthenticatedUserSelector();

  useEffect(() => {
    if (authToken) {
      dispatch(getAuthenticatedUser());
    }
  }, [dispatch, authToken]);

  if (isUserLoading) {
    return <Spinner />;
  }

  if (authToken && !isUserFetched) {
    return <Spinner />;
  }

  if (authToken && !user) {
    return <Spinner />;
  }

  if (hasUserError) {
    return <UnauthenticatedApp />;
  }

  return authToken ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <GlobalLayout>
        <AppLayout />
      </GlobalLayout>
    </BrowserRouter>
  );
};

export default App;
