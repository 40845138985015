import { Account } from 'src/models';

export enum HeaderMenuItemKey {
  MyAccount = 'MyAccount',
  MoneyTransfers = 'MoneyTransfers',
  AccountStatus = 'AccountStatus',
  AccountStatements = 'AccountStatements',
  ChangeEmail = 'ChangeEmail',
  IndicationsOfInterest = 'IndicationsOfInterst',
}

export interface HeaderMenuItem {
  key: HeaderMenuItemKey;
  name: string;
  path: (id?: string) => string;
}

export const accountSettingMenuItemList: HeaderMenuItem[] = [
  {
    key: HeaderMenuItemKey.MoneyTransfers,
    name: 'Money Transfers & Preferences',
    path: (id?: string) => `/accounts/${id}/money-transfers`,
  },
  {
    key: HeaderMenuItemKey.AccountStatus,
    name: 'Account Status',
    path: (id?: string) => `/accounts/${id}/account-status`,
  },
  {
    key: HeaderMenuItemKey.AccountStatements,
    name: 'Statements & Documents',
    path: (id?: string) => `/accounts/${id}/statements`,
  },
];

export const getAccountSettingMenuItemList = (account?: Account): HeaderMenuItem[] => {
  if (!account?.accountId) {
    return [];
  }

  return accountSettingMenuItemList;
};

export const userSettingMenuItemList: HeaderMenuItem[] = [
  {
    key: HeaderMenuItemKey.IndicationsOfInterest,
    name: 'Indications of Interest',
    path: () => `/user/indications-of-interest`,
  },
  { key: HeaderMenuItemKey.ChangeEmail, name: 'Change Email Address & Phone Number', path: () => '/user/settings' },
];

export const getUserSettingMenuItemList = (account?: Account): HeaderMenuItem[] => {
  if (!account?.accountId) {
    return [
      {
        key: HeaderMenuItemKey.IndicationsOfInterest,
        name: 'Indications of Interest',
        path: () => `/user/indications-of-interest`,
      },
    ];
  }

  return userSettingMenuItemList;
};
