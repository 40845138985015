import { MFormItem } from '../MFormItem/MFormItem';
import { MSegmentedProps, MSegmented } from '../MSegmented/MSegmented';

export interface MFormSegmentedProps extends MSegmentedProps {
  label: string;
  error?: string;
  className?: string;
  testId?: string;
}

export const MFormSegmented = ({
  label,
  value,
  defaultValue,
  options,
  onChange,
  error,
  disabled,
  className,
  testId,
}: MFormSegmentedProps) => {
  return (
    <MFormItem label={label} align='vertical' className={className} testId={testId}>
      <MSegmented
        value={value}
        defaultValue={defaultValue}
        options={options}
        onChange={onChange}
        disabled={disabled}
        error={error}
        testId={testId}
      />
    </MFormItem>
  );
};
