import { useSelector } from 'react-redux';
import { Account } from 'src/models';
import { Maybe } from 'src/utils';

interface UseAccountSelectorReturnType {
  account?: Account;
  isAccountLoading: boolean;
  hasAccountError: boolean;
  accountError?: string;
  hasAccountSucceeded: boolean;
}

export const useAccountSelector = (): UseAccountSelectorReturnType => {
  const account: Maybe<Account> = useSelector((state: any) => state.accounts.item?.data);
  const isAccountLoading = useSelector((state: any) => Boolean(state.accounts.item?.__requested));
  const hasAccountError = useSelector((state: any) => Boolean(state.accounts.item?.__failed));
  const accountError = useSelector((state: any) => state.accounts?.item?.__message);
  const hasAccountSucceeded = useSelector((state: any) => Boolean(state.accounts.item?.__succeeded));

  return {
    account,
    isAccountLoading,
    hasAccountError,
    accountError,
    hasAccountSucceeded,
  };
};
