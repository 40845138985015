import { Col, Row } from 'antd';
import { PhoneNumberDto } from 'src/dtos';

interface PhoneNumbersFieldProps {
  value: PhoneNumberDto[];
}

export const PhoneNumbersField = ({ value }: PhoneNumbersFieldProps) => {
  return (
    <Row>
      {value.map(item => {
        return (
          <Col span={24} key={item.type}>
            {`${item.phoneNumber} (${item.type})`}
          </Col>
        );
      })}
    </Row>
  );
};
