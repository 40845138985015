import { FormikProps } from 'formik';
import { NavLink } from 'react-router-dom';
import { MFormCheckbox } from 'src/lib';
import { ColorConstant } from 'src/styles';

import { SignAccountFormValues } from './SignApplication';
import * as Styles from './SignApplication.styles';

interface AccountAgreementContentProps {
  form: FormikProps<SignAccountFormValues>;
}

export const AccountAgreementContent = ({ form }: AccountAgreementContentProps) => {
  return (
    <div>
      <div>
        <h4 className={Styles.boldLabel}>By signing below and pressing “Create Account”, I acknowledge that:</h4>
        <ol className={Styles.normalLabel} style={{ marginLeft: -15 }}>
          <li>
            I am opening a brokerage account with AOS, Inc., which does business as TradingBlock and My IPO, and clears
            its transactions at APEX Clearing Corporation (&quot;Apex&quot;) who will act as custodian.
          </li>
          <li>I am the Primary Account Holder listed below.</li>
          <li>
            I am a U.S. Person, and the tax identification number provided is my true taxpayer identification number.
          </li>
          <li>
            I am not subject to backup withholding as a result of my failure to report all interest and dividends, or
            the Internal Revenue Service (IRS) has notified me that I am no longer subject to backup withholding.
          </li>
          <MFormCheckbox
            testId={'account-backup-without-holding'}
            label='Check here ONLY if you are subject to backup withholding.'
            defaultChecked={false}
            checked={form.values.isBackupWithholding}
            onChange={e => form.setFieldValue('isBackupWithholding', e.checked)}
          />
          <li>
            I am a U.S. Person, and the tax identification number provided is my true taxpayer identification number. I
            agree that My IPO and Apex do not recommend any securities or investments, do not question, investigate,
            analyze, monitor, or otherwise evaluate any of the investment decisions of any investor or the decisions or
            directions that may be provided by any individual or entity with authority over this account, nor do they
            determine, disclose or research risk factors that may be applicable to investing in securities.
          </li>
          <li>
            I have received, read, and agree to the terms and conditions set forth in{' '}
            <NavLink to='/customer-agreement-review' style={{ color: ColorConstant.BRAND6, cursor: 'pointer' }}>
              the customer agreement and disclosures.
            </NavLink>
          </li>
          <li>
            AOS, Inc. dba My IPO and Apex will share your personal and financial information between themselves as
            needed to service your account and consistent with each firm’s privacy policy.
          </li>
        </ol>
      </div>
      <div className={Styles.normalLabel}>
        By signing below you agree to a pre-dispute
        <NavLink to='/arbitration-clauses' style={{ color: ColorConstant.BRAND6 }}>
          {' '}
          arbitration clause{' '}
        </NavLink>
        located in the AOS, Inc. and Apex customer agreements.
        <br />
        The Internal Revenue Service does not require consent to any provision of this document other than the
        certifications required to avoid backup withholding.
      </div>
    </div>
  );
};
