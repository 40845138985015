import { useSelector } from 'react-redux';
import { User } from 'src/models';
import { Maybe } from 'src/utils';

interface UseAuthenticatedUserSelectorReturnType {
  user?: User;
  isUserLoading: boolean;
  hasUserError: boolean;
  hasUserSucceeded?: boolean;
  userError?: string;
  isUserFetched: boolean;
}

export const useAuthenticatedUserSelector = (): UseAuthenticatedUserSelectorReturnType => {
  const user: Maybe<User> = useSelector((state: any) => state.user.authenticated.data);
  const isUserLoading: boolean = useSelector((state: any) => Boolean(state.user.authenticated.isLoading));
  const hasUserError = useSelector((state: any) => Boolean(state.user.authenticated?.__failed));
  const userError = useSelector((state: any) => state.user.authenticated?.__message);
  const isUserFetched: boolean = useSelector((state: any) => state.user.authenticated.__isFetched);
  const isUserSuccess = useSelector((state: any) => Boolean(state.user.authenticated.__succeeded));

  return {
    user,
    isUserLoading,
    hasUserError,
    userError,
    isUserFetched,
    hasUserSucceeded: isUserSuccess,
  };
};
