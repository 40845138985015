import { PropsWithChildren } from 'react';

import { Button } from 'antd';

import * as Styles from './MLinkButton.styles';

export interface MLinkButtonProps {
  onClick: () => void;
  className?: string;
  underlined?: boolean;
  disabled?: boolean;
}

export const MLinkButton = ({
  onClick,
  className,
  disabled,
  children,
  underlined,
}: PropsWithChildren<MLinkButtonProps>) => {
  return (
    <Button
      type='link'
      onClick={onClick}
      className={`${Styles.button(underlined)} ${className ?? ''}`}
      disabled={disabled}>
      {children}
    </Button>
  );
};
