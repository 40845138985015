import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useAccountSelector } from 'src/hooks';
import { ScreenBreakpoint } from 'src/styles';
import { isAccountSuitabilityInformationComplete } from 'src/utils';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { SuitabilityInformationSection } from './SuitabilityInformationSection';

export const SuitabilityInformation = () => {
  const navigate = useNavigate();

  const isCreateSuccess = useSelector((state: any) =>
    Boolean(state.accountHolders.createSuitabilityInformation.__succeeded),
  );
  const isPatchSuccess = useSelector((state: any) =>
    Boolean(state.accountHolders.patchSuitabilityInformation.__succeeded),
  );

  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const hasAnyUpsertRequest = () => isCreateSuccess || isPatchSuccess;

  const shouldSendAnalyticsEvent = () =>
    !account?.accountId && isCreateSuccess && isAccountSuitabilityInformationComplete(account);

  const shouldRedirectToFinancialInformation = () =>
    !account?.accountId && hasAnyUpsertRequest() && isAccountSuitabilityInformationComplete(account);

  useEffect(() => {
    if (shouldSendAnalyticsEvent()) {
      window.gtag('event', 'account_suitability_information_complete');
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (shouldRedirectToFinancialInformation()) {
      navigate(`/accounts/financial-information`);
    }
  }, [isCreateSuccess, isPatchSuccess]);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.SuitabilityInformation}
      title={isMobile ? undefined : 'Suitability Information'}>
      <SuitabilityInformationSection />
    </MyAccountLayout>
  );
};
