import { useEffect } from 'react';

import { Col } from 'antd';
import { Formik } from 'formik';
import getCanvas from 'html2canvas';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { doClearSubmitAccount, doSubmitAccount } from 'src/actions';
import { useAccountSelector } from 'src/hooks';
import { MAlert, MButton, MFormSection } from 'src/lib';
import { ScreenBreakpoint } from 'src/styles';
import { assertNonNullable, assertPropertyIsFound, isAccountComplete, Maybe } from 'src/utils';
import * as Yup from 'yup';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { AccountAgreementContent } from './AccountAgreementContent';
import * as Styles from './SignApplication.styles';
import { UserSignatureOptionList } from './UserSignatureOptionList';

export const signApplicationValidationSchema = Yup.object().shape({
  primaryAccountHolder: Yup.string().required('Signature is required'),
  isSubjectToBackupWithholding: Yup.boolean().default(false),
});

export const signJointApplicationValidationSchema = Yup.object().shape({
  primaryAccountHolder: Yup.string().required('Primary Account Holder Signature is required'),
  secondaryAccountHolder: Yup.string().required('Joint Account Holder Signature is required'),
  isSubjectToBackupWithholding: Yup.boolean().default(false),
});

export interface SignAccountFormValues {
  isBackupWithholding?: boolean;
  primaryAccountHolder?: HTMLElement;
  secondaryAccountHolder?: HTMLElement;
}

export const SignApplication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadingAccountSubmit: boolean = useSelector((state: any) => state.accounts.submit.isLoading);
  const succeededAccountSubmit = useSelector((state: any) => Boolean(state.accounts.submit.__succeeded));

  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const onSubmit = async (values: SignAccountFormValues) => {
    assertNonNullable(account, 'Account');
    assertPropertyIsFound(values, 'primaryAccountHolder');
    let primarySignature: string = await getCanvas(values.primaryAccountHolder).then(canvas => {
      return canvas.toDataURL();
    });

    let secondarySignature: Maybe<string>;

    if (account.type.isJoint) {
      assertPropertyIsFound(values, 'secondaryAccountHolder');
      secondarySignature = await getCanvas(values.secondaryAccountHolder).then(canvas => {
        return canvas.toDataURL();
      });
    }

    dispatch(
      doSubmitAccount({
        params: {
          id: account.id,
        },
        body: { signature: { primaryAccountHolder: primarySignature, secondaryAccountHolder: secondarySignature } },
      }),
    );
  };

  useEffect(() => {
    if (succeededAccountSubmit) {
      window.gtag('event', 'account', {
        accountId: account?.accountId,
      });

      return navigate(`/`);
    }
  }, [succeededAccountSubmit]);

  useEffect(() => {
    dispatch(doClearSubmitAccount());
  }, []);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.SignApplication}
      title={isMobile ? undefined : 'Sign Application'}>
      {!isAccountComplete(account) && (
        <Col span={24}>
          <MAlert
            showIcon
            type='info'
            description={
              <>
                <span>
                  Before you can sign the application to set up your My IPO account, you will need to complete all the
                  required information listed under the sections
                  {isMobile ? 'above' : 'on the left'}. If there is a section that has not been completed, it will
                  appear with the following pending icon
                </span>
                <i className={[`ri-time-line`, Styles.timelineIcon].join(' ')} />
              </>
            }
            className={Styles.alert}
          />
        </Col>
      )}
      <MFormSection
        isEditable
        isEditMode
        className={isAccountComplete(account) ? undefined : Styles.blurSection}
        testId={'account-sign-application'}>
        <Formik<SignAccountFormValues>
          validateOnChange
          validateOnBlur
          initialValues={{
            isBackupWithholding: false,
          }}
          validationSchema={
            account?.type.isJoint ? signJointApplicationValidationSchema : signApplicationValidationSchema
          }
          onSubmit={values => {
            onSubmit(values);
          }}>
          {form => {
            return (
              <>
                <Col span={24}>
                  <AccountAgreementContent form={form} />
                </Col>
                <Col span={24}>
                  <UserSignatureOptionList
                    title={account?.type.isJoint ? 'Primary Account Holder Signature' : undefined}
                    fullName={`${account?.primaryAccountHolder?.firstName} ${account?.primaryAccountHolder?.lastName}`}
                    value={form.values.primaryAccountHolder}
                    error={form.errors.primaryAccountHolder}
                    onChange={signature => {
                      form.setFieldValue('primaryAccountHolder', signature);
                    }}
                  />
                </Col>

                {account?.type.isJoint && (
                  <Col span={24}>
                    <UserSignatureOptionList
                      title={'Secondary Account Holder Signature'}
                      fullName={`${account?.secondaryAccountHolder?.firstName} ${account?.secondaryAccountHolder?.lastName}`}
                      value={form.values.secondaryAccountHolder}
                      error={form.errors.secondaryAccountHolder}
                      onChange={signature => {
                        form.setFieldValue('secondaryAccountHolder', signature);
                      }}
                    />
                  </Col>
                )}

                <Col span={24} className={Styles.buttonContainer}>
                  <MButton
                    loading={loadingAccountSubmit}
                    disabled={!form.isValid}
                    onClick={() => {
                      form.submitForm();
                    }}
                    testId={`account-btn-create-account`}>
                    Create Account
                  </MButton>
                </Col>
              </>
            );
          }}
        </Formik>
      </MFormSection>
    </MyAccountLayout>
  );
};
