import { useMediaQuery } from 'react-responsive';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';
import { isAccountAgreementsComplete } from 'src/utils';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import { ScreenBreakpoint } from '../../../styles';

import { AccountAgreementsForm } from './AccountAgreementsForm';
import { AccountAgreementsView } from './AccountAgreementsView';

export const AccountAgreements = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });
  const { account } = useAccountSelector();

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.AccountAgrements}
      title={isMobile ? undefined : 'Account Agreements'}>
      <MFormSection isEditMode isEditable={false} testId={'account-agreement'}>
        {isAccountAgreementsComplete(account) ? <AccountAgreementsView /> : <AccountAgreementsForm />}
      </MFormSection>
    </MyAccountLayout>
  );
};
