import { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { TradingBlockDocumentNameDto } from 'src/dtos';
import { useAccountSelector, useInvestigationListSelector } from 'src/hooks';
import { AccountHolder, InvestigationCategoryName, TradingBlockInvestigationCipCategory } from 'src/models';
import { Heading, Spacing } from 'src/styles';

import { getInvestigations } from '../../../actions';
import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarOtherMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import Spinner from '../../../lib/Miscellaneous/Spinner';

import * as Styles from './AccountStatus.styles';
import { AccountStatusHeader } from './AccountStatusHeader';
import { ApplicationCipFailureForm } from './ApplicationCipFailureForm';
import { InvestigationCipFailureForm } from './InvestigationCipFailureForm';

export const AccountStatus = () => {
  const dispatch = useDispatch();

  const [primaryNameOrDateOfBirthSelectedDocumentType, setPrimaryNameOrDateOfBirthSelectedDocumentType] =
    useState<TradingBlockDocumentNameDto | null>(null);
  const [secondaryNameOrDateOfBirthSelectedDocumentType, setSecondaryNameOrDateOfBirthSelectedDocumentType] =
    useState<TradingBlockDocumentNameDto | null>(null);

  const {
    status,
    primaryAccountHolderApplications,
    primaryAccountHolderInvestigations,
    secondaryAccountHolderApplications,
    secondaryAccountHolderInvestigations,
    isInvestigationListLoading,
    isAnyPrimaryAccountHolderDocumentRequested,
    isAnySecondaryAccountHolderDocumentRequested,
  } = useInvestigationListSelector();

  const { account } = useAccountSelector();

  const getAccountHolderName = (accountHolder?: AccountHolder) => {
    return `${accountHolder?.firstName} ${accountHolder?.lastName}`;
  };

  const removeAddressCategoryIfNecessary = ({
    category,
    document,
  }: {
    category: TradingBlockInvestigationCipCategory;
    document: TradingBlockDocumentNameDto | null;
  }): boolean => {
    return !(
      document &&
      [TradingBlockDocumentNameDto.DriversLicense, TradingBlockDocumentNameDto.Passport].includes(document) &&
      category.name === InvestigationCategoryName.Address
    );
  };

  const removePrimaryAddressCategoryIfNecessary = (category: TradingBlockInvestigationCipCategory): boolean =>
    removeAddressCategoryIfNecessary({ category, document: primaryNameOrDateOfBirthSelectedDocumentType });

  const removeSecondaryAddressCategoryIfNecessary = (category: TradingBlockInvestigationCipCategory): boolean =>
    removeAddressCategoryIfNecessary({ category, document: secondaryNameOrDateOfBirthSelectedDocumentType });

  useEffect(() => {
    if (account?.accountId) dispatch(getInvestigations(account?.accountId));
  }, [account?.accountId]);

  if (isInvestigationListLoading || !status) {
    return <Spinner />;
  }

  return (
    <MyAccountLayout sidebarMenuItemKey={MyAccountSidebarOtherMenuItemKey.AccountStatus} title='Account status'>
      <Row>
        <Col>
          <div className={Styles.formCard}>
            <AccountStatusHeader />
            {(status.isPending || status.isRejected) && (
              <>
                {account?.type.isJoint && isAnyPrimaryAccountHolderDocumentRequested && (
                  <Row className={cx(Heading.title, Spacing.my24)}>
                    Primary Account Holder ({getAccountHolderName(account?.primaryAccountHolder)})
                  </Row>
                )}
                {isAnyPrimaryAccountHolderDocumentRequested && (
                  <Col span={24} className={Styles.statusLabel}>
                    Additional Information
                  </Col>
                )}
                {primaryAccountHolderApplications.map(application =>
                  application.appCategories
                    .filter(category => !isEmpty(category.requestedDocuments))
                    .map(appCategory => {
                      return (
                        <ApplicationCipFailureForm
                          key={`${appCategory.name}_key`}
                          category={appCategory}
                          uploadedDocuments={application.uploadedDocuments ?? []}
                          accountHolderId={application.accountHolderId}
                          accountId={account?.accountId}
                        />
                      );
                    }),
                )}
                {primaryAccountHolderInvestigations.map(investigation =>
                  investigation.cipCategories
                    .filter(category => !isEmpty(category.requestedDocuments))
                    .filter(removePrimaryAddressCategoryIfNecessary)
                    .map(cipCategory => {
                      return (
                        <div key={`${cipCategory?.name}_key`}>
                          <InvestigationCipFailureForm
                            investigationId={investigation.investigationId}
                            category={cipCategory}
                            uploadedDocuments={investigation.uploadedDocuments ?? []}
                            accountId={account?.accountId}
                            onChange={
                              cipCategory.name === InvestigationCategoryName.NameOrDateOfBirth
                                ? documentName => {
                                    setPrimaryNameOrDateOfBirthSelectedDocumentType(documentName);
                                  }
                                : undefined
                            }
                          />
                        </div>
                      );
                    }),
                )}

                {account?.type.isJoint && isAnySecondaryAccountHolderDocumentRequested && (
                  <>
                    <Row className={cx(Heading.title, Spacing.my24)}>
                      Joint Account Holder ({getAccountHolderName(account?.secondaryAccountHolder)})
                    </Row>
                    <Col span={24} className={Styles.statusLabel}>
                      Additional Information
                    </Col>
                  </>
                )}
                {secondaryAccountHolderApplications.map(application =>
                  application.appCategories
                    .filter(category => !isEmpty(category.requestedDocuments))
                    .map(appCategory => {
                      return (
                        <ApplicationCipFailureForm
                          key={`${appCategory.name}_key`}
                          category={appCategory}
                          uploadedDocuments={application.uploadedDocuments ?? []}
                          accountHolderId={application.accountHolderId}
                          accountId={account?.accountId}
                        />
                      );
                    }),
                )}
                {secondaryAccountHolderInvestigations.map(investigation =>
                  investigation.cipCategories
                    .filter(category => !isEmpty(category.requestedDocuments))
                    .filter(removeSecondaryAddressCategoryIfNecessary)
                    .map(cipCategory => {
                      return (
                        <div key={`${cipCategory?.name}_key`}>
                          <InvestigationCipFailureForm
                            investigationId={investigation.investigationId}
                            category={cipCategory}
                            uploadedDocuments={investigation.uploadedDocuments ?? []}
                            accountId={account?.accountId}
                            onChange={
                              cipCategory.name === InvestigationCategoryName.NameOrDateOfBirth
                                ? documentName => {
                                    setSecondaryNameOrDateOfBirthSelectedDocumentType(documentName);
                                  }
                                : undefined
                            }
                          />
                        </div>
                      );
                    }),
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </MyAccountLayout>
  );
};
