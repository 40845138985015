import {
  GetInvestigationListResponseDto,
  TradingBlockInvestigationCategoryNameDto,
  TradingBlockInvestigationCipCategoryDto,
  TradingBlockInvestigationDto,
} from 'src/dtos';
import {
  InvestigationStatus,
  GetInvestigationListResponse,
  InvestigationDocumentName,
  InvestigationCategoryName,
  TradingBlockInvestigationCipCategory,
} from 'src/models';
import { Maybe } from 'src/utils';

export const mapInvestigationCipCategoryNameDtoToModel = (
  category: TradingBlockInvestigationCategoryNameDto,
): InvestigationCategoryName => {
  if (category === TradingBlockInvestigationCategoryNameDto.TaxId) {
    return InvestigationCategoryName.TaxId;
  }

  if (category === TradingBlockInvestigationCategoryNameDto.Address) {
    return InvestigationCategoryName.Address;
  }

  return InvestigationCategoryName.NameOrDateOfBirth;
};

const collapseNameAndDateOfBirthCategories = ({
  acc,
  category,
  investigation,
}: {
  acc: TradingBlockInvestigationCipCategory[];
  category: TradingBlockInvestigationCipCategoryDto;
  investigation: TradingBlockInvestigationDto;
}): Maybe<TradingBlockInvestigationCipCategory> => {
  if (acc.some(aCategory => aCategory.name === InvestigationCategoryName.NameOrDateOfBirth)) {
    return;
  }

  let unresolvedCategory: Maybe<TradingBlockInvestigationCipCategoryDto>;

  const nameCategory = investigation.cipCategories?.find(
    aCategory => aCategory.name === TradingBlockInvestigationCategoryNameDto.Name,
  );

  const dateOfBirthCategory = investigation.cipCategories?.find(
    aCategory => aCategory.name === TradingBlockInvestigationCategoryNameDto.DateOfBirth,
  );

  const requestedDocuments =
    nameCategory?.requestedDocuments?.map(document => new InvestigationDocumentName(document)) ??
    dateOfBirthCategory?.requestedDocuments?.map(document => new InvestigationDocumentName(document)) ??
    [];

  if (!nameCategory?.isResolved) {
    unresolvedCategory = nameCategory;
  }

  if (!dateOfBirthCategory?.isResolved) {
    unresolvedCategory = dateOfBirthCategory;
  }

  return {
    ...(unresolvedCategory ?? category),
    name: InvestigationCategoryName.NameOrDateOfBirth,
    requestedDocuments,
  };
};

export const mapInvestigationCipCategoryList = (
  investigation: TradingBlockInvestigationDto,
): TradingBlockInvestigationCipCategory[] => {
  const result: Maybe<TradingBlockInvestigationCipCategory[]> = investigation.cipCategories?.reduce(
    (acc: TradingBlockInvestigationCipCategory[], category: TradingBlockInvestigationCipCategoryDto) => {
      if (
        [TradingBlockInvestigationCategoryNameDto.Name, TradingBlockInvestigationCategoryNameDto.DateOfBirth].includes(
          category.name,
        )
      ) {
        const collapsedCategory = collapseNameAndDateOfBirthCategories({ acc, category, investigation });

        if (collapsedCategory) {
          acc.push(collapsedCategory);
        }

        return acc;
      }

      acc.push({
        ...category,
        name: mapInvestigationCipCategoryNameDtoToModel(category.name),
        requestedDocuments: category.requestedDocuments?.map(document => new InvestigationDocumentName(document)) ?? [],
      });

      return acc;
    },
    [],
  );

  return result ?? [];
};

export const mapGetInvestigationListResponseDtoToModel = (
  response: GetInvestigationListResponseDto,
): GetInvestigationListResponse => {
  return {
    id: response.state,
    applications:
      response.applications?.map(application => ({
        ...application,
        appCategories:
          application.appCategories?.map(category => ({
            ...category,
            requestedDocuments:
              category.requestedDocuments?.map(document => new InvestigationDocumentName(document)) ?? [],
          })) ?? [],
      })) ?? [],
    investigations:
      response.investigations?.map(investigation => ({
        ...investigation,
        cipCategories: mapInvestigationCipCategoryList(investigation),
      })) ?? [],
    status: new InvestigationStatus(response.state),
  };
};
