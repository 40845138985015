import { AccountDto } from 'src/dtos';
import { Account, AccountType, JointAccountType } from 'src/models';
import { getUnitedStateOptionLabel } from 'src/utils';

import { mapAccountHolderDtoToModel } from './accountHolders.mappers';

export const mapAccountDtoToModel = (dto: AccountDto): Account => ({
  id: dto.id,
  type: new AccountType(dto.type),
  jointAccountType: new JointAccountType(dto.jointAccountType),
  jointCommunityPropertyState: dto.jointCommunityPropertyState
    ? { label: getUnitedStateOptionLabel(dto.jointCommunityPropertyState), value: dto.jointCommunityPropertyState }
    : undefined,
  accountId: dto.accountId,
  accountNumber: dto.accountNumber,
  primaryAccountHolder: dto.primaryAccountHolder && mapAccountHolderDtoToModel(dto.primaryAccountHolder),
  secondaryAccountHolder: dto.secondaryAccountHolder && mapAccountHolderDtoToModel(dto.secondaryAccountHolder),
});
