import { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Row } from 'antd';
import { isUndefined } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { IdentityVerificationInstructions, MFormSection } from 'src/lib';
import { Heading, Spacing } from 'src/styles';

import { PersonalInformationForm } from './PersonalInformationForm';
import { PersonalInformationView } from './PersonalInformationView';

export const AccountHolderSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isAlreadySaved = () => !isUndefined(account?.primaryAccountHolder?.dateOfBirth);

  useEffect(() => {
    if (isAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.primaryAccountHolder]);

  if (!account) {
    return null;
  }

  return (
    <>
      {account.type.isJoint && <Row className={cx(Heading.title, Spacing.my32)}>Primary Account Holder</Row>}
      <MFormSection
        title='Account Holder Information'
        onEdit={onEdit}
        isEditable={isEditable}
        isEditMode={isEditMode}
        tooltip={{
          icon: 'info',
          title: 'Identity Verification Instructions',
          label: 'Please enter your name as shown on a government-issued ID',
          type: 'modal',
          content: <IdentityVerificationInstructions />,
        }}
        testId={'personal-information'}>
        {isAlreadySaved() && !isEditMode ? (
          <PersonalInformationView accountHolder={account?.primaryAccountHolder} isPrimary />
        ) : (
          <PersonalInformationForm
            accountHolder={account?.primaryAccountHolder}
            accountId={account?.accountId}
            accountUuid={account?.id}
            onCancel={onCancel}
            isPrimary
          />
        )}
      </MFormSection>
    </>
  );
};
