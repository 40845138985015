export const arbitrationClausesLinks: Array<{ label: string; url: string }> = [
  {
    label: 'Apex Arbitration Clause',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Customer_Agreement_v5.6_20200424.pdf#page=3',
  },
  {
    label: 'Customer Agreement',
    url: 'https://legacy.tradingblock.com/Docs/Agreements/Customer_Agreement_v5.6_20200424.pdf',
  },
];
