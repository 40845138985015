import { AnyAction } from 'redux';
import {
  CreateAccountDto,
  DownloadAccountSignatureDto,
  GetAccountDto,
  PatchAccountDto,
  PatchJointAccountInterestDto,
  SubmitAccountDto,
  SwitchAccountDto,
} from 'src/dtos';

import { createAction, State, Type } from './utils';

export const doGetAccount = (payload: GetAccountDto): AnyAction =>
  createAction(State.actionRequested(Type.GET_ACCOUNT), payload);

export const doSucceededGetAccount = (payload: any): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_ACCOUNT), payload);

export const doClearGetAccount = (): AnyAction => createAction(State.actionCleared(Type.GET_ACCOUNT));

export const doCreateAccount = (payload: CreateAccountDto): AnyAction =>
  createAction(State.actionRequested(Type.CREATE_ACCOUNT), payload);

export const doClearCreateAccount = (): AnyAction => createAction(State.actionCleared(Type.CREATE_ACCOUNT));

export const doPatchAccount = (payload: PatchAccountDto): AnyAction =>
  createAction(State.actionRequested(Type.PATCH_ACCOUNT), payload);

export const doClearPatchAccount = (): AnyAction => createAction(State.actionCleared(Type.PATCH_ACCOUNT));

export const doPatchJointAccountInterest = (payload: PatchJointAccountInterestDto): AnyAction =>
  createAction(State.actionRequested(Type.PATCH_JOINT_ACCOUNT_INTEREST), payload);

export const doClearPatchJointAccountInterest = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_JOINT_ACCOUNT_INTEREST));

export const doSubmitAccount = (payload: SubmitAccountDto): AnyAction =>
  createAction(State.actionRequested(Type.SUBMIT_ACCOUNT), payload);

export const doClearSubmitAccount = (): AnyAction => createAction(State.actionCleared(Type.SUBMIT_ACCOUNT));

export const doDownloadAccountSignature = (payload: DownloadAccountSignatureDto): AnyAction =>
  createAction(State.actionRequested(Type.DOWNLOAD_ACCOUNT_SIGNATURE), payload);

export const doSwitchAccount = (payload: SwitchAccountDto): AnyAction =>
  createAction(State.actionRequested(Type.SWITCH_ACCOUNT), payload);

export const doClearSwitchAccount = (): AnyAction => createAction(State.actionCleared(Type.SWITCH_ACCOUNT));
