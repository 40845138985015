import { css } from '@emotion/css';
import { BorderRadiusSize, ColorConstant } from 'src/styles';

export const segmented = css`
  border-radius: ${BorderRadiusSize.xs};

  .ant-segmented-item-selected {
    color: white;
    background-color: ${ColorConstant.BRAND6};
  }

  .ant-segmented-item-selected {
    // border-radius: ${BorderRadiusSize.xs};
  }
`;
