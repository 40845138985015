import { useCallback, useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Col, Row } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { doClearCreateAccount, doClearPatchAccount, doCreateAccount, doPatchAccount } from 'src/actions';
import { AccountTypeDto, JointAccountTypeDto } from 'src/dtos';
import { useAuthenticatedUserSelector } from 'src/hooks';
import { MFormSaveButton, MFormSegmented, MFormSelect } from 'src/lib';
import { Account, AccountTypeLabel, JointAccountTypeLabel } from 'src/models';
import { Heading, ScreenBreakpoint, Spacing } from 'src/styles';
import { assertNonNullable } from 'src/utils';
import * as Yup from 'yup';

import { AccountTypeDescription } from './AccountTypeDescription';
import { JointAccountTypeDescription } from './JointAccountTypeDescription';

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Account type is required'),
  jointAccountType: Yup.string().when('type', {
    is: AccountTypeDto.Joint,
    then: Yup.string().required('Joint account type is required'),
    otherwise: Yup.string().notRequired(),
  }),
  jointCommunityPropertyState: Yup.string().when(['type', 'jointAccountType'], {
    is: (type: AccountTypeDto, jointAccountType: JointAccountTypeDto) =>
      type === AccountTypeDto.Joint && jointAccountType === JointAccountTypeDto.Community,
    then: Yup.string().required('Joint community property state is required'),
    otherwise: Yup.string().notRequired(),
  }),
});

const jointAccountTypeOptions: { value: string; label: string }[] = [
  {
    value: JointAccountTypeDto.Community,
    label: JointAccountTypeLabel.Community,
  },
  {
    value: JointAccountTypeDto.Survivor,
    label: JointAccountTypeLabel.Survivor,
  },
  {
    value: JointAccountTypeDto.TenantsInCommon,
    label: JointAccountTypeLabel.TenantsInCommon,
  },
  {
    value: JointAccountTypeDto.TenantsInEntirety,
    label: JointAccountTypeLabel.TenantsInEntirety,
  },
];

const accountTypeOptions = [
  { value: AccountTypeDto.Individual, label: AccountTypeLabel.Individual },
  { value: AccountTypeDto.Joint, label: AccountTypeLabel.Joint },
];

const communityStateList = [
  { label: 'Arizona', value: 'AZ' },
  { label: 'California', value: 'CA' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'Texas', value: 'TX' },
  { label: 'Washington', value: 'WA' },
  { label: 'Wisconsin', value: 'WI' },
];

export interface AccountTypeFormValues {
  type: AccountTypeDto;
  jointAccountType?: JointAccountTypeDto;
  jointCommunityPropertyState?: string;
}

export interface AccountTypeFormProps {
  account?: Account;
  onCancel?: () => void;
  onSave?: () => void;
}

export const AccountTypeForm = ({ account, onCancel, onSave }: AccountTypeFormProps) => {
  const dispatch = useDispatch();

  const isCreateLoading = useSelector((state: any) => state.accounts.create.isLoading);
  const isCreateSuccess = useSelector((state: any) => Boolean(state.accounts.create.__succeeded));

  const isPatchLoading = useSelector((state: any) => state.accounts.patch.isLoading);
  const isPatchSuccess = useSelector((state: any) => Boolean(state.accounts.patch.__succeeded));

  const [shouldPatch, setShouldPatch] = useState<boolean>(false);

  const { user } = useAuthenticatedUserSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const isAccountAlreadyCreated = () => !isUndefined(account?.type);

  const getAccountTypeOptionList = useCallback(() => {
    const optionList = accountTypeOptions.filter(anAccount => {
      if (user?.accounts) {
        return (
          anAccount.value === account?.type?.value ||
          !user.accounts.some(userAccount => userAccount.type.value === anAccount.value)
        );
      }

      return false;
    });

    return optionList;
  }, [account, user?.accounts]);

  const _onCancel = (form: FormikProps<AccountTypeFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<AccountTypeFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    if (isAccountAlreadyCreated()) {
      setShouldPatch(true);
    }
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (isCreateSuccess || isPatchSuccess) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [isCreateSuccess, isPatchSuccess]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccount());
      dispatch(doClearPatchAccount());
    };
  }, []);

  return (
    <Formik<AccountTypeFormValues>
      enableReinitialize
      validateOnChange
      validateOnBlur
      initialValues={{
        type: account?.type?.value ?? getAccountTypeOptionList()[0]?.value,
        jointAccountType: account?.jointAccountType?.value,
        jointCommunityPropertyState: account?.jointCommunityPropertyState?.value,
      }}
      validationSchema={validationSchema}
      onSubmit={(values: AccountTypeFormValues) => {
        const dto = {
          type: values.type,
          jointAccountType: values.type === AccountTypeDto.Joint ? values.jointAccountType : undefined,
          jointCommunityPropertyState:
            values.type === AccountTypeDto.Joint && values.jointAccountType === JointAccountTypeDto.Community
              ? values.jointCommunityPropertyState
              : undefined,
        };

        if (shouldPatch) {
          assertNonNullable(account, 'Account');

          dispatch(
            doPatchAccount({
              params: {
                id: account.id,
              },
              body: dto,
            }),
          );

          return;
        }

        dispatch(doCreateAccount({ body: dto }));
      }}>
      {form => {
        return (
          <>
            <Row style={{ width: '100%' }} gutter={[16, 0]} className={Spacing.mb12}>
              <Col span={isMobile ? 24 : 14}>
                <MFormSegmented
                  label='What type of account do you wish to open?'
                  defaultValue={account?.type?.value}
                  options={getAccountTypeOptionList()}
                  onChange={(value: string | number) => {
                    form.setFieldValue('type', value);
                  }}
                  error={form.errors.type}
                  className={isMobile ? Spacing.mb0 : undefined}
                  testId={'account-type'}
                />
              </Col>
              <Col span={isMobile ? 24 : 10} className={Heading.subtitle} data-testid={'account-type-description'}>
                <AccountTypeDescription value={form.values.type} />
              </Col>
            </Row>

            {form.values.type === AccountTypeDto.Joint && (
              <Row style={{ width: '100%' }} gutter={[16, 0]} className={Spacing.my12}>
                <Col span={isMobile ? 24 : 14}>
                  <Row style={{ width: '100%' }}>
                    <Col span={24}>
                      <MFormSelect
                        label='Type of joint account'
                        defaultValue={account?.jointAccountType?.value}
                        value={form.values.jointAccountType}
                        options={jointAccountTypeOptions}
                        onChange={value => {
                          form.setFieldValue('jointAccountType', value);
                        }}
                        error={form.errors.jointAccountType}
                        align='vertical'
                        className={isMobile ? Spacing.mb0 : undefined}
                      />
                    </Col>
                    {isMobile && (
                      <Col span={24} className={cx(Heading.subtitle, Spacing.mb12)}>
                        <JointAccountTypeDescription value={form.values.jointAccountType} />
                      </Col>
                    )}
                    {form.values.type === AccountTypeDto.Joint &&
                      form.values.jointAccountType === JointAccountTypeDto.Community && (
                        <Col span={24}>
                          <MFormSelect
                            label='Property State'
                            placeholder='Select'
                            defaultValue={account?.jointCommunityPropertyState?.value}
                            value={form.values.jointCommunityPropertyState}
                            options={communityStateList}
                            error={form.errors.jointCommunityPropertyState}
                            onChange={value => {
                              form.setFieldValue('jointCommunityPropertyState', value);
                            }}
                            testId={'joint-community-property-state'}
                            align='vertical'
                          />
                        </Col>
                      )}
                  </Row>
                </Col>

                {!isMobile && (
                  <Col span={10} className={Heading.subtitle}>
                    <JointAccountTypeDescription value={form.values.jointAccountType} />
                  </Col>
                )}
              </Row>
            )}

            <Col span={24}>
              <MFormSaveButton<AccountTypeFormValues>
                loading={isCreateLoading || isPatchLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode={shouldPatch}
                form={form}
                testId={'account-type'}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
