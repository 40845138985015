import { useEffect } from 'react';

import { useOutletContext } from 'react-router-dom';

export const TermsOfUse = () => {
  const [, setTitle] = useOutletContext<any>();

  useEffect(() => {
    setTitle('Terms of Use');
  }, []);

  return (
    <div>
      <p>
        Cambria Asset Management, Inc. (&quot;Cambria&quot;) is a privately-held company and is based in Salt Lake City,
        Utah. AOS, Inc. dba MY IPO (&quot;AOS&quot;) which has a software and trademark license agreement with cambria
        for the My IPO software and trademark, is a securities broker-dealer and a member of FINRA and SPIC. All
        references to &quot;Cambria&quot; on this web site (this &quot;Site&quot;) refer to Cambria Asset Management,
        Inc and AOS, Inc. All references to &quot;AOS&quot; and &quot;My IPO&quot; on this web site (this
        &quot;Site&quot;) refer AOS. By using this Site, you agree to the following terms and conditions, which Cambria
        or AOS may modify at any time without prior notice.
      </p>
      <p>
        The information on this website has been published for informational purposes only, and is not a solicitation or
        offer of any security or of any investment service. The content of this website is provided solely for your
        personal use (subject to the limitations described on this web site), and the information may not be copied,
        reproduced, republished, transmitted or distributed in any way, without the express written permission of
        Cambria or AOS. Modification or use of the information for any purpose other than your personal use, is a
        violation of the copyrights and other proprietary rights of Cambria or AOS.
      </p>
      <p>
        The information provided on this site is not intended for distribution to, or use by, any person or entity in
        any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
        subject Cambria and AOS to any registration requirement within such jurisdiction or country. Neither the
        information, nor any opinion contained in this site, constitutes a solicitation or offer by Cambria, AOS or its
        affiliates to buy or sell any securities, futures, options or other financial instruments. Cambria and AOS does
        not intend the information on this web site to be investment advice, and the information presented at this site
        should not be relied upon to make an investment decision. Although the information provided to you on this site
        is obtained or compiled from sources we believe to be reliable, the content of this website is provided “as is”
        without warranty of any kind (either express or implied). Cambria and AOS do not assume any obligation to update
        the information and data at this web site and neither party guarantees that it is accurate, current, valid,
        complete or suitable for any particular purpose
      </p>
      <p>
        The Web site and its contents are the property of Cambria. Copyright © 2025 Cambria Asset Management, Inc.
        Unless otherwise noted, the information, trademarks, and logos on this web site are the property of Cambria. The
        posting of such material on this web site shall not limit Cambria’s ownership interests in such material. Users
        of this web site may make single copies of information for their own personal use, but are prohibited from
        selling or republishing any portion of this web site without the prior written consent of Cambria.
      </p>
    </div>
  );
};

export default TermsOfUse;
