import { Account } from 'src/models';

import { useAccountSelector } from './useAccountSelector';
import { useAuthenticatedUserSelector } from './useAuthenticatedUserSelector';

export const useFindOtherAccountList = (): Account[] => {
  const { account } = useAccountSelector();
  const { user } = useAuthenticatedUserSelector();

  return user?.accounts?.filter(acc => acc.id !== account?.id) ?? [];
};
