import { useEffect, useReducer } from 'react';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { InvestigationStatus, TradingBlockApplication, TradingBlockInvestigation } from 'src/models';
import { Maybe } from 'src/utils';

import { useAccountSelector } from './useAccountSelector';

const EMPTY_APPLICATION_ARRAY: TradingBlockApplication[] = [];
const EMPTY_INVESTIGATION_ARRAY: TradingBlockInvestigation[] = [];

interface UseInvestigationListSelectorReturnType {
  status: Maybe<InvestigationStatus>;
  primaryAccountHolderApplications: TradingBlockApplication[];
  primaryAccountHolderInvestigations: TradingBlockInvestigation[];
  secondaryAccountHolderApplications: TradingBlockApplication[];
  secondaryAccountHolderInvestigations: TradingBlockInvestigation[];
  isInvestigationListLoading: boolean;
  isAnyDocumentRequested: boolean;
  isAnyPrimaryAccountHolderDocumentRequested: boolean;
  isAnySecondaryAccountHolderDocumentRequested: boolean;
}

type State = Omit<UseInvestigationListSelectorReturnType, 'isInvestigationListLoading' | 'status'>;

type Action = { type: 'UPDATE'; payload: State };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'UPDATE':
      return action.payload;
    default:
      return state;
  }
};

const initialState: State = {
  primaryAccountHolderApplications: [],
  primaryAccountHolderInvestigations: [],
  secondaryAccountHolderApplications: [],
  secondaryAccountHolderInvestigations: [],
  isAnyDocumentRequested: false,
  isAnyPrimaryAccountHolderDocumentRequested: false,
  isAnySecondaryAccountHolderDocumentRequested: false,
};

export const useInvestigationListSelector = (): UseInvestigationListSelectorReturnType => {
  const investigationState: Maybe<InvestigationStatus> = useSelector(
    (state: any) => state.investigations.list.data?.status,
  );
  const applications: Maybe<TradingBlockApplication[]> = useSelector(
    (state: any) => state.investigations.list.data?.applications,
  );
  const investigations: Maybe<TradingBlockInvestigation[]> = useSelector(
    (state: any) => state.investigations.list.data?.investigations,
  );
  const isLoadingInvestigations: boolean = useSelector((state: any) => state.investigations.list.isLoading);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { account } = useAccountSelector();

  useEffect(() => {
    const primaryAccountHolderApplications =
      applications?.filter(
        application =>
          application.firstName === account?.primaryAccountHolder?.firstName &&
          application.lastName === account?.primaryAccountHolder?.lastName,
      ) ?? EMPTY_APPLICATION_ARRAY;
    const primaryAccountHolderInvestigations =
      investigations?.filter(
        investigation =>
          investigation.firstName === account?.primaryAccountHolder?.firstName &&
          investigation.lastName === account?.primaryAccountHolder?.lastName,
      ) ?? EMPTY_INVESTIGATION_ARRAY;
    const secondaryAccountHolderApplications =
      applications?.filter(
        application =>
          application.firstName === account?.secondaryAccountHolder?.firstName &&
          application.lastName === account?.secondaryAccountHolder?.lastName,
      ) ?? EMPTY_APPLICATION_ARRAY;
    const secondaryAccountHolderInvestigations =
      investigations?.filter(
        investigation =>
          investigation.firstName === account?.secondaryAccountHolder?.firstName &&
          investigation.lastName === account?.secondaryAccountHolder?.lastName,
      ) ?? EMPTY_INVESTIGATION_ARRAY;

    const isAnyPrimaryAccountHolderDocumentUnresolved =
      primaryAccountHolderApplications.some(application =>
        application.appCategories?.some(category => !category.isResolved),
      ) ||
      primaryAccountHolderInvestigations.some(investigation =>
        investigation.cipCategories?.some(category => !category.isResolved),
      );
    const isAnySecondaryAccountHolderDocumentUnresolved =
      secondaryAccountHolderApplications.some(application =>
        application.appCategories?.some(category => !category.isResolved),
      ) ||
      secondaryAccountHolderInvestigations.some(investigation =>
        investigation.cipCategories?.some(category => !category.isResolved),
      );

    const isAnyPrimaryAccountHolderDocumentRequested =
      primaryAccountHolderApplications.some(application =>
        application.appCategories?.some(category => !isEmpty(category.requestedDocuments)),
      ) ||
      primaryAccountHolderInvestigations.some(investigation =>
        investigation.cipCategories?.some(category => !isEmpty(category.requestedDocuments)),
      );
    const isAnySecondaryAccountHolderDocumentRequested =
      secondaryAccountHolderApplications.some(application =>
        application.appCategories?.some(category => !isEmpty(category.requestedDocuments)),
      ) ||
      secondaryAccountHolderInvestigations.some(investigation =>
        investigation.cipCategories?.some(category => !isEmpty(category.requestedDocuments)),
      );

    dispatch({
      type: 'UPDATE',
      payload: {
        primaryAccountHolderApplications,
        primaryAccountHolderInvestigations,
        secondaryAccountHolderApplications,
        secondaryAccountHolderInvestigations,
        isAnyDocumentRequested:
          isAnyPrimaryAccountHolderDocumentUnresolved || isAnySecondaryAccountHolderDocumentUnresolved,
        isAnyPrimaryAccountHolderDocumentRequested,
        isAnySecondaryAccountHolderDocumentRequested,
      },
    });
  }, [applications, investigations]);

  return {
    status: investigationState,
    isInvestigationListLoading: isLoadingInvestigations,
    primaryAccountHolderApplications: state.primaryAccountHolderApplications,
    primaryAccountHolderInvestigations: state.primaryAccountHolderInvestigations,
    secondaryAccountHolderApplications: state.secondaryAccountHolderApplications,
    secondaryAccountHolderInvestigations: state.secondaryAccountHolderInvestigations,
    isAnyDocumentRequested: state.isAnyDocumentRequested,
    isAnyPrimaryAccountHolderDocumentRequested: state.isAnyPrimaryAccountHolderDocumentRequested,
    isAnySecondaryAccountHolderDocumentRequested: state.isAnySecondaryAccountHolderDocumentRequested,
  };
};
