import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { offerOrdersGetListRequested } from '../actions';
import { CustomHookBaseOptions } from '../models/common.model';
import { OfferOrder } from '../models/offer-orders.model';

import { useAccountSelector } from './useAccountSelector';

interface UseOfferOrdersReturnType {
  offerOrderList: OfferOrder[];
  isOfferOrderListLoading: boolean;
  hasOfferOrderListError?: boolean;
  offerOrderListError?: string;
}

export interface UseOfferOrdersOptions extends CustomHookBaseOptions {}

const EMPTY_OFFER_ORDERS: OfferOrder[] = [];

export const useOfferOrders = ({ skip }: UseOfferOrdersOptions = { skip: false }) => {
  const dispatch = useDispatch();
  const offerOrderList: OfferOrder[] | undefined = useSelector((state: any) => state.offerOrders.list?.data?.data);
  const isOfferOrderListLoading = useSelector((state: any) => Boolean(state.offerOrders.list?.__requested));
  const hasOfferOrderListFailed = useSelector((state: any) => Boolean(state.offerOrders.list?.__failed));
  const offerOrderListError: string | undefined = useSelector((state: any) => state.offerOrders?.list?.__message);
  const { account } = useAccountSelector();

  const shouldFetchOfferOrders = (): boolean =>
    !offerOrderList && !isOfferOrderListLoading && !skip && Boolean(account?.accountId) && !hasOfferOrderListFailed;

  useEffect(() => {
    if (shouldFetchOfferOrders()) {
      dispatch(offerOrdersGetListRequested({ queryParams: { filterBy: { accountId: account?.accountId } } }));
    }
  }, [offerOrderList, isOfferOrderListLoading, account?.accountId, dispatch]);

  return {
    offerOrderList: Array.isArray(offerOrderList) ? offerOrderList : EMPTY_OFFER_ORDERS,
    isOfferOrderListLoading,
    hasOfferOrderListError: hasOfferOrderListFailed,
    offerOrderListError,
  } as UseOfferOrdersReturnType;
};
