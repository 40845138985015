import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { MailingAddressForm } from './MailingAddressForm';
import { MailingAddressView } from './MailingAddressView';

export const JointAccountHolderMailingAddressSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isMailingAddressAlreadySaved = () => !isEmpty(account?.secondaryAccountHolder?.mailingAddress);

  useEffect(() => {
    if (isMailingAddressAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.secondaryAccountHolder?.mailingAddress]);

  if (!account) {
    return null;
  }

  if (!account.type.isJoint) {
    return null;
  }

  return (
    <MFormSection
      title='Mailing Address'
      onEdit={onEdit}
      isEditable={isEditable}
      isEditMode={isEditMode}
      testId={'mailing-address'}>
      {isMailingAddressAlreadySaved() && !isEditMode ? (
        <MailingAddressView accountHolder={account?.secondaryAccountHolder} />
      ) : (
        <MailingAddressForm
          accountHolder={account?.secondaryAccountHolder}
          accountUuid={account?.id}
          isPrimary={false}
          onCancel={onCancel}
        />
      )}
    </MFormSection>
  );
};
