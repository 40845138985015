import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededDefaults } from './utils';

export const patchAccountHolder = (state: any = initialState.accountHolders.patch, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION):
      return requestedDefaults(state, action);
    case State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.patch;
    default:
      return state;
  }
};

export const createPhysicalAddress = (state: any = initialState.accountHolders.createPhysicalAddress, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS):
      return clearedDefaults(state);
    case State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS):
      return requestedDefaults(state, action);
    case State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.createPhysicalAddress;
    default:
      return state;
  }
};

export const patchPhysicalAddress = (state: any = initialState.accountHolders.patchPhysicalAddress, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS):
      return requestedDefaults(state, action);
    case State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.patchPhysicalAddress;
    default:
      return state;
  }
};

export const createMailingAddress = (state: any = initialState.accountHolders.createMailingAddress, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS):
      return clearedDefaults(state);
    case State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS):
      return requestedDefaults(state, action);
    case State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.createMailingAddress;
    default:
      return state;
  }
};

export const patchMailingAddress = (state: any = initialState.accountHolders.patchMailingAddress, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS):
      return requestedDefaults(state, action);
    case State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.patchMailingAddress;
    default:
      return state;
  }
};

export const createTrustedContact = (state: any = initialState.accountHolders.createTrustedContact, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return clearedDefaults(state);
    case State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.createTrustedContact;
    default:
      return state;
  }
};

export const patchTrustedContact = (state: any = initialState.accountHolders.patchTrustedContact, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.patchTrustedContact;
    default:
      return state;
  }
};

export const deleteTrustedContact = (state: any = initialState.accountHolders.deleteTrustedContact, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return clearedDefaults(state);
    case State.actionRequested(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.deleteTrustedContact;
    default:
      return state;
  }
};

export const createSuitabilityInformation = (
  state: any = initialState.accountHolders.createSuitabilityInformation,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.createSuitabilityInformation;
    default:
      return state;
  }
};

export const patchSuitabilityInformation = (
  state: any = initialState.accountHolders.patchSuitabilityInformation,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.patchSuitabilityInformation;
    default:
      return state;
  }
};

// Financial information
export const createFinancialInformationAssets = (
  state: any = initialState.accountHolders.createFinancialInformationAssets,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS):
      return clearedDefaults(state);
    case State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.createFinancialInformationAssets;
    default:
      return state;
  }
};

export const patchFinancialInformationAssets = (
  state: any = initialState.accountHolders.patchFinancialInformationAssets,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.patchFinancialInformationAssets;
    default:
      return state;
  }
};

export const createFinancialInformationEmployment = (
  state: any = initialState.accountHolders.createFinancialInformationEmployment,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT):
      return clearedDefaults(state);
    case State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT):
      return requestedDefaults(state, action);
    case State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.createFinancialInformationEmployment;
    default:
      return state;
  }
};

export const patchFinancialInformationEmployment = (
  state: any = initialState.accountHolders.patchFinancialInformationEmployment,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT):
      return requestedDefaults(state, action);
    case State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.patchFinancialInformationEmployment;
    default:
      return state;
  }
};

export const createDisclosures = (state: any = initialState.accountHolders.createDisclosures, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES):
      return clearedDefaults(state);
    case State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES):
      return requestedDefaults(state, action);
    case State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.createDisclosures;
    default:
      return state;
  }
};

export const patchDisclosures = (state: any = initialState.accountHolders.patchDisclosures, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES):
      return requestedDefaults(state, action);
    case State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountHolders.patchDisclosures;
    default:
      return state;
  }
};

export const accountHolderReducers = combineReducers({
  patch: patchAccountHolder,
  createMailingAddress,
  patchMailingAddress,
  createPhysicalAddress,
  patchPhysicalAddress,
  createTrustedContact,
  patchTrustedContact,
  deleteTrustedContact,
  createSuitabilityInformation,
  patchSuitabilityInformation,
  createFinancialInformationAssets,
  patchFinancialInformationAssets,
  createFinancialInformationEmployment,
  patchFinancialInformationEmployment,
  createDisclosures,
  patchDisclosures,
});
