import { AccountDto } from './accounts.dtos';

export type LoginDto = {
  email: string;
  password: string;
};

export interface AuthenticatedUserLoginResponseDto {
  user: UserDto;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  tbToken: string;
}

export interface MfaRequiredLoginResponseDto {
  session: string;
  phoneNumber: string;
}

export type LoginResponseDto = AuthenticatedUserLoginResponseDto | MfaRequiredLoginResponseDto;

export interface ConfirmMfaCodeResponseDto {
  user: UserDto;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  tbToken: string;
}

export type SignUpDto = LoginDto & {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  phoneNumber: string;
};

export enum SignUpStatusDto {
  AddressStep = 'addressStep',
  SecurityStep = 'securityStep',
  Done = 'done',
}

export interface UserDto {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  email: string;
  phoneNumber: string;
  signUpStatus: SignUpStatusDto;
  isMfaEnabled: boolean;
  isMigrated: boolean;
  isLocked: boolean;
  isClosed: boolean;
  isApproved: boolean;
  lastUsedAccountId?: string;
  address: {
    state: string;
    country: string;
  };
  postCloseOffers: string[];
  createdAt: Date | null;
  updatedAt: Date | null;
  accounts: AccountDto[];
}

export interface UpdatePhoneNumberBodyDto {
  phoneNumber: string;
}
