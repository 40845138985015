import { AccountTypeDto } from 'src/dtos';

interface AccountTypeDescriptionProps {
  value?: AccountTypeDto;
}

export const AccountTypeDescription = ({ value }: AccountTypeDescriptionProps) => {
  if (value === AccountTypeDto.Individual) {
    return (
      <>A standard account with a sole owner. Any assets are passed to the account owner’s estate if owner passes.</>
    );
  }

  if (value === AccountTypeDto.Joint) {
    return <>A brokerage account owned by two or more people. Select the Joint account suitable for you.</>;
  }

  return null;
};
