import { AccountHolderDto, AccountStatusDto } from './accountHolders.dtos';

export enum AccountTypeDto {
  Individual = 'individual',
  Joint = 'joint',
}

export enum JointAccountTypeDto {
  Community = 'Community',
  Survivor = 'Survivor',
  TenantsInCommon = 'TenantsInCommon',
  TenantsInEntirety = 'TenantsInEntirety',
}

export interface AccountDto {
  id: string;
  accountId?: number;
  accountNumber?: string;
  status?: AccountStatusDto;
  type: AccountTypeDto;
  primaryAccountHolder?: AccountHolderDto;
  secondaryAccountHolder?: AccountHolderDto;
  jointAccountType?: JointAccountTypeDto;
  jointCommunityPropertyState?: string;
  tbToken?: string;
}

export interface BaseAccountParamsDto {
  id: string;
}

export interface CreateAccountBodyDto {
  type: AccountTypeDto;
}

export interface CreateAccountDto {
  body: CreateAccountBodyDto | CreateJointAccountBodyDto;
}

export interface GetAccountDto {
  params: BaseAccountParamsDto;
}

export interface CreateJointAccountBodyDto extends CreateAccountBodyDto {
  jointAccountType: JointAccountTypeDto;
  jointCommunityPropertyState?: string;
}

export interface PatchJointAccountBodyDto extends CreateJointAccountBodyDto {}

export interface PatchJointAccountDto {
  params: BaseAccountParamsDto;
  body: PatchJointAccountBodyDto;
}

export interface PatchAccountBodyDto {
  type: AccountTypeDto;
}

export interface PatchAccountDto {
  params: BaseAccountParamsDto;
  body: PatchAccountBodyDto | PatchJointAccountBodyDto;
}

export interface PatchJointAccountInterestBodyDto {
  primaryAccountHolder: {
    percent: number;
  };
  secondaryAccountHolder: {
    percent: number;
  };
}

export interface PatchJointAccountInterestDto {
  params: BaseAccountParamsDto;
  body: PatchJointAccountInterestBodyDto;
}

export interface SubmitAccountBodyDto {
  signature: {
    primaryAccountHolder: string;
    secondaryAccountHolder?: string;
  };
}

export interface SubmitAccountDto {
  params: BaseAccountParamsDto;
  body: SubmitAccountBodyDto;
}

export interface SwitchAccountDto {
  params: BaseAccountParamsDto;
}

export interface DownloadAccountSignatureResponseDto {
  primaryAccountHolder?: string;
  secondaryAccountHolder?: string;
}

export interface DownloadAccountSignatureDto {
  params: BaseAccountParamsDto;
}
