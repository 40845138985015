import { MFormCheckbox, MFormTextField } from 'src/lib';
import { AccountHolder } from 'src/models';

export interface MailingAddressViewProps {
  accountHolder?: AccountHolder;
}

export const MailingAddressView = ({ accountHolder }: MailingAddressViewProps) => {
  if (accountHolder?.mailingAddress?.sameAsPhysical) {
    return <MFormCheckbox label='Mailing address is the same as my physical address' checked disabled />;
  }

  return (
    <>
      <MFormTextField label='Address Line 1' value={accountHolder?.mailingAddress?.address1} />
      <MFormTextField label='Address Line 2 (Opt.)' value={accountHolder?.mailingAddress?.address2} />
      <MFormTextField label='Country' value={accountHolder?.mailingAddress?.country?.label} />
      <MFormTextField label='City' value={accountHolder?.mailingAddress?.city} />
      <MFormTextField label='State' value={accountHolder?.mailingAddress?.state?.label} />
      <MFormTextField label='Postal Code' value={accountHolder?.mailingAddress?.postalCode} />
    </>
  );
};
