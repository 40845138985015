import { cx } from '@emotion/css';
import { useDispatch } from 'react-redux';
import { logout } from 'src/actions';

import * as Styles from './Header.styles';

interface LogoutMenuItemProps {
  onClick: () => void;
  className?: string;
}

export const LogoutMenuItem = ({ onClick, className }: LogoutMenuItemProps) => {
  const dispatch = useDispatch();

  return (
    <div
      style={{ width: '100%' }}
      className={cx(Styles.menuItemWithoutLink, Styles.logoutMenuItemColor, className)}
      onClick={() => {
        onClick();
        dispatch(logout());
      }}>
      Log out
    </div>
  );
};
