import { call, put, select, takeEvery, SagaReturnType } from 'redux-saga/effects';
import { doSucceededGetAccount, toastMessagesAdd } from 'src/actions';
import { AccountApi, getClientApi } from 'src/data-communication';
import {
  CreateAccountDto,
  PatchAccountDto,
  PatchJointAccountInterestDto,
  SubmitAccountDto,
  SwitchAccountDto,
} from 'src/dtos';
import { mapAccountDtoToModel } from 'src/mappers';
import { User } from 'src/models';
import { SeverityEnum, TReduxAction } from 'src/typings/commonTypes';
import { replaceTbToken } from 'src/utils';
import { v4 as uuid } from 'uuid';

import { State, Type } from '../actions/utils';

import { safeSaga } from './utils';

const clientApi = getClientApi();

export function* create(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const dto: CreateAccountDto = action.payload;

  const data: SagaReturnType<AccountApi['create']> = yield call(clientApi.account.create, {
    ...dto,
    authToken,
  });

  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT),
    payload: data,
  });

  const model = mapAccountDtoToModel(data);
  yield put(doSucceededGetAccount(model));

  const user: User = yield select(state => state.user.authenticated.data);

  yield put({
    type: State.actionSucceeded(Type.GET_AUTHENTICATED_USER),
    payload: {
      ...user,
      lastUsedAccountId: model.id,
      accounts: user.accounts.filter(account => account.id !== data.id).concat(model),
    },
  });
}

export function* patch(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const dto: PatchAccountDto = action.payload;

  const data: SagaReturnType<AccountApi['patch']> = yield call(clientApi.account.patch, {
    ...dto,
    authToken,
  });

  yield put({
    type: State.actionSucceeded(Type.PATCH_ACCOUNT),
    payload: data,
  });

  const model = mapAccountDtoToModel(data);
  yield put(doSucceededGetAccount(model));

  const user: User = yield select(state => state.user.authenticated.data);

  yield put({
    type: State.actionSucceeded(Type.GET_AUTHENTICATED_USER),
    payload: { ...user, accounts: user.accounts.filter(account => account.id !== data.id).concat(model) },
  });
}

export function* patchJointAccountInterest(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const dto: PatchJointAccountInterestDto = action.payload;

  const data: SagaReturnType<AccountApi['patch']> = yield call(clientApi.account.patchJointInterest, {
    ...dto,
    authToken,
  });

  yield put({
    type: State.actionSucceeded(Type.PATCH_JOINT_ACCOUNT_INTEREST),
    payload: data,
  });

  yield put(doSucceededGetAccount(mapAccountDtoToModel(data)));
}

export function* getAccount(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const id = action.payload.id;

  const response: SagaReturnType<AccountApi['retrieve']> = yield call(clientApi.account.retrieve, {
    authToken,
    params: { id },
  });

  yield put(doSucceededGetAccount(mapAccountDtoToModel(response)));
}

export function* submit(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);

  const dto: SubmitAccountDto = action.payload;

  const data: SagaReturnType<AccountApi['submit']> = yield call(clientApi.account.submit, {
    ...dto,
    authToken,
  });

  yield put({
    type: State.actionSucceeded(Type.SUBMIT_ACCOUNT),
  });
  yield put(
    toastMessagesAdd({
      key: uuid(),
      severity: SeverityEnum.Success,
      message: 'Account Application was successfully submitted',
    }),
  );

  if (data.tbToken) {
    const newBearer = replaceTbToken({ bearer: authToken, tbToken: data.tbToken });
    yield put({
      type: Type.SET_AUTHENTICATION,
      payload: {
        authToken: newBearer,
        tbToken: data.tbToken,
      },
    });
  }

  const model = mapAccountDtoToModel(data);
  yield put(doSucceededGetAccount(model));

  const user: User = yield select(state => state.user.authenticated.data);

  yield put({
    type: State.actionSucceeded(Type.GET_AUTHENTICATED_USER),
    payload: {
      ...user,
      lastUsedAccountId: model.id,
      accounts: user.accounts.filter(account => account.id !== data.id).concat(model),
    },
  });
}

export function* downloadSignature(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const dto = action.payload;

  const data: SagaReturnType<AccountApi['downloadSignature']> = yield call(clientApi.account.downloadSignature, {
    ...dto,
    authToken,
  });

  yield put({
    type: State.actionSucceeded(Type.DOWNLOAD_ACCOUNT_SIGNATURE),
    payload: data,
  });
}

export function* switchAccount(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const dto: SwitchAccountDto = action.payload;

  const data: SagaReturnType<AccountApi['switch']> = yield call(clientApi.account.switch, {
    ...dto,
    authToken,
  });

  if (data.tbToken) {
    const newBearer = replaceTbToken({ bearer: authToken, tbToken: data.tbToken });
    yield put({
      type: Type.SET_AUTHENTICATION,
      payload: {
        authToken: newBearer,
        tbToken: data.tbToken,
      },
    });
  }

  yield put({
    type: State.actionSucceeded(Type.SWITCH_ACCOUNT),
  });
}

export function* registerAccountSagas() {
  yield takeEvery(State.actionRequested(Type.CREATE_ACCOUNT), safeSaga(create, Type.CREATE_ACCOUNT));
  yield takeEvery(State.actionRequested(Type.PATCH_ACCOUNT), safeSaga(patch, Type.PATCH_ACCOUNT));
  yield takeEvery(
    State.actionRequested(Type.PATCH_JOINT_ACCOUNT_INTEREST),
    safeSaga(patchJointAccountInterest, Type.PATCH_JOINT_ACCOUNT_INTEREST),
  );
  yield takeEvery(State.actionRequested(Type.GET_ACCOUNT), safeSaga(getAccount, Type.GET_ACCOUNT));
  yield takeEvery(State.actionRequested(Type.SUBMIT_ACCOUNT), safeSaga(submit, Type.SUBMIT_ACCOUNT));
  yield takeEvery(
    State.actionRequested(Type.DOWNLOAD_ACCOUNT_SIGNATURE),
    safeSaga(downloadSignature, Type.DOWNLOAD_ACCOUNT_SIGNATURE),
  );
  yield takeEvery(State.actionRequested(Type.SWITCH_ACCOUNT), safeSaga(switchAccount, Type.SWITCH_ACCOUNT));
}
