import { Navigate, Outlet } from 'react-router-dom';
import { useAccountSelector } from 'src/hooks';

export const CompletedAccountGuard = () => {
  const { account } = useAccountSelector();

  if (account?.accountId) {
    return <Outlet />;
  }

  return <Navigate to={`/complete-account`} />;
};
