import { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Col, Popover, Row } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { doClearSwitchAccount, doSwitchAccount, getAuthenticatedUser, getInvestigations } from 'src/actions';
import { useAccountSelector, useAuthenticatedUserSelector, useFindOtherAccountList } from 'src/hooks';
import { Account, InvestigationStatus } from 'src/models';
import { MAIN_PATH } from 'src/pages/Main';
import { HISTORY_PATH } from 'src/pages/Main/pages/History/History';
import { SUMMARY_PATH } from 'src/pages/Main/pages/Summary/Summary';
import { BaseStyle, Color, Display, FontWeight, Spacing } from 'src/styles';
import { logo } from 'src/styles/images';

import { MButton } from '../FormComponents/MButton/MButton';
import { MDivider } from '../MDivider/MDivider';

import { AccountHolderFullName } from './AccountHolderFullName';
import { AccountStatusBanner } from './AccountStatusBanner';
import { AccountValue } from './AccountValue';
import {
  getAccountSettingMenuItemList,
  getUserSettingMenuItemList,
  HeaderMenuItem,
  HeaderMenuItemKey,
} from './constants';
import { DesktopMenuItemLink } from './DesktopMenuLinkItem';
import './index.css';
import * as Styles from './Header.styles';
import { LogoutMenuItem } from './LogoutMenuItem';
import ResourceLinks from './ResourceLinks';
import { SwitchAccountMenuItem } from './SwitchAccountMenuItem';
import { UserInfo } from './UserInfo';

export type DesktopProps = {
  authToken?: string;
  pathName: string;
  status?: InvestigationStatus;
};

export const DesktopHeader = ({ authToken, pathName, status }: DesktopProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSwitchLoading: boolean = useSelector((state: any) => state.accounts.switch.isLoading);
  const isSwitchSuccess: boolean = useSelector((state: any) => Boolean(state.accounts.switch.__succeeded));
  const isInvestigationLoading: boolean = useSelector((state: any) => state.investigations.list.isLoading);

  const [switchToAccountId, setSwitchToAccountId] = useState<string | null>(null);

  const [visible, setIsVisible] = useState(false);
  const [step, setStep] = useState<'account' | 'switch'>('account');

  const { isUserLoading } = useAuthenticatedUserSelector();
  const { account, hasAccountSucceeded } = useAccountSelector();
  // const canCreateAccount = useCanCreateAccount();
  // const canSwitchAccount = useCanSwitchAccount();
  const otherAccountList = useFindOtherAccountList();

  const getMenuItemText = (item: HeaderMenuItem) => {
    if (item.key !== HeaderMenuItemKey.AccountStatus) {
      return item.name;
    }

    if (status?.isActive) {
      return item.name;
    }

    return (
      <Row align='middle' justify='space-between' className={Styles.menuItemTextContainer}>
        <span>{item.name}</span>
        <span className={Styles.inactiveAccountMenuItemTextIconContainer}>
          <i className={Styles.inactiveAccountMenuItemTextIcon(status?.isPending ?? false)} />
        </span>
      </Row>
    );
  };

  const switchTo = (account: Account) => {
    setSwitchToAccountId(account.id);
    dispatch(doSwitchAccount({ params: { id: account.id } }));
  };

  const handleVisibleChange = (visible: boolean) => {
    setIsVisible(visible);
  };

  const onHide = () => {
    setIsVisible(false);
  };

  // const onGoToSwitchStep = () => {
  //   setStep('switch');
  // };

  const onGoBackToAccountStep = () => {
    setStep('account');
  };

  // const onCreateAccount = () => {
  //   dispatch(doClearGetAccount());
  //   dispatch(doClearGetInvestigations());
  //   setIsVisible(false);
  //   navigate('/accounts/personal-information');
  // };

  const onCurrentAccount = () => {
    setIsVisible(false);
  };

  const renderOtherAccountList = () => {
    if (isEmpty(otherAccountList)) return null;

    return (
      <>
        <Row>
          <MDivider />
        </Row>
        <Row>
          <Col span={24}>
            <span className={Styles.menuSubtitle}>OTHER ACCOUNTS</span>
          </Col>
          {otherAccountList.map(acc => (
            <Col span={24} key={acc.id}>
              <SwitchAccountMenuItem
                account={acc}
                onClick={() => switchTo(acc)}
                isLoading={switchToAccountId === acc.id && (isSwitchLoading || isUserLoading)}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  };

  const renderAccountStep = () => {
    return (
      <>
        <Row justify='center' align='middle' className={Spacing.mb12}>
          <Col span={24} className={cx(Spacing.mb4, BaseStyle.level)}>
            <AccountHolderFullName account={account} alignment='center' />
          </Col>
          <Col span={24} className={Spacing.my4}>
            {account?.accountId && <AccountValue />}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DesktopMenuItemLink text='My Account' link='/accounts/personal-information' onClick={onHide} />
          </Col>
        </Row>

        {/* {canSwitchAccount && (
          <Row>
            <Col span={24}>
              <div className={cx(Styles.menuItemWithoutLink, JustifyContent.spaceBetween)} onClick={onGoToSwitchStep}>
                <span>Switch Account </span>
                <span>
                  <i className='ri-arrow-right-s-line' style={{ fontSize: 24 }} />
                </span>
              </div>
            </Col>
          </Row>
        )} */}

        {/* {canCreateAccount && (
          <Row>
            <Col span={24}>
              <Link
                to='/accounts/personal-information'
                onClick={onCreateAccount}
                className={cx(Styles.desktopMenuLink, AlignItems.center)}>
                <span>
                  <i className='ri-user-add-line' style={{ fontSize: 24 }}></i>
                </span>
                <span>Add New Account Type </span>
              </Link>
            </Col>
          </Row>
        )} */}

        {account?.accountId && (
          <>
            <Row>
              <MDivider />
            </Row>
            <Row>
              <Col span={24}>
                <span className={Styles.menuSubtitle}>ACCOUNT SETTINGS</span>
              </Col>
              <Col span={24}>
                {getAccountSettingMenuItemList(account).map(aMenuItem => (
                  <DesktopMenuItemLink
                    key={aMenuItem.key}
                    text={getMenuItemText(aMenuItem)}
                    link={aMenuItem.path(account?.id)}
                    onClick={onHide}
                  />
                ))}
              </Col>
            </Row>
          </>
        )}
        <Row>
          <MDivider />
        </Row>
        <Row>
          <Col span={24}>
            <span className={Styles.menuSubtitle}>USER SETTINGS</span>
          </Col>
          <Col span={24}>
            {getUserSettingMenuItemList(account).map(aMenuItem => (
              <DesktopMenuItemLink
                key={aMenuItem.key}
                text={getMenuItemText(aMenuItem)}
                link={aMenuItem.path(account?.id)}
                onClick={onHide}
              />
            ))}
          </Col>
        </Row>
      </>
    );
  };

  const renderSwitchStep = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <div className={cx(Styles.menuItemWithoutLink, FontWeight.semibold)} onClick={onGoBackToAccountStep}>
              <div className={cx(Display.flex, Spacing.mr16)}>
                <i className='ri-arrow-left-line' />
              </div>
              <span className=''>Accounts </span>
            </div>
          </Col>
        </Row>
        <Row>
          <MDivider />
        </Row>
        <Row>
          <Col span={24}>
            <span className={Styles.menuSubtitle}>CURRENT ACCOUNT</span>
          </Col>
          <Col span={24}>
            <SwitchAccountMenuItem account={account} onClick={onCurrentAccount} />
          </Col>
        </Row>
        {renderOtherAccountList()}
      </>
    );
  };

  useEffect(() => {
    if (account?.accountId && !isInvestigationLoading) dispatch(getInvestigations(account.accountId));
  }, [account?.accountId]);

  useEffect(() => {
    if (isSwitchSuccess) {
      dispatch(getAuthenticatedUser());
    }

    if (isSwitchSuccess && hasAccountSucceeded) {
      setIsVisible(false);
      navigate('/');
    }

    return () => {
      dispatch(doClearSwitchAccount());
    };
  }, [isSwitchSuccess, hasAccountSucceeded]);

  return (
    <div>
      {authToken ? (
        <div className='resourceSection'>
          <div className='resourceWidthRestriction'>
            <div>
              <ResourceLinks className='linksSmall' isAuthenticated />
            </div>
          </div>
        </div>
      ) : null}
      <Row justify='center'>
        <Col span={24}>
          <div className='mainHeader'>
            <div className='headerWidthRestriction'>
              <Link to='/'>
                <img src={logo} alt='My IPO' className='logo' />
              </Link>
              {!authToken ? (
                <div className='signedOutLinks'>
                  <ResourceLinks className='linksMedium' />
                  <div className='authOptions'>
                    <div>
                      <MButton type='secondary' onClick={() => navigate('/register')} testId={'home-btn-signup'}>
                        Sign up
                      </MButton>
                    </div>
                    <div className='authBtnContainer'>
                      <MButton type='secondary' onClick={() => navigate('/login')} testId={'home-btn-login'}>
                        Log in
                      </MButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='headerLinkSection'>
                  <div
                    style={{
                      display: 'inline-block',
                      margin: '0 auto',
                    }}>
                    <NavLink
                      to={`/${MAIN_PATH}/${SUMMARY_PATH}`}
                      className={pathName.indexOf(`/${SUMMARY_PATH}`) > 0 ? 'linksLargeActive' : 'linksLarge'}>
                      Summary
                    </NavLink>

                    <NavLink
                      to='/offers'
                      className={pathName.startsWith('/offers') ? 'linksLargeActive' : 'linksLarge'}>
                      Offers
                    </NavLink>

                    <NavLink
                      to={`/${MAIN_PATH}/${HISTORY_PATH}`}
                      className={pathName.indexOf(`/${HISTORY_PATH}`) > 0 ? 'linksLargeActive' : 'linksLarge'}>
                      History
                    </NavLink>
                  </div>
                  <Popover
                    content={
                      <div
                        style={{
                          width: 345,
                          backgroundColor: Color.GRAYSCALE.GRAY1,
                          borderRadius: 12,
                          paddingTop: 22,
                          boxShadow: '0 4px 16px rgba(2, 85, 163, 0.15)',
                        }}
                        data-testid={'summary-dropdown-account-menu'}>
                        {step === 'account' ? renderAccountStep() : renderSwitchStep()}
                        <Row>
                          <MDivider />
                        </Row>
                        <Row className={cx(Spacing.pl0, Spacing.pb12)}>
                          <LogoutMenuItem onClick={onHide} />
                        </Row>
                      </div>
                    }
                    trigger='click'
                    open={visible}
                    onOpenChange={handleVisibleChange}
                    placement='bottomRight'>
                    <UserInfo
                      onClick={() => {
                        setStep('account');
                        handleVisibleChange(!visible);
                      }}
                    />
                  </Popover>
                </div>
              )}
            </div>
          </div>
        </Col>
        <AccountStatusBanner />
      </Row>
    </div>
  );
};
