import { Col, Row, Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';

import { MFormItemError } from '../MFormItemError/MFormItemError';

import * as Styles from './MSegmented.styles';

export interface MSegmentedProps {
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  options: { value: string; label: string }[];
  onChange?: (value: SegmentedValue) => void;
  error?: string;
  block?: boolean;
  testId?: string;
}

export const MSegmented = ({
  value,
  defaultValue,
  options,
  onChange,
  disabled,
  error,
  block,
  testId,
}: MSegmentedProps) => {
  return (
    <Row>
      <Col span={24}>
        <Segmented
          value={value}
          defaultValue={defaultValue}
          options={options}
          onChange={onChange}
          disabled={disabled}
          block={block}
          size='large'
          className={Styles.segmented}
          data-testid={testId + '-options'}
        />
      </Col>
      <Col span={24} data-testid={testId + '-' + 'error'}>
        <MFormItemError value={error} />
      </Col>
    </Row>
  );
};
