import { cx } from '@emotion/css';
import { useNavigate } from 'react-router';
import { useAccountSelector, useInvestigationListSelector } from 'src/hooks';
import { Font, FontWeight } from 'src/styles';

import { Banner } from '../Banner/Banner';

export const AccountStatusBanner = () => {
  const navigate = useNavigate();
  const { status, isAnyDocumentRequested } = useInvestigationListSelector();
  const { account } = useAccountSelector();

  const text = () => {
    if (status?.isRejected && isAnyDocumentRequested) {
      return (
        <span className={cx(Font.h1, FontWeight.semibold)}>
          Your account has been rejected. Before we can approve your account, we will need additional information.
          Please visit{' '}
          <a
            onClick={() => {
              navigate(`/accounts/${account?.id}/account-status`);
            }}>
            Account Status
          </a>{' '}
          to submit the required information.
        </span>
      );
    }

    if (status?.isRejected) {
      return (
        <span className={cx(Font.h1, FontWeight.semibold)}>
          Your account has been rejected. You can find more information at{' '}
          <a
            onClick={() => {
              navigate(`/accounts/${account?.id}/account-status`);
            }}>
            Account Status
          </a>
          .
        </span>
      );
    }

    if (isAnyDocumentRequested) {
      return (
        <span className={cx(Font.h1, FontWeight.semibold)}>
          Before we can approve your account, we will need additional information. Please visit{' '}
          <a
            onClick={() => {
              navigate(`/accounts/${account?.id}/account-status`);
            }}>
            Account Status
          </a>{' '}
          to submit the required information.
        </span>
      );
    }

    return (
      <span className={cx(Font.h1, FontWeight.semibold)}>
        Your account application has been submitted and is under review. You can find more information at{' '}
        <a
          onClick={() => {
            navigate(`/accounts/${account?.id}/account-status`);
          }}>
          Account Status
        </a>
        .
      </span>
    );
  };

  if (!account) {
    return null;
  }

  if (!status) {
    return null;
  }

  if (status.isActive) {
    return null;
  }

  return <Banner type={status.isPending ? 'warning' : 'error'} message={text()} />;
};
