import { phoneNumberValidator, suffixValidator } from 'src/utils';
import * as Yup from 'yup';

export const validationMessages = {
  firstNameRequired: 'First Name is required.',
  firstNameMinCharacters: 'First Name is too short',
  firstNameMaxCharacters: 'First Name can have a maximum length of 40 characters',
  firstNameRegex: 'First Name may only contain letters, periods, apostrophes, hyphens, slashes or spaces',
  lastNameRequired: 'Last Name is required.',
  lastNameMinCharacters: 'Last Name is too short',
  lastNameMaxCharacters: 'Last Name can have a maximum length of 40 characters',
  lastNameRegex: 'Last Name may only contain letters, periods, apostrophes, hyphens, slashes or spaces',
  passwordRequired: 'Password is required.',
  passwordMinCharacters: 'Password should be 8 characters at minimum.',
  passwordMaxCharacters: 'Password should be 24 characters at most.',
  passwordMustContainUpperCase: 'Password should contain one uppercase letter.',
  passwordMustContainLowercase: 'Password should contain one lowercase letter.',
  passwordMustContainNumber: 'Password should contain at least one number.',
  passwordMustContainSpecialCharacter: 'Password should contain at least one special character.',
  passwordMustNotContainWordPassword: `Password should not contain the word 'password'`,
  passwordMustNotContainFirstnameAndLastname: 'Password should not contain First name and Last name',
  confirmPasswordRequired: 'Confirm Password is required',
  passwordsDoNotMatch: 'Passwords do not match.',
  emailNameRequired: 'Email is required.',
  emailNameInvalid: 'Email is invalid.',
  countryCodeRequired: 'Country is required.',
  countryCodeInvalid: 'Country is invalid.',
  phoneRequired: 'Phone is required.',
  phoneInvalid: 'Phone number is not valid.',
};

export const registerValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(validationMessages.firstNameRequired)
    .min(2, validationMessages.firstNameMinCharacters)
    .max(40, validationMessages.firstNameMaxCharacters)
    .matches(/^[a-zA-Z.\s\'\-\\\/]*$/, validationMessages.firstNameRegex),
  middleName: Yup.string()
    .trim()
    .max(20, 'Middle Initial or Middle Name can have a maximum length of 20 characters')
    .matches(
      /^[a-zA-Z\s.\'\-\/]+$/,
      'Only letters, periods, apostrophes, hyphens, slashes, or spaces are allowed for Middle Initial or Middle Name',
    ),
  lastName: Yup.string()
    .required(validationMessages.lastNameRequired)
    .min(2, validationMessages.lastNameMinCharacters)
    .max(40, validationMessages.lastNameMaxCharacters)
    .matches(/^[a-zA-Z.\s\'\-\\\/]*$/, validationMessages.lastNameRegex),
  suffix: suffixValidator,
  email: Yup.string().required(validationMessages.emailNameRequired).email(validationMessages.emailNameInvalid),
  phoneNumber: phoneNumberValidator,
  password: Yup.string()
    .required(validationMessages.passwordRequired)
    .min(8, validationMessages.passwordMinCharacters)
    .max(24, validationMessages.passwordMaxCharacters)
    .matches(/[A-Z]/, validationMessages.passwordMustContainUpperCase)
    .matches(/[a-z]/, validationMessages.passwordMustContainLowercase)
    .matches(/[\d]/, validationMessages.passwordMustContainNumber)
    .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, validationMessages.passwordMustContainSpecialCharacter)
    .test(
      'compare',
      validationMessages.passwordMustNotContainWordPassword,
      function (password: string | any, schema: string | any) {
        if (schema !== undefined && schema !== null) {
          return !password?.toLowerCase().includes('password');
        } else return false;
      },
    )
    .test(
      'compare',
      validationMessages.passwordMustNotContainFirstnameAndLastname,
      function (password: string | any, schema: string | any) {
        if (schema !== undefined && schema !== null) {
          return (
            !password?.toLowerCase().includes(schema?.parent?.firstName?.toLowerCase()) &&
            !password?.toLowerCase().includes(schema?.parent?.lastName?.toLowerCase())
          );
        } else return false;
      },
    ),

  confirmPassword: Yup.string()
    .required(validationMessages.confirmPasswordRequired)
    .min(8, validationMessages.passwordMinCharacters)
    .test(validationMessages.passwordsDoNotMatch, validationMessages.passwordsDoNotMatch, function () {
      return this.parent.password === this.parent.confirmPassword;
    }),
});
