import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededDefaults } from './utils';

// ACCOUNT_BALANCE
export const accountBalance = (state: any = initialState.accountDetails.accountBalance, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.ACCOUNTS_GET_BALANCE):
      return clearedDefaults(state);
    case State.actionRequested(Type.ACCOUNTS_GET_BALANCE):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.ACCOUNTS_GET_BALANCE):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.ACCOUNTS_GET_BALANCE):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountDetails.accountBalance;
    default:
      return state;
  }
};

// ACCOUNT_VALUE
export const accountValue = (state: any = initialState.accountDetails.accountValue, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.SET_ACCOUNT_VALUE):
      return clearedDefaults(state);
    case State.actionSucceeded(Type.SET_ACCOUNT_VALUE):
      return succeededDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
    case State.actionSucceeded(Type.SWITCH_ACCOUNT):
    case State.actionSucceeded(Type.CREATE_ACCOUNT):
      return initialState.accountDetails.accountValue;
    default:
      return state;
  }
};

export default combineReducers({
  accountBalance,
  accountValue,
});
