import { MFormDateField, MFormTextField, PhoneNumbersField } from 'src/lib';
import { AccountHolder } from 'src/models';

import { SocialSecurityNumberTooltipContent } from './SocialSecurityNumberTooltipContent';

export interface PersonalInformationViewProps {
  accountHolder?: AccountHolder;
  isPrimary: boolean;
}

export const PersonalInformationView = ({ accountHolder }: PersonalInformationViewProps) => {
  const formatSsid = () => {
    const ssid = accountHolder?.maskedSsid;

    if (ssid) {
      return ssid.substring(0, 3) + ' - ' + ssid.substring(3, 5) + ' - ' + ssid.substring(5, 9);
    }

    return ssid;
  };

  return (
    <>
      <MFormTextField label='First Name' value={accountHolder?.firstName} testId={'account-first-name'} />
      <MFormTextField
        label='Middle Initial or Middle Name'
        value={accountHolder?.middleName}
        testId={'account-middle-name'}
      />
      <MFormTextField label='Last Name' value={accountHolder?.lastName} testId={'account-last-name'} />
      <MFormTextField label='Suffix' value={accountHolder?.suffix} testId={'account-suffix'} />
      <MFormTextField
        label='Social Security Number'
        value={formatSsid()}
        tooltip={{
          type: 'modal',
          title: 'Why do we need this?',
          content: <SocialSecurityNumberTooltipContent />,
        }}
        testId={'account-ssn'}
      />
      <MFormTextField label='Email' value={accountHolder?.email} />
      {accountHolder?.phones && (
        <MFormTextField label='Phone Number(s)' value={<PhoneNumbersField value={accountHolder.phones} />} />
      )}
      <MFormDateField label='Date of Birth' value={accountHolder?.dateOfBirth} testId={'account-dob'} />
      <MFormTextField
        label='Marital Status'
        value={accountHolder?.maritalStatus?.label}
        testId={'account-marital-status'}
      />
      <MFormTextField
        label='Number of Dependents'
        value={accountHolder?.numberOfDependents}
        testId={'account-number-of-dependents'}
      />
    </>
  );
};
