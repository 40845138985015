import { JointAccountTypeDto } from 'src/dtos';

interface AccountTypeDescriptionProps {
  value?: JointAccountTypeDto;
}

export const JointAccountTypeDescription = ({ value }: AccountTypeDescriptionProps) => {
  if (value === JointAccountTypeDto.Community) {
    return (
      <>
        Form of ownership required by states that have adopted community property laws for shares owned by husband and
        wife. Non-resident aliens are not eligible for this type of account.
      </>
    );
  }

  if (value === JointAccountTypeDto.Survivor) {
    return (
      <>
        Form of ownership where two or more individuals hold shares. When one tenant dies, the entire tenancy remains
        with the surviving tenant(s).
      </>
    );
  }

  if (value === JointAccountTypeDto.TenantsInCommon) {
    return (
      <>
        Form of ownership where each tenant owns a specific percentage of the property. When one tenant dies, his
        interest passes to his estate. JTTIC accounts are only allowed for US residents.
      </>
    );
  }

  if (value === JointAccountTypeDto.TenantsInEntirety) {
    return (
      <>
        Form of ownership for two individuals who are married people. When one tenant dies, the property passes to the
        survivor. Non-resident aliens do not qualify for this account type.
      </>
    );
  }

  return null;
};
