import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { useAccountSelector } from 'src/hooks';
import { ScreenBreakpoint } from 'src/styles';
import { isAccountDisclosureComplete } from 'src/utils';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { AccountHolderDisclosureSection } from './AccountHolderDisclosureSection';
import { JointAccountHolderDisclosureSection } from './JointAccountHolderDisclosureSection';

export const Disclosures = () => {
  const navigate = useNavigate();

  const isCreateSuccess = useSelector((state: any) => Boolean(state.accountHolders.createDisclosures.__succeeded));
  const isPatchSuccess = useSelector((state: any) => Boolean(state.accountHolders.patchDisclosures.__succeeded));

  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const hasAnyUpsertRequest = () => isCreateSuccess || isPatchSuccess;

  const shouldSendAnalyticsEvent = () => !account?.accountId && isCreateSuccess && isAccountDisclosureComplete(account);

  const shouldRedirectToAccountAgreements = () =>
    !account?.accountId && hasAnyUpsertRequest() && isAccountDisclosureComplete(account);

  useEffect(() => {
    if (shouldSendAnalyticsEvent()) {
      window.gtag('event', 'account_disclosures_complete');
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (shouldRedirectToAccountAgreements()) {
      navigate(`/accounts/account-agreements`);
    }
  }, [isCreateSuccess, isPatchSuccess]);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.Disclosures}
      title={
        isMobile
          ? account?.type.isJoint
            ? 'Primary Account Holder'
            : undefined
          : account?.type.isJoint
          ? 'Primary Account Holder'
          : 'Disclosures'
      }>
      <AccountHolderDisclosureSection />
      <JointAccountHolderDisclosureSection />
    </MyAccountLayout>
  );
};
