import { MFormTextField } from 'src/lib';
import { AccountHolder } from 'src/models';

export interface PhysicalAddressViewProps {
  accountHolder?: AccountHolder;
}

export const PhysicalAddressView = ({ accountHolder }: PhysicalAddressViewProps) => {
  return (
    <>
      <MFormTextField label='Address Line 1' value={accountHolder?.physicalAddress?.address1} />
      <MFormTextField label='Address Line 2 (Opt.)' value={accountHolder?.physicalAddress?.address2} />
      <MFormTextField label='Country' value={accountHolder?.physicalAddress?.country.label} />
      <MFormTextField label='City' value={accountHolder?.physicalAddress?.city} />
      <MFormTextField label='State' value={accountHolder?.physicalAddress?.state.label} />
      <MFormTextField label='Postal Code' value={accountHolder?.physicalAddress?.postalCode} />
      <MFormTextField label='Citizenship' value={accountHolder?.citizenshipCountry?.label} />
      <MFormTextField label='Birth Country' value={accountHolder?.birthCountry?.label} />
    </>
  );
};
