import { useEffect, useState } from 'react';

import { isUndefined } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { AccountTypeForm } from './AccountTypeForm';
import { AccountTypeView } from './AccountTypeView';

export const AccountTypeSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const isAccountAlreadyCreated = () => !isUndefined(account?.type);

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  useEffect(() => {
    if (isAccountAlreadyCreated() && !Boolean(account?.accountId)) {
      setIsEditable(true);
    }
  }, [account]);

  return (
    <MFormSection
      title='Account Type'
      onEdit={onEdit}
      isEditable={isEditable}
      isEditMode={isEditMode}
      testId='account-type'>
      {isAccountAlreadyCreated() && !isEditMode ? (
        <AccountTypeView account={account} />
      ) : (
        <AccountTypeForm account={account} onCancel={onCancel} />
      )}
    </MFormSection>
  );
};
