import { css } from '@emotion/css';

import { AlignItems, ColorConstant, Display, Font, Spacing } from '../../styles';

export const button = (underlined: boolean = false) => css`
  color: ${ColorConstant.BRAND6};
  ${Display.flex}
  ${AlignItems.center}
  ${Spacing.mx0}
  ${Spacing.px0}
  ${Font.md}
  cursor: pointer;
  white-space: normal;
  text-decoration: ${underlined ? 'underline' : 'none'} !important;

  &:hover {
    color: ${ColorConstant.BRAND6};
  }

  &:focus {
    color: ${ColorConstant.BRAND6};
  }
`;
