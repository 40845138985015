import moment from 'moment';
import { phoneNumberValidator, suffixValidator } from 'src/utils';
import * as Yup from 'yup';

const validationMessages = {
  firstNameRequired: 'First name is required.',
  firstNameMinCharacters: 'First Name is too short',
  firstNameMaxCharacters: 'First Name can have a maximum length of 40 characters',

  middleNameMaxChar: 'Middle Initial or Middle Name can have a maximum length of 20 characters',

  lastNameRequired: 'Last name is required.',
  lastNameMinCharacters: 'Last Name is too short',
  lastNameMaxCharacters: 'Last Name can have a maximum length of 40 characters',

  suffixMaxChar: 'Suffix can have a maximum length of 4 characters',

  ssnRequired: 'SSN is required',
  ssnInvalid: 'SSN should have 9 digits',

  dateOfBirthRequired: 'Date of Birth is required',
  dateOfBirthMinimum: 'Age should be older than 18',
  dateOfBirthInvalid: 'Date of Birth is invalid',

  maritalStatusRequired: 'Marital Status One is required',
  maritalStatusInvalid: 'Marital Status is invalid',

  numberOfDependentsRequired: 'Number Of Dependents is required',
  numberOfDependentsInvalid: 'Number of dependents is invalid',

  addressLineOneRequired: 'Address line 1 is required',
  addressLineOneInvalid: 'Adress Line 1 can have a maximum length of 30 characters',
  addressLineTwoInvalid: 'Adress Line 2 can have a maximum length of 30 characters',
  cityRequired: 'City is required',
  cityInvalid: 'City can have a maximum length of 30 characters',
  stateRequired: 'State is required',
  stateInvalid: 'State is invalid',
  postalCodeRequired: 'Postal Code is required',
  postalCodeInvalid: 'Postal Code value should be between 0 and 100000',
  citizenshipRequired: 'Citizenship is required',
  countryRequired: 'Country is required',
  countryInvalid: 'Country is invalid',
  birthCountryRequired: 'Birth Country is required',
  fieldShouldContainOnlyLetters: 'Field should contain only letters',
};

export const createAccountHolderValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required(validationMessages.firstNameRequired)
    .min(2, validationMessages.lastNameMinCharacters)
    .max(40, validationMessages.lastNameMaxCharacters),
  middleName: Yup.string()
    .trim()
    .max(20, validationMessages.middleNameMaxChar)
    .matches(
      /^[a-zA-Z\s.\'\-\/]+$/,
      'Only letters, periods, apostrophes, hyphens, slashes, or spaces are allowed for Middle Initial or Middle Name',
    ),
  lastName: Yup.string()
    .trim()
    .required(validationMessages.lastNameRequired)
    .min(2, validationMessages.lastNameMinCharacters)
    .max(40, validationMessages.lastNameMaxCharacters),
  suffix: suffixValidator,
  ssid: Yup.string()
    .required(validationMessages.ssnRequired)
    .min(9, validationMessages.ssnInvalid)
    .max(9, validationMessages.ssnInvalid)
    .matches(/^[0-9]+$/, 'SSN should have digits only'),
  dateOfBirth: Yup.string()
    .required(validationMessages.dateOfBirthRequired)
    .test('DOB', validationMessages.dateOfBirthInvalid, value => {
      if (value) {
        return moment(new Date(value)).isValid();
      }

      return false;
    })
    .test('DOB', validationMessages.dateOfBirthMinimum, value => {
      return moment().diff(moment(value), 'years') >= 18;
    }),
  maritalStatus: Yup.string()
    .required(validationMessages.maritalStatusRequired)
    .notOneOf(['-1'], validationMessages.maritalStatusInvalid),
  numberOfDependents: Yup.number()
    .required(validationMessages.numberOfDependentsRequired)
    .moreThan(-1, validationMessages.numberOfDependentsInvalid)
    .lessThan(100000, validationMessages.numberOfDependentsInvalid),
});

export const createJointAccountHolderValidationSchema = createAccountHolderValidationSchema.shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .required('Confirm Email is required'),
  phones: phoneNumberValidator.required('Phone number is required'),
});

export const patchAccountHolderValidationSchema = Yup.object().shape({
  maritalStatus: Yup.string()
    .required(validationMessages.maritalStatusRequired)
    .notOneOf(['-1'], validationMessages.maritalStatusInvalid),
  numberOfDependents: Yup.number()
    .required(validationMessages.numberOfDependentsRequired)
    .moreThan(-1, validationMessages.numberOfDependentsInvalid)
    .lessThan(100000, validationMessages.numberOfDependentsInvalid),
});

export const patchJointAccountholderValidationSchema = patchAccountHolderValidationSchema.shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .required('Confirm Email is required'),
  phones: phoneNumberValidator.required('Phone number is required'),
});

export const upsertAccountHolderPhysicalAddressValidation = Yup.object().shape({
  address1: Yup.string()
    .trim()
    .required(validationMessages.addressLineOneRequired)
    .max(30, validationMessages.addressLineOneInvalid),
  address2: Yup.string().trim().max(30, validationMessages.addressLineTwoInvalid),
  country: Yup.string()
    .required(validationMessages.countryRequired)
    .notOneOf(['-1'], validationMessages.countryInvalid),
  city: Yup.string().trim().required('City is required'),
  state: Yup.string().required('State is required'),
  postalCode: Yup.string()
    .required(validationMessages.postalCodeRequired)
    .max(6)
    .test('Digits only', 'Postal Code should have digits only', (value: any) => /^\d+$/.test(value))
    .test(
      'Range size',
      'Postal Code value should be between 0 and 100000',
      (value: any) => 0 < Number(value) && Number(value) < 100000,
    ),
  citizenshipCountry: Yup.string().required(validationMessages.citizenshipRequired),
  birthCountry: Yup.string().required(validationMessages.birthCountryRequired),
});

export const upsertAccountHolderMailingAddressValidation = Yup.object().shape({
  address1: Yup.string()
    .trim()
    .required(validationMessages.addressLineOneRequired)
    .max(30, validationMessages.addressLineOneInvalid),
  address2: Yup.string().trim().max(30, validationMessages.addressLineTwoInvalid),
  country: Yup.string()
    .required(validationMessages.countryRequired)
    .notOneOf(['-1'], validationMessages.countryInvalid),
  city: Yup.string().trim().required('City is required'),
  state: Yup.string().required('State is required'),
  postalCode: Yup.string()
    .trim()
    .required(validationMessages.postalCodeRequired)
    .max(6)
    .test('Digits only', 'Postal Code should have digits only', (value: any) => /^\d+$/.test(value))
    .test(
      'Range size',
      'Postal Code value should be between 0 and 100000',
      (value: any) => 0 < Number(value) && Number(value) < 100000,
    ),
});
