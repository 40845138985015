import { cx } from '@emotion/css';
import { Col } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addOfferOrderIntent } from 'src/actions';
import { MRadioGroup, SecurityDeliveryOption, MButton } from 'src/lib';
import { OfferDetails, OfferOrderIntent } from 'src/models';
import { BaseStyle, Images, Spacing } from 'src/styles';
import * as Yup from 'yup';

import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import { DETAILS_PATH } from '../NewOfferOrderConfirmStep/NewOfferOrderConfirmStep';
import * as Styles from '../NewOfferOrders.styles';

export const SECURITY_DELIVERY_INFORMATION = 'security-delivery-information';

const securityDeliveryInformationFormValidation = Yup.object().shape({
  securityDeliveryOption: Yup.string().required('Security delivery option is required'),
});

interface SecurityDeliveryInformationFormValues {
  securityDeliveryOption: string;
}

export const NewOfferOrderSecurityDeliveryInformationStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);

  const getSecurityDeliveryOptionList = () => offerDetails.externalDetails?.securityDeliveryOptions ?? [];

  const findSecurityDeliveryOptionListInitialValue = () =>
    getSecurityDeliveryOptionList().findIndex(anOption => anOption.initialValue);

  const onSubmit = (values: SecurityDeliveryInformationFormValues) => {
    const selectedOption = getSecurityDeliveryOptionList()[Number(values.securityDeliveryOption)];
    dispatch(
      addOfferOrderIntent({
        ...offerOrderIntent,
        securityDeliveryOption: {
          label: selectedOption.label,
          tooltip: selectedOption.tooltip,
        },
      }),
    );

    window.gtag('event', 'offer_order_security_delivery_complete', {
      offer_id: offerDetails.id,
      offer_name: offerDetails.name,
      account_id: offerOrderIntent.account.accountId,
    });

    navigate(`../${DETAILS_PATH}`);
  };

  return (
    <NewOfferOrderLayout
      step='security-delivery'
      description='Please review the information below before completing the order process.'
      hasManySecurityDeliveryOptions
      offerId={offerDetails.id}>
      <Formik<SecurityDeliveryInformationFormValues>
        enableReinitialize
        validateOnChange
        validateOnBlur
        initialValues={{
          securityDeliveryOption: findSecurityDeliveryOptionListInitialValue().toString(),
        }}
        validationSchema={securityDeliveryInformationFormValidation}
        onSubmit={values => onSubmit(values)}>
        {form => {
          return (
            <div>
              <Col span={24} className={cx(BaseStyle.level, Spacing.mb40)}>
                <img
                  className={Styles.offerLogo}
                  src={offerDetails.logoUrl || Images.DefaultOffering}
                  alt={`${offerDetails.name}_logo`}
                />
                <span className={Styles.offerName}>{offerDetails.name}</span>
              </Col>
              <Col span={24}>
                <MRadioGroup
                  align='vertical'
                  defaultValue={findSecurityDeliveryOptionListInitialValue().toString()}
                  value={form.values.securityDeliveryOption}
                  options={getSecurityDeliveryOptionList().map((anOption, index) => ({
                    value: `${index}`,
                    label: <SecurityDeliveryOption {...anOption} />,
                  }))}
                  error={form.errors.securityDeliveryOption}
                  onChange={value => {
                    form.setFieldValue('securityDeliveryOption', value);
                  }}
                  testId={'radio-group-security-delivery-information'}
                />
              </Col>
              <Col span={24} className={cx(BaseStyle.level, Spacing.mt24)}>
                <MButton type='tertiary' onClick={() => navigate(-1)} className={Spacing.mx4}>
                  Back
                </MButton>
                <MButton
                  onClick={() => form.submitForm()}
                  className={Spacing.mx4}
                  testId={'security-delivery-info-btn-continue'}>
                  Continue
                </MButton>
              </Col>
            </div>
          );
        }}
      </Formik>
    </NewOfferOrderLayout>
  );
};
