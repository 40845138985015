import { PropsWithChildren } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { Col, Row } from 'antd';

import { MButton } from '../FormComponents/MButton/MButton';
import { MTooltip } from '../MTooltip/MTooltip';

import * as Styles from './MFormSection.styles';

export interface MFormSectionProps {
  title?: string;
  onEdit?: () => void;
  isEditable: boolean;
  isEditMode: boolean;
  tooltip?: {
    type: 'modal' | 'popover';
    icon?: 'info' | 'question';
    title?: string;
    label?: string;
    content: string | JSX.Element;
  };
  className?: string;
  testId?: string;
}

export const MFormSection = ({
  title,
  onEdit,
  isEditable,
  isEditMode,
  tooltip,
  className = '',
  children,
  testId,
}: PropsWithChildren<MFormSectionProps>) => {
  return (
    <div className={cx(Styles.section, className)}>
      <Row className={Styles.header(Boolean(title))}>
        {title && (
          <Col span={19} className={Styles.title} data-testid={testId + '-title'}>
            {title}
          </Col>
        )}
        <Col span={5}>
          {isEditable && !isEditMode && (
            <MButton onClick={onEdit} type='tertiary' testId={testId + '-edit-button'}>
              Edit <EditOutlined />
            </MButton>
          )}
        </Col>
        {tooltip && (
          <Col span={24}>
            <MTooltip type={tooltip.type} icon={tooltip.icon} title={tooltip.title} label={tooltip.label}>
              {tooltip.content}
            </MTooltip>
          </Col>
        )}
      </Row>
      <Row>{children}</Row>
    </div>
  );
};
